import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { DIGITAL_JOURNEY_BASE_URL } from '../apiConfig'

export const processLead = createAsyncThunk(
    'processLead',
    async ({ jwtBearer, uuid, username, name, requestBody }) => {
        console.log('Request Body - ', requestBody)
        const options = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${jwtBearer}`,
                'Content-Type': 'application/json' // Set content type to JSON
            },
            body: JSON.stringify(requestBody) // Wrap requestBody as needed
        }

        try {
            const response = await fetch(
                `${DIGITAL_JOURNEY_BASE_URL}lead/process/?uuid=${uuid}&username=${username}&name=${name}`,
                options
            )
            console.log('Request Options - ', options)
            console.log('Response -- ', response)
            return response.json() // Assuming the response is JSON
        } catch (error) {
            console.log('Error', error)
            alert(error.message)
            throw error
        }
    }
)

const processLeadSlice = createSlice({
    name: 'processLead',
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
        errorMessage: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(processLead.pending, state => {
            state.isLoading = true
            state.isError = false
            state.errorMessage = null
        })
        builder.addCase(processLead.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = false
            state.errorMessage = null
        })
        builder.addCase(processLead.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = action.error.message
        })
    }
})

export default processLeadSlice.reducer
