import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,
  Tabs,
  Typography,
  Form,
  Input,
  InputNumber,
  Select
} from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAdmin } from '../../../redux/slice/adminSlice'
import { useAuth } from '../../../context/AuthContext'
import jwt_decode from 'jwt-decode'
import AddUser from './AddUser'
import TableRefresh from './TableRefresh'
import TableRefreshManual from './TableRefreshManual'
import AddGroup from './AddGroup'
import BackendAccess from './BackendAccess'

const { Title } = Typography
const { TabPane } = Tabs

// const { authState } = useAuth()

// var jwtBearer = authState.token

const columns = [
  {
    title: <strong>USER ID</strong>,
    dataIndex: 'userid',
    key: 'userid',
    width: '70%',
    align: 'left' as 'left',
    className: 'table-heading',
    render: (text, record, index) => {
      const isFirstColumn = index === 0
      return (
        <span
          style={{
            fontWeight: 'bold'
            // color: isFirstColumn ? 'grey' : 'black'
          }}
        >
          {text}
        </span>
      )
    }
  },
  //   {
  //     title: <strong>PASSWORD</strong>,
  //     dataIndex: 'password',
  //     key: 'password',
  //     width: '30%',
  //     align: 'left' as 'left',
  //     className: 'table-column-light'
  //   },
  {
    title: <strong>USER TYPE</strong>,
    dataIndex: 'usertype',
    key: 'usertype',
    width: '30%',
    align: 'left' as 'left',
    className: 'table-column-light'
  }
]

const tabsItems = [
  // {
  //   key: '1',
  //   label: `Active Users`,
  //   children: (
  //     <div className='tabled'>
  //       <Row gutter={[24, 0]}>
  //         <Col
  //           span={12}
  //           offset={6}
  //           style={{ display: 'flex', justifyContent: 'center' }}
  //         >
  //           <Card
  //             layout='horizontal'
  //             bordered={true}
  //             className='criclebox tablespace mb-24'
  //             title='Active Users'
  //             centered
  //             style={{ width: '100%' }}
  //           >
  //             <TableContainer />
  //             {/* <Modal
  //               title='Selected Details'
  //               visible={isModalVisible}
  //               width={1000}
  //               centered
  //               onCancel={handleModalClose}
  //               footer={[
  //                 <Button key='close' onClick={handleModalClose}>
  //                   Close
  //                 </Button>
  //               ]}
  //               style={{ textAlign: 'center' }}
  //             ></Modal> */}
  //           </Card>
  //         </Col>
  //       </Row>
  //     </div>
  //   )
  // },
  {
    key: '2',
    label: `Manage Users`,
    children: (
      // <div className='tabled'>
      //   <Row gutter={[24, 0]}>
      //     <Col
      //       span={12}
      //       offset={6}
      //       style={{ display: 'flex', justifyContent: 'center' }}
      //     >
      <Card
        layout='horizontal'
        bordered={true}
        className='criclebox tablespace mb-24'
        title='Manage Users'
        centered
        style={{ width: '100%' }}
      >
        <AddUser />
      </Card>
      //     </Col>
      //   </Row>
      // </div>
    )
  },
  {
    key: '3',
    label: `Table Refresh`,
    children: (
      <div className='tabled'>
        <Row gutter={[24, 0]}>
          <Col
            span={12}
            offset={6}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Card
              layout='horizontal'
              bordered={true}
              className='criclebox tablespace mb-24'
              title='Table Refresh'
              centered
              style={{ width: '100%' }}
            >
              <TableRefresh />
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col
            span={12}
            offset={6}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Card
              layout='horizontal'
              bordered={true}
              className='criclebox tablespace mb-24'
              title='Table Refresh Manual'
              centered
              style={{ width: '100%' }}
            >
              <TableRefreshManual />
            </Card>
          </Col>
        </Row>
      </div>
    )
  },
  {
    key: '4',
    label: `Add Group`,
    children: (
      <div className='tabled'>
        <Row gutter={[24, 0]}>
          <Col
            span={12}
            offset={6}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Card
              layout='horizontal'
              bordered={true}
              className='criclebox tablespace mb-24'
              title='Add Group'
              centered
              style={{ width: '100%' }}
            >
              <AddGroup />
            </Card>
          </Col>
        </Row>
      </div>
    )
  },
  {
    key: '5',
    label: `Backend Access Control`,
    children: (
      <div className='tabled'>
        <Row gutter={[24, 0]}>
          <Col
            span={12}
            offset={6}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Card
              layout='horizontal'
              bordered={true}
              className='criclebox tablespace mb-24'
              title='Backend Access Control'
              centered
              style={{ width: '100%' }}
            >
              <BackendAccess />
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
]

function TableContainer() {
  const [dataSource, setDataSource] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const dispatch = useDispatch()
  const { authState } = useAuth()
  var jwtBearer = authState.token
  // console.log('Admin auth token', jwtBearer)

  useEffect(() => {
    // dispatch(fetchAdmin({ jwtBearer }))
    dispatch(fetchAdmin({ jwtBearer }))
  }, [])

  const state = useSelector(state => state)
  // console.log('State Admin -', state)

  const allFields = state.admin.data?.message || []
  // console.log('Admin all fields', allFields)

  const keys = state.admin.data
    ? Object.keys(allFields['userid'] ? allFields['userid'] : '')
    : []

  // Create an array of objects in the desired format
  // const objectsArray = keys.map(key => {
  //   const obj = {}
  //   for (const subKey in allFields) {
  //     obj[subKey] = allFields[subKey][key]
  //   }
  //   return obj
  // })
  // console.log('CMA Registered users JSON - ', objectsArray)

  // useEffect(() => {
  //   setDataSource(objectsArray)
  //   // setLastUpdatedDate(updated_date)
  //   // setLastUpdatedTime(updated_time)
  // }, [objectsArray])

  // const allKeys = objectsArray.reduce((keys, record) => {
  //   return keys.concat(Object.keys(record))
  // }, [])

  // const uniqueKeys = [...new Set(allKeys)]

  // const columns = uniqueKeys.map(key => ({
  //   title: <strong>{key.toUpperCase()}</strong>,
  //   dataIndex: key,
  //   key: key,
  //   width: '30%',
  //   align: 'left',
  //   filters: [...new Set(objectsArray.map(record => record[key]))].map(
  //     value => ({
  //       text: value,
  //       value: value
  //     })
  //   ),
  //   onFilter: (value, record) => record[key] === value,
  //   filterSearch: true
  // }))
  // console.log('Admin Datasource - ', dataSource)

  const handleRowClick = record => {
    setIsModalVisible(true)
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
  }
  const paginationOptions = {
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    defaultPageSize: 10, // Initial page size
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
  }

  return (
    <div className='table-responsive'>
      <Table
        onRow={(record, rowIndex) => ({
          onClick: () => handleRowClick(record)
        })}
        columns={columns}
        dataSource={dataSource}
        pagination={paginationOptions}
        bordered
        rowClassName={(record, index) =>
          index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
        }
      />
    </div>
  )
}

// function AddUSer () {
//   const [form] = Form.useForm()
//   const [formData, setFormData] = useState({})

// const { authState } = useAuth()

//   console.log('Admin Auth Token - ', jwt_token)
//   // var jwt_payload = ''
//   // var jwt_payload_group = []
//   // if (token_decoded) {
//   //   console.log('Token decoded')
//   //   jwt_payload = jwt_decode(token_decoded)
//   //   jwt_payload_group = jwt_payload.group_id
//   //   console.log('JWT Group Admin - ', jwt_payload_group)
//   // }

//   const onFinish = async values => {
//     const {
//       empcode,
//       password,
//       empname,
//       contact,
//       email,
//       groupname,
//       groupid,
//       role,
//       department,
//       zone,
//       zoneid,
//       branchname,
//       branchid
//     } = values.user

//     // const requestBody = JSON.stringify({
//     //   user_id: empcode,
//     //   password,
//     //   username: empname,
//     //   phone: contact,
//     //   email,
//     //   group_name: role,
//     //   group_id: department,
//     //   role,
//     //   department,
//     //   zone,
//     //   zone_id: zoneid,
//     //   branch_name: branchname,
//     //   branch_id: branchid
//     // })

//     const requestBody = {
//       user_id: empcode,
//       password,
//       username: empname,
//       phone: contact,
//       email,
//       group_name: groupname,
//       group_id: groupid,
//       role,
//       department,
//       zone,
//       zone_id: zoneid,
//       branch_name: branchname,
//       branch_id: branchid
//     }
//     console.log('Branch ID', values.user.branchid)
//     console.log('Request body - ', requestBody)
//     const jsonreq = JSON.stringify(requestBody)
//     console.log('JSON Request - ', jsonreq)

//     const options = {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         // Authorization: 'Bearer YOUR_BEARER_TOKEN'
//         Authorization: 'Bearer ' + jwt_token
//       },
//       body: jsonreq
//     }

//     console.log('Create Request - ', options)

//     try {
//       const response = await fetch(
//         'http://127.0.0.1:8000/create/user/',
//         options
//       )
//       const data = await response.json()

//       console.log(data)
//       // form.setFieldsValue({ user: {} })
//       // setFormData({})
//       form.resetFields()
//     } catch (error) {
//       console.error('Error:', error)
//     }
//   }

//   const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 16 }
//   }

//   return (
//     // <div className='table-responsive'>
//     <Form
//       {...layout}
//       name='nest-messages'
//       onFinish={onFinish}
//       style={{ maxWidth: 600 }}
//       // validateMessages={validateMessages}
//     >
//       <Form.Item
//         name={['user', 'empcode']}
//         label='Employee Code'
//         rules={[
//           {
//             required: true,
//             // len: 8,
//             message: 'Employee Code must be 7 characters long'
//           }
//         ]}
//       >
//         <Input placeholder='Enter Employee Code' maxLength={7} />
//       </Form.Item>
//       <Form.Item
//         name={['user', 'password']}
//         label='Password'
//         rules={[{ required: true, type: 'password' }]}
//       >
//         <Input placeholder='Enter Password' />
//       </Form.Item>
//       <Form.Item
//         name={['user', 'empname']}
//         label='Employee Name'
//         // rules={[{ type: 'email' }]}
//         rules={[{ required: true }]}
//       >
//         <Input placeholder='Enter Employee Name' />
//       </Form.Item>
//       <Form.Item
//         name={['user', 'contact']}
//         label='Contact Number'
//         // rules={[{ type: 'email' }]}
//         rules={[{ required: true }]}
//       >
//         <Input placeholder='Enter Contact Number' />
//       </Form.Item>
//       <Form.Item
//         name={['user', 'email']}
//         label='Email ID'
//         // rules={[{ type: 'email' }]}
//         rules={[{ required: true }]}
//       >
//         <Input placeholder='Enter Email ID' />
//       </Form.Item>
//       <Form.Item
//         name={['user', 'groupname']}
//         label='Group Name'
//         rules={[{ required: true, message: 'Please select a group' }]}
//       >
//         <Select placeholder='Select Group'>
//           <Select.Option value='leadership'>Leadership</Select.Option>
//           <Select.Option value='zonalsupervisor'>
//             Zonal Supervisor
//           </Select.Option>
//           <Select.Option value='rpm'>Regional Portfolio Manager</Select.Option>
//           <Select.Option value='branch'>Branch</Select.Option>
//           <Select.Option value='credit'>Credit</Select.Option>
//           <Select.Option value='operations'>Operations</Select.Option>
//         </Select>
//       </Form.Item>
//       <Form.Item
//         name={['user', 'groupid']}
//         label='Group ID'
//         rules={[{ required: true, message: 'Please select a group id' }]}
//       >
//         <Select placeholder='Select Group ID'>
//           <Select.Option value='0'>0</Select.Option>
//           <Select.Option value='1'>1</Select.Option>
//           <Select.Option value='2'>2</Select.Option>
//           <Select.Option value='3'>3</Select.Option>
//           <Select.Option value='4'>4</Select.Option>
//           <Select.Option value='5'>5</Select.Option>
//           <Select.Option value='10'>10</Select.Option>
//         </Select>
//       </Form.Item>
//       <Form.Item
//         name={['user', 'role']}
//         label='Role'
//         rules={[{ required: true, message: 'Please select a role' }]}
//       >
//         <Select placeholder='Select Role'>
//           <Select.Option value='admin'>Admin</Select.Option>
//           <Select.Option value='ba'>BA</Select.Option>
//           <Select.Option value='2'>2</Select.Option>
//           <Select.Option value='3'>3</Select.Option>
//           <Select.Option value='4'>4</Select.Option>
//           <Select.Option value='5'>5</Select.Option>
//           <Select.Option value='10'>10</Select.Option>
//         </Select>
//       </Form.Item>
//       <Form.Item
//         name={['user', 'department']}
//         label='Department'
//         rules={[{ required: true, message: 'Please select a department' }]}
//       >
//         <Select placeholder='Select Department'>
//           <Select.Option value='it'>IT</Select.Option>
//           <Select.Option value='ops'>OPS</Select.Option>
//           <Select.Option value='credit'>Credit</Select.Option>
//           <Select.Option value='3'>3</Select.Option>
//           <Select.Option value='4'>4</Select.Option>
//           <Select.Option value='5'>5</Select.Option>
//           <Select.Option value='10'>10</Select.Option>
//         </Select>
//       </Form.Item>
//       <Form.Item
//         name={['user', 'zone']}
//         label='Zone'
//         rules={[{ required: true, message: 'Please select a zone' }]}
//       >
//         <Select placeholder='Select Zone'>
//           <Select.Option value='east'>EAST</Select.Option>
//           <Select.Option value='west'>WEST</Select.Option>
//           <Select.Option value='north'>NORTH</Select.Option>
//           <Select.Option value='south'>SOUTH</Select.Option>
//         </Select>
//       </Form.Item>

//       <Form.Item
//         name={['user', 'zoneid']}
//         label='Zone-ID'
//         rules={[{ required: true }]}
//       >
//         <Input placeholder='Enter Zone ID' />
//         {/* <Input.TextArea /> */}
//       </Form.Item>
//       <Form.Item
//         name={['user', 'branchname']}
//         label='Branch Name'
//         rules={[{ required: true }]}
//       >
//         <Input placeholder='Enter Branch Name' />
//         {/* <Input.TextArea /> */}
//       </Form.Item>
//       <Form.Item
//         name={['user', 'branchid']}
//         label='Branch-ID'
//         rules={[{ required: true }]}
//       >
//         <Input placeholder='Enter Branch ID' />
//         {/* <Input.TextArea /> */}
//       </Form.Item>
//       <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
//         <Button type='primary' htmlType='submit'>
//           Add User
//         </Button>
//       </Form.Item>
//     </Form>
//     // </div>
//   )
// }

function Tables() {
  const onChange = key => {
    console.log('Tab changed:', key)
  }

  return (
    <>
      <Tabs
        style={{
          marginLeft: '20px',
          marginRight: '20px'
        }}
        defaultActiveKey='1'
        onChange={onChange}
        type='card'
      >
        {tabsItems.map(tab => (
          <TabPane tab={tab.label} key={tab.key}>
            {tab.children}
          </TabPane>
        ))}
      </Tabs>
    </>
  )
}

export default Tables
