import { createSlice } from '@reduxjs/toolkit'

const updatedOnSlice = createSlice({
  name: 'supervisorUpdatedOn',
  initialState: null, // Initial value of updatedOn
  reducers: {
    setSupervisorUpdatedOn: (state, action) => action.payload
  }
})

export const { setSupervisorUpdatedOn } = updatedOnSlice.actions

export default updatedOnSlice.reducer
