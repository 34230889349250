import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../apiConfig'

export const detailedStageReport = createAsyncThunk(
  'detailedStageReport',
  async ({ stage, jwtBearer }) => {
    // Receive the stage as an argument
    const response = await fetch(
      `${BASE_URL}pipeline/selected/credit/data?stage=${stage}`, // Append the stage to the URL
      {
        headers: {
          Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
        }
      }
    )
    return response.json()
  }
)

const stageReportSlice = createSlice({
  name: 'stageReport',
  initialState: {
    isLoading: false,
    data: null,
    isError: false
  },
  extraReducers: builder => {
    builder.addCase(detailedStageReport.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(detailedStageReport.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(detailedStageReport.rejected, (state, action) => {
      console.log('Error', action.payload)
      state.isError = true
    })
  }
})

export default stageReportSlice.reducer
