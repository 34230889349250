import {
  Tabs, Card
} from 'antd'
import CMAUserTickets from '../views/CMAUserTickets'
import CMAClosedTickets from '../views/CMAClosedTickets'
import CMAWIPTickets from '../views/CMAWIPTickets'
import jwt_decode from 'jwt-decode'
import { useAuth } from '../../../context/AuthContext'
import { fetchUserDepartment } from '../../../redux/slice/getCMAUserDepartment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'


const { TabPane } = Tabs

const tabsItems = [
  {
    key: '1',
    label: <strong>OPEN TICKETS</strong>,
    children: (
      <CMAUserTickets />
    )
  },
  {
    key: '2',
    label: <strong>WIP TICKETS</strong>,
    children: (
      <CMAWIPTickets />
    )
  },
  {
    key: '3',
    label: <strong>CLOSED TICKETS</strong>,
    children: (
      <CMAClosedTickets />
    )
  }
]

// function Tables() {
const Tables = (props) => {
  const dispatch = useDispatch()
  const { authState, onLogout } = useAuth()

  var token_decoded = authState.token
  var jwtBearer = authState.token
  var jwt_payload = ''
  var jwt_payload_group = []
  var user_id = ''
  if (jwtBearer) {

    jwt_payload = jwt_decode(jwtBearer)
    user_id = jwt_payload.user_id
    // jwt_payload_group = jwt_payload.group_id
    jwt_payload_group = props.userDetails?.group_id
  }

  // useEffect(() => {
  //     dispatch(fetchUserDepartment({ jwtBearer, userid: user_id })).then(() => {
  //       setLoading(false) // Set loading state to false when the data is fetched
  //     })
  //   }, [])
  const [tabKey, setTabKey] = React.useState('1');
  useEffect(() => {
    dispatch(fetchUserDepartment({ jwtBearer, userid: user_id }))
  }, [])

  const state = useSelector(state => state)
  const userDepartment = state.CMAUserDepartmentSlice.data?.message || ''
  console.log('CMA User Department - ', userDepartment)

  const onChange = key => {
    console.log('Tab changed:', key)
    setTabKey(key);
  }

  return (
    <>
      <Tabs
        style={{
          marginLeft: '20px',
          marginRight: '20px'
        }}
        defaultActiveKey='1'
        activeKey={tabKey}
        onChange={onChange}
        type='card'
        size='large'
      >
        {tabsItems.map(tab => (
          (tab.key === '2' && userDepartment !== 'Support') ? null : (
            <TabPane tab={tab.label} key={tab.key}>
              {/* {tab.children} */}
              {tab.key === tabKey && tab.children}
            </TabPane>
          )
        ))}
      </Tabs>
    </>
  )
}

export default Tables
