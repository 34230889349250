import { createSlice } from '@reduxjs/toolkit'

const collapseSlice = createSlice({
  name: 'sidenavCollapse',
  initialState: null, // Initial value of collapse
  reducers: {
    setCollapsed: (state, action) => action.payload
  }
})

export const { setCollapsed } = collapseSlice.actions

export default collapseSlice.reducer
