import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { CMA_SUPPORT_URL } from '../apiConfig'

export const fetchUserTickets = createAsyncThunk(
  'fetchUserTickets',
  async ({ jwtBearer, userid, status }) => {
    const response = await fetch(`${CMA_SUPPORT_URL}query/get/all/ticket/?userid=${userid}&status=${status}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
      }
    })
    return response.json()
  }
)

const userTicketsSlice = createSlice({
  name: 'userTicketsSlice',
  initialState: {
    isLoading: false,
    data: null,
    isError: false
  },
  extraReducers: builder => {
    builder.addCase(fetchUserTickets.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchUserTickets.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(fetchUserTickets.rejected, (state, action) => {
      console.log('Error', action.payload)
      state.isError = true
    })
  }
})

export default userTicketsSlice.reducer
