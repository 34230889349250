import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../apiConfig';

// Utility function to create query params string
const createQueryParams = (page, pageSize, filters) => {
  const params = new URLSearchParams();

  if (page) params.append('page', page);
  if (pageSize) params.append('page_size', pageSize);
  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (filters[key]) params.append(key, filters[key]);
    });
  }

  return params.toString();
};

// Define async thunks for fetching data from each endpoint with optional query parameters
export const fetchRejectData = createAsyncThunk(
  'collectionData/fetchRejectData',
  async ({ jwtBearer, page, pageSize, filters } = {}) => {
    const queryParams = createQueryParams(page, pageSize, filters);
    const response = await axios.get(`${BASE_URL}collection/reject/data${queryParams ? `?${queryParams}` : ''}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}`
      }
    });
    return response.data;
  }
);

export const fetchSuccessData = createAsyncThunk(
  'collectionData/fetchSuccessData',
  async ({ jwtBearer, page, pageSize, filters } = {}) => {
    const queryParams = createQueryParams(page, pageSize, filters);
    const response = await axios.get(`${BASE_URL}collection/success/data${queryParams ? `?${queryParams}` : ''}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}`
      }
    });
    return response.data;
  }
);

export const fetchPendingData = createAsyncThunk(
  'collectionData/fetchPendingData',
  async ({ jwtBearer, page, pageSize, filters } = {}) => {
    const queryParams = createQueryParams(page, pageSize, filters);
    const response = await axios.get(`${BASE_URL}collection/pending/data${queryParams ? `?${queryParams}` : ''}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}`
      }
    });
    return response.data;
  }
);

export const fetchPendingForApprovalData = createAsyncThunk(
  'collectionData/fetchPendingForApprovalData',
  async ({ jwtBearer, page, pageSize, filters } = {}) => {
    const queryParams = createQueryParams(page, pageSize, filters);
    const response = await axios.get(`${BASE_URL}collection/pending-approval/data${queryParams ? `?${queryParams}` : ''}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}`
      }
    });
    return response.data;
  }
);

const initialState = {
  rejectData: null,
  successData: null,
  pendingData: null,
  pendingForApprovalData: null,
  rejectCount: null,
  successCount: null,
  pendingCount: null,
  pendingForApprovalCount: null,
  errorReject: null,
  errorSuccess: null,
  errorPending: null,
  errorPendingForApproval: null,
  isLoadingSuccess: false,
  isLoadingReject: false,
  isLoadingPending: false,
  isLoadingPendingForApproval: false,
};

// Create the slice
const collectionDataSlice = createSlice({
  name: 'collectionData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle thunk states for reject data
      .addCase(fetchRejectData.pending, (state) => {
        state.isLoadingReject = true;
        state.errorReject = null; // Clear previous errors
      })
      .addCase(fetchRejectData.fulfilled, (state, action) => {
        state.isLoadingReject = false;
        state.rejectData = action.payload.reject.data;
        state.rejectCount = action.payload.reject.total_count;
      })
      .addCase(fetchRejectData.rejected, (state, action) => {
        state.isLoadingReject = false;
        state.errorReject = action.error.message;
      })

      // Handle thunk states for success data
      .addCase(fetchSuccessData.pending, (state) => {
        state.isLoadingSuccess = true;
        state.errorSuccess = null; // Clear previous errors
      })
      .addCase(fetchSuccessData.fulfilled, (state, action) => {
        state.isLoadingSuccess = false;
        state.successData = action.payload.success.data;
        state.successCount = action.payload.success.total_count;
      })
      .addCase(fetchSuccessData.rejected, (state, action) => {
        state.isLoadingSuccess = false;
        state.errorSuccess = action.error.message;
      })

      // Handle thunk states for pending data
      .addCase(fetchPendingData.pending, (state) => {
        state.isLoadingPending = true;
        state.errorPending = null; // Clear previous errors
      })
      .addCase(fetchPendingData.fulfilled, (state, action) => {
        state.isLoadingPending = false;
        state.pendingData = action.payload.pending.data;
        state.pendingCount = action.payload.pending.total_count;
      })
      .addCase(fetchPendingData.rejected, (state, action) => {
        state.isLoadingPending = false;
        state.errorPending = action.error.message;
      })

      // Handle thunk states for pending for approval data
      .addCase(fetchPendingForApprovalData.pending, (state) => {
        state.isLoadingPendingForApproval = true;
        state.errorPendingForApproval = null; // Clear previous errors
      })
      .addCase(fetchPendingForApprovalData.fulfilled, (state, action) => {
        state.isLoadingPendingForApproval = false;
        state.pendingForApprovalData = action.payload.pending_for_approval.data;
        state.pendingForApprovalCount = action.payload.pending_for_approval.total_count;
      })
      .addCase(fetchPendingForApprovalData.rejected, (state, action) => {
        state.isLoadingPendingForApproval = false;
        state.errorPendingForApproval = action.error.message;
      });
  }
});

export default collectionDataSlice.reducer;
