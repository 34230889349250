import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../apiConfig';

// Helper function for API requests
const fetchData = async (url, jwtBearer) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${jwtBearer}`,
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Network response was not ok: ${response.status} ${errorText}`);
  }

  return await response.json();
};

// Thunks
export const fetchAutoRepeatData = createAsyncThunk(
  'repeatEligibility/fetchAutoRepeatData',
  async ({ jwtBearer, page, pageSize, filters }, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        page,
        page_size: pageSize,
        ...filters
      }).toString();

      const result = await fetchData(`${BASE_URL}pipeline/auto-repeat/?${queryParams}`, jwtBearer);
      return result;
    } catch (error) {
      console.error('Fetch error:', error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchFilteredAutoRepeatData = createAsyncThunk(
  'repeatEligibility/fetchFilteredAutoRepeatData',
  async ({ jwtBearer, filters }, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams(filters).toString();
      const result = await fetchData(`${BASE_URL}pipeline/auto-repeat/?${queryParams}`, jwtBearer);
      return result;
    } catch (error) {
      console.error('Fetch error:', error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchFullAppraisalData = createAsyncThunk(
  'repeatEligibility/fetchFullAppraisalData',
  async ({ jwtBearer, page, pageSize, filters }, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        page,
        page_size: pageSize,
        ...filters
      }).toString();

      const result = await fetchData(`${BASE_URL}pipeline/full-appraisal/?${queryParams}`, jwtBearer);
      return result;
    } catch (error) {
      console.error('Fetch error:', error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchFilteredFullAppraisalData = createAsyncThunk(
  'repeatEligibility/fetchFilteredFullAppraisalData',
  async ({ jwtBearer, filters }, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams(filters).toString();
      const result = await fetchData(`${BASE_URL}pipeline/full-appraisal/?${queryParams}`, jwtBearer);
      return result;
    } catch (error) {
      console.error('Fetch error:', error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Slice
const repeatEligibility = createSlice({
  name: 'repeatEligibility',
  initialState: {
    autoRepeatData: [],
    fullAppraisalData: [],
    filteredAutoRepeatData: [],
    filteredFullAppraisalData: [],
    totalCountAutoRepeat: 0,
    totalCountFullAppraisal: 0,
    totalCountFilteredAutoRepeat: 0,
    totalCountFilteredFullAppraisal: 0,
    isLoadingAutoRepeat: false,
    isLoadingFullAppraisal: false,
    isLoadingFilteredAutoRepeat: false,
    isLoadingFilteredFullAppraisal: false,
    errorAutoRepeat: null,
    errorFullAppraisal: null,
    errorFilteredAutoRepeat: null,
    errorFilteredFullAppraisal: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Auto Repeat Data
      .addCase(fetchAutoRepeatData.pending, (state) => {
        state.isLoadingAutoRepeat = true;
        state.errorAutoRepeat = null;
      })
      .addCase(fetchAutoRepeatData.fulfilled, (state, action) => {
        state.isLoadingAutoRepeat = false;
        state.autoRepeatData = action.payload.data.auto_repeat || [];
        console.log("state.autoRepeatData", state.autoRepeatData)
        state.totalCountAutoRepeat = action.payload.data.total_auto_repeat || 0;
        console.log(" state.totalCountAutoRepeat", state.totalCountAutoRepeat)
      })
      .addCase(fetchAutoRepeatData.rejected, (state, action) => {
        state.isLoadingAutoRepeat = false;
        state.errorAutoRepeat = action.payload || 'An error occurred while fetching auto repeat data';
      })

      // Full Appraisal Data
      .addCase(fetchFullAppraisalData.pending, (state) => {
        state.isLoadingFullAppraisal = true;
        state.errorFullAppraisal = null;
      })
      .addCase(fetchFullAppraisalData.fulfilled, (state, action) => {
        state.isLoadingFullAppraisal = false;
        state.fullAppraisalData = action.payload.data.full_appraisal || [];
        console.log("state.fullappraisalData", state.fullAppraisalData)
        state.totalCountFullAppraisal = action.payload.data.total_full_appraisal || 0;
        console.log("state.totalCountFullAppraisal", state.totalCountFullAppraisal)
      })
      .addCase(fetchFullAppraisalData.rejected, (state, action) => {
        state.isLoadingFullAppraisal = false;
        state.errorFullAppraisal = action.payload || 'An error occurred while fetching full appraisal data';
      })


      // Filtered Auto Repeat Data
      .addCase(fetchFilteredAutoRepeatData.pending, (state) => {
        state.isLoadingFilteredAutoRepeat = true;
        state.errorFilteredAutoRepeat = null;
      })
      .addCase(fetchFilteredAutoRepeatData.fulfilled, (state, action) => {
        state.isLoadingFilteredAutoRepeat = false;
        state.filteredAutoRepeatData = action.payload.data.auto_repeat || [];
        console.log("state.filteredAutoRepeatData ", state.filteredAutoRepeatData)
      })
      .addCase(fetchFilteredAutoRepeatData.rejected, (state, action) => {
        state.isLoadingFilteredAutoRepeat = false;
        state.errorFilteredAutoRepeat = action.payload || 'An error occurred while fetching filtered auto repeat data';
      })

      // Filtered Full Appraisal Data
      // Filtered Full Appraisal Data
      .addCase(fetchFilteredFullAppraisalData.pending, (state) => {
        state.isLoadingFilteredFullAppraisal = true;
        state.errorFilteredFullAppraisal = null;
      })
      .addCase(fetchFilteredFullAppraisalData.fulfilled, (state, action) => {
        state.isLoadingFilteredFullAppraisal = false;
        state.filteredFullAppraisalData = action.payload.data.full_appraisal || [];
        console.log("state.filteredFullAppraisalData", state.filteredFullAppraisalData);
      })
      .addCase(fetchFilteredFullAppraisalData.rejected, (state, action) => {
        state.isLoadingFilteredFullAppraisal = false;
        state.errorFilteredFullAppraisal = action.payload || 'An error occurred while fetching filtered full appraisal data';
      });

  },
});

export default repeatEligibility.reducer;
