import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../apiConfig'

export const downloadCollectionReport = createAsyncThunk(
  'downloadCollectionReport',
  async ({ jwtBearer, type }) => {
    const response = await fetch(`${BASE_URL}collection/report/download/?type=${type}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
      }
    })
    const blob = await response.blob() // Extract the blob from the response

    // Create a temporary URL for the blob
    const url = URL.createObjectURL(blob)

    // Create a link element and trigger the download
    const link = document.createElement('a')
    link.href = url
    link.download = `Collection_Report.xlsx` // Set the desired file name
    link.click()

    // Clean up the temporary URL
    URL.revokeObjectURL(url)
  }
)

const downloadCollectionReportSlice = createSlice({
  name: 'downloadCollectionReport',
  initialState: {
    isLoading: false,
    data: null,
    isError: false
  },
  extraReducers: builder => {
    builder.addCase(downloadCollectionReport.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(downloadCollectionReport.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(downloadCollectionReport.rejected, (state, action) => {
      console.log('Error', action.payload)
      state.isError = true
    })
  }
})

export default downloadCollectionReportSlice.reducer
