import { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import * as SecureStore from 'expo-secure-store'
import AsyncStorage from '@react-native-async-storage/async-storage'
import jwt_decode from 'jwt-decode'
import { loginUser } from '../redux/slice/loginUserSlice'
import { verifyOTP } from '../redux/slice/verifyOTP'
import { useDispatch } from 'react-redux'
import { BASE_URL } from '../redux/apiConfig'

interface AuthProps {
  authState?: { token: string | null; authenticated: boolean | null }
  // onRegister?: (userid: string, password: string) => Promise<any>
  onLogin?: (userid: String, password: String) => Promise<any>
  onVerifyOtp?: (userid: string, otp: string) => Promise<any>;
  onLogout?: () => Promise<any>
}

const TOKEN_KEY = 'my-jwt'
// export const API_URL = 'http://127.0.0.1:8000/login/user/'

const AuthContext = createContext<AuthProps>({})

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }: any) => {
  const [authState, setAuthState] = useState<{
    token: string | null
    authenticated: boolean | null
  }>({
    token: null,
    authenticated: null
  })

  const dispatch = useDispatch() // Get the dispatch function

  // useEffect(() => {
  //   const loadToken = async () => {
  //     const token = await AsyncStorage.getItem(TOKEN_KEY)
  //     console.log('Stored Token', token)

  //     if (token) {
  //       axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

  //       setAuthState({
  //         token: token,
  //         authenticated: true
  //       })
  //     }
  //   }
  //   loadToken()
  // }, [])

  useEffect(() => {
    const loadToken = async () => {
      // Check if the cookie exists by making a request to validate the token
      try {
        console.log('result auth useeffect triggered')
        const response = await axios.get(`${BASE_URL}user/token/validate/`, {
          withCredentials: true,
        });
        console.log('result auth refresh - ', response)
        if (response.data) {
          setAuthState({
            token: response.data.access_token,
            authenticated: true,
          });
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data.access_token}`;
        }
      } catch (error) {
        console.log("Error validating token", error);
      }
    };

    loadToken();
  }, []);

  const login = async (userid: string, password: string) => {
    try {
      // Dispatch login action and get the result
      const result = await dispatch(loginUser({ userid, password }));
      console.log('Result Auth', result);
      // console.log('Token Auth', result.payload.access_token);

      if (result.payload?.otp_required) {
        // Set state to indicate that OTP is required
        setAuthState({
          token: null,
          authenticated: false
          // otpRequired: true,
        });
        return { otp_required: true }; // Indicate OTP requirement
      }

      // If no OTP is required, proceed with normal login
      // setAuthState({
      //   token: result.payload.access_token,
      //   authenticated: true
      // otpRequired: false,
      // });

      console.log('Auth State Login', authState);

      // Set default authorization header for future requests
      // axios.defaults.headers.common['Authorization'] = `Bearer ${result.payload.access_token}`;

      // Store token in AsyncStorage
      await AsyncStorage.setItem(TOKEN_KEY, result.payload ? result.payload.access_token : '');

      return result;
    } catch (e) {
      console.log('Error Login', e);
      return { error: true, msg: e };
    }
  };

  // const verifyOtp = async (userid: string, otp: string) => {
  //   try {
  //     // const response = await axios.post("http://localhost:8000/login/verify/otp/", { userid, otp });
  //     const result = await dispatch(verifyOTP({ userid, otp }));

  //     console.log("Result Auth Verify", result);
  //     console.log("Token Auth", result.payload.access_token);

  //     setAuthState({
  //       token: result.payload.access_token,
  //       authenticated: true,
  //     });

  //     axios.defaults.headers.common[
  //       "Authorization"
  //     ] = `Bearer ${result.payload.access_token}`;

  //     return result;
  //   } catch (error) {
  //     console.log('verify error - ', error)
  //     // alert(error.error.message)
  //     return { error: true, msg: error };
  //   }
  // };
  const verifyOtp = async (userid: string, otp: string) => {
    try {
      // const response = await axios.post("http://localhost:8000/login/verify/otp/", { userid, otp });
      const result = await dispatch(verifyOTP({ userid, otp }));
      console.log('Verify OTP response - ', result)

      // const token = response.payload ? response.payload.access_token : '';
      // if (token !== '') {
      //   setAuthState({
      //     token: token,
      //     authenticated: true
      //     // otpRequired: false,
      //   });
      // }

      // // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      // await AsyncStorage.setItem(TOKEN_KEY, token);

      // return response;

      // console.log('Result Auth', result);
      console.log('Token Auth', result.payload.access_token);
      // console.log('Token Auth', result.payload);

      setAuthState({
        token: result.payload.access_token,
        authenticated: true,
      });

      // axios.defaults.headers.common['Authorization'] = `Bearer ${result.payload.access_token}`;
      axios.defaults.headers.common['Authorization'] = `Bearer ${result.payload.access_token}`;

      return result;
    } catch (error) {
      console.log('verify error - ', error)
      // alert(error.error.message)
      return { error: true, msg: error };
    }
  };

  const userGroup = async () => {
    var token_decoded = authState.token
    var jwt_payload = ''
    var jwt_payload_group = []
    if (token_decoded) {
      jwt_payload = jwt_decode(token_decoded)
      jwt_payload_group = jwt_payload.group
      console.log('JWT Group', jwt_payload_group)
    }
    return jwt_payload_group
  }

  // const logout = async () => {
  //   console.log('In logout func')
  //   await AsyncStorage.removeItem(TOKEN_KEY)

  //   axios.defaults.headers.common['Authorization'] = ''

  //   setAuthState({
  //     token: null,
  //     authenticated: false
  //   })
  // }

  const logout = async () => {
    console.log('In logout func');
    try {
      const response = await axios.post(`${BASE_URL}user/logout/`, {}, { withCredentials: true });
      console.log('Logout response:', response.data); // Log the response      delete axios.defaults.headers.common['Authorization'];

      setAuthState({
        token: null,
        authenticated: false,
      });
    } catch (e) {
      console.log('Error Logging Out', e);
    }
  };

  const value = {
    // onRegister: register,
    onLogin: login,
    onLogout: logout,
    onVerifyOtp: verifyOtp,
    authState,
    userGroup
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
