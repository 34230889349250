import { createSlice } from '@reduxjs/toolkit'

const updatedOnSlice = createSlice({
  name: 'creditUpdatedOn',
  initialState: null, // Initial value of updatedOn
  reducers: {
    setCreditUpdatedOn: (state, action) => action.payload
  }
})

export const { setCreditUpdatedOn } = updatedOnSlice.actions

export default updatedOnSlice.reducer
