export const ROLES = {
  10: 'ADMIN',
  consumer: 'consumer',
  user: 'USER',
  los: 'LOS',
  vendor: 'vendor',
  admin: 'ADMIN'
}
//
export const SCOPES = {
  canViewCollection: 'can-view-collection',
  canViewReports: 'can-view-reports',
  canViewMIS: 'can-view-mis',
  canViewLeads: 'can-view-leads',
  canViewCMA: 'can-view-cma',
  canViewAdmin: 'can-view-admin',
  canViewInventory: 'can-view-inventory',
  canViewCMATickets: 'can-view-CMA-tickets',
  canViewAD: 'can-view-AD',
  canViewDigitalJourney: 'can-view-digital-journey',
  canViewZohoMIS: 'can-view-Zoho-MIS',
  canViewPDD: 'can-view-pdd'
}

export const PERMISSIONS = {
  10: [
    SCOPES.canViewCollection,
    SCOPES.canViewReports,
    SCOPES.canViewAdmin,
    SCOPES.canViewMIS,
    SCOPES.canViewLeads,
    SCOPES.canViewCMA,
    SCOPES.canViewInventory,
    SCOPES.canViewCMATickets,
    SCOPES.canViewDigitalJourney,
    SCOPES.canViewZohoMIS,
    SCOPES.canViewPDD
  ],
  0: [SCOPES.canViewReports, SCOPES.canViewLeads, SCOPES.canViewCollection],
  1: [SCOPES.canViewReports, SCOPES.canViewMIS, SCOPES.canViewLeads, SCOPES.canViewCollection, SCOPES.canViewZohoMIS],
  2: [SCOPES.canViewReports, SCOPES.canViewCollection],
  3: [SCOPES.canViewReports, SCOPES.canViewMIS, SCOPES.canViewLeads, SCOPES.canViewCollection, SCOPES.canViewPDD],
  4: [SCOPES.canViewReports, SCOPES.canViewCollection, SCOPES.canViewCMATickets],
  5: [SCOPES.canViewReports, SCOPES.canViewMIS, SCOPES.canViewCollection, SCOPES.canViewCMATickets, SCOPES.canViewLeads, SCOPES.canViewPDD],
  6: [SCOPES.canViewCMATickets],
  7: [SCOPES.canViewInventory],
  11: [SCOPES.canViewAdmin],
  12: [SCOPES.canViewDigitalJourney, SCOPES.canViewCMATickets]
}
