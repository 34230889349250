import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../apiConfig'

export const fetchSavedCMAWidgets = createAsyncThunk(
  'fetchSavedCMAWidgets',
  async ({ jwt_token, userid }) => {
    console.log('Request URL - ', `${BASE_URL}cma/get/saved/widgets/?user_id=${userid}`)
    const response = await fetch(`${BASE_URL}cma/get/saved/widgets/?user_id=${userid}`, {
      headers: {
        Authorization: `Bearer ${jwt_token}` // Include the bearer token in the request header
      }
    })
    return response.json()
  }
)

const fetchSavedCMAWidgetsSlice = createSlice({
  name: 'fetchSavedCMAWidgets',
  initialState: {
    isLoading: false,
    data: null,
    isError: false
  },
  extraReducers: builder => {
    builder.addCase(fetchSavedCMAWidgets.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchSavedCMAWidgets.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(fetchSavedCMAWidgets.rejected, (state, action) => {
      console.log('Error', action.payload)
      state.isError = true
    })
  }
})

export default fetchSavedCMAWidgetsSlice.reducer
