import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { useAuth } from "../../../context/AuthContext";
import { fetchLeads } from "../../../redux/slice/leadsSlice";
// import LeadsPivot from './LeadsPivot'
// import LeadsSummary from './LeadsSummary'
import { Suspense, lazy } from "react";
const LeadsSummary = lazy(() => import("./LeadsSummary"));
import { setLeadUpdatedOn } from "../../../redux/slice/updatedOnLeadSlice";

import { Spin, Table, Card, Collapse, Divider } from "antd";

import AsyncStorage from "@react-native-async-storage/async-storage";
// import { CSVLink } from "react-csv";
import { HashLoader } from 'react-spinners'
import { StyledCSVLink, StyledTable } from "../components/styleComp";

const { Panel } = Collapse;

const Leads = (props) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [convertDataSource, setConvertDataSource] = useState([]);
  const [pivotDataSource, setPivotDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [uploadAlert, setUploadAlert] = useState("");
  const [lastUpdatedTime, setLastUpdatedTime] = useState(null);
  const [fileList, setFileList] = useState([]); // To store the uploaded file
  const [userUploadType, setUserUploadType] = useState(null);
  const [isPanelExpanded, setIsPanelExpanded] = useState(false);

  const { authState, onLogout } = useAuth();

  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var jwt_payload_group = [];
  var jwt_payload_expiry = [];
  if (jwtBearer) {
    console.log("Token decoded");
    jwt_payload = jwt_decode(jwtBearer);
    // jwt_payload_group = jwt_payload.group_id;
    jwt_payload_group = props.userDetails.group_id;
    jwt_payload_expiry = jwt_payload.exp;
    console.log("JWT Group Reports - ", jwt_payload_group);
  }

  // Session Timeout
  const TOKEN_KEY = "my-jwt";
  var currentTime = Math.floor(Date.now() / 1000);

  // Compare current time with token expiry time
  if (jwt_payload_expiry.length != 0) {
    if (currentTime > jwt_payload_expiry) {
      console.log("In token expired condition");
      // Token has expired, perform logout
      AsyncStorage.removeItem(TOKEN_KEY);
      onLogout();
      location.reload();
    }
  }
  // Session Timeout

  console.log("JWT Bearer Branch MIS - ", jwtBearer);

  useEffect(() => {
    dispatch(fetchLeads({ jwtBearer })).then(() => {
      setLoading(false); // Set loading state to false when the data is fetched
    });
  }, []);

  const state = useSelector((state) => state);
  console.log("Leads State - ", state);

  const tokenExpired = state.leadsReports.data?.detail || "";
  // const TOKEN_KEY = "my-jwt";
  if (tokenExpired == "Signature has expired.") {
    console.log("In token expired condition");
    AsyncStorage.removeItem(TOKEN_KEY);
    onLogout;
    location.reload();
  }

  const allFields = state.leadsReports.data?.message || [];
  const allBA = state.leadsReports.data?.total_ba || [];
  const allBAWithLead = state.leadsReports.data?.ba_with_lead || [];
  const ftmData = state.leadsReports.data?.ftm_data || [];
  const updatedOn = state.leadsReports.data?.updated_on || "";
  const allFieldsPivot = state.leadsReports.data?.lead_pivot || {};
  dispatch(setLeadUpdatedOn(updatedOn));

  console.log("Pivot Response", allFieldsPivot);

  console.log("Total BA", allBA);
  console.log("BA with lead", allBAWithLead);
  console.log("FTM Data", ftmData);
  // let allFieldsPivotJSON
  // const allFieldsPvotJSON = JSON.parse(allFieldsPivot)
  // if (Object.keys(allFieldsPivot).length > 0) {
  // useEffect(() => {
  //   setPivotDataSource(JSON.parse(allFieldsPivot))
  // }, [allFieldsPivot])
  // setPivotDataSource(allFieldsPivot)
  // allFieldsPivotJSON = JSON.parse(allFieldsPivot)
  // console.log('PIVOT', allFieldsPivotJSON)
  // }
  // console.log('PIVOT', allFieldsPivot)
  console.log("CMA Registered users Pandas - ", allFields);

  // const keys = state.leadsReports.data
  //   ? Object.keys(allFields['current_state'])
  //   : []
  // const keys = Object.keys(allFields['current_state'])

  const current_state = allFields["current_state"]
    ? allFields["current_state"]
    : [];

  const keys = current_state ? Object.keys(current_state) : [];

  // Create an array of objects in the desired format
  const objectsArray = keys.map((key) => {
    const obj = {};
    for (const subKey in allFields) {
      const value = String(allFields[subKey][key])
        .toUpperCase()
        .replace(/NULL/gi, "");
      obj[subKey] = value;
    }
    return obj;
  });
  console.log("CMA Registered users JSON - ", objectsArray);

  useEffect(() => {
    setDataSource(objectsArray);
  }, [allFields]);

  const allKeys = objectsArray.reduce((keys, record) => {
    return keys.concat(Object.keys(record));
  }, []);

  const uniqueKeys = [...new Set(allKeys)];

  const columns = uniqueKeys.map((key) => ({
    title: <strong>{key.toUpperCase()}</strong>,
    dataIndex: key,
    key: key,
    width: "30%",
    align: "left",
    render: (text) => (
      // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
      <span className="poppins-font" style={{ fontSize: "16px" }}>
        {text}
      </span>
    ),
    filters: [...new Set(objectsArray.map((record) => record[key]))].map(
      (value) => ({
        text: value,
        value: value,
      })
    ),
    onFilter: (value, record) => record[key] === value,
    filterSearch: true,
  }));
  console.log("Branch MIS Datasource - ", dataSource);

  // Convert Leads

  const allFieldsConvert = state.leadsReports.data?.convert_leads || [];
  // }
  // console.log('CMA Registered users Pandas - ', allFieldsConvert)

  // const keysConvert = state.leadsReports.data
  //   ? Object.keys(allFieldsConvert['current_state'])
  //   : []

  const current_state_converted = allFieldsConvert["current_state"]
    ? allFieldsConvert["current_state"]
    : [];

  const keysConvert = current_state_converted
    ? Object.keys(current_state_converted)
    : [];

  // Create an array of objects in the desired format
  const objectsArrayConvert = keysConvert.map((key) => {
    const obj = {};
    for (const subKey in allFieldsConvert) {
      // obj[subKey] = allFieldsConvert[subKey][key]
      const value = String(allFieldsConvert[subKey][key])
        .toUpperCase()
        .replace(/NULL/gi, "");
      obj[subKey] = value;
    }
    return obj;
  });
  console.log("CMA Registered users JSON convert- ", objectsArrayConvert);

  useEffect(() => {
    setConvertDataSource(objectsArrayConvert);
  }, [allFieldsConvert]);

  const allKeysConvert = objectsArrayConvert.reduce((keys, record) => {
    return keys.concat(Object.keys(record));
  }, []);

  const uniqueKeysConvert = [...new Set(allKeysConvert)];

  const ConvertColumns = uniqueKeysConvert.map((key) => ({
    title: <strong>{key.toUpperCase()}</strong>,
    dataIndex: key,
    key: key,
    width: "30%",
    align: "left",
    render: (text) => (
      // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
      <span className="poppins-font" style={{ fontSize: "16px" }}>
        {text}
      </span>
    ),
    filters: [...new Set(objectsArrayConvert.map((record) => record[key]))].map(
      (value) => ({
        text: value,
        value: value,
      })
    ),
    onFilter: (value, record) => record[key] === value,
    filterSearch: true,
  }));

  // Convert Leads

  const paginationOptions = {
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
    defaultPageSize: 10, // Initial page size
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  return (
    <>
      <center>
        <div>
          {/* <Collapse defaultActiveKey={['1']} bordered={false}> */}
          <Collapse bordered={true}>
            {/* <Panel header='LEAD SUMMARY' key='1'> */}
            {jwt_payload_group == "3" ||
              jwt_payload_group == "4" ||
              jwt_payload_group == "5" ? null : (
              // <Card
              //   layout='horizontal'
              //   bordered={true}
              //   className='criclebox tablespace mb-24'
              //   title={
              //     <>
              //       <strong>LEAD SUMMARY</strong>
              //     </>
              //   }
              //   centered
              //   style={{ width: '100%', marginTop: '20px' }}
              // >
              //  <Suspense
              //   fallback={<div>Component1 are loading please wait...</div>}
              // >
              <LeadsSummary
                allFieldsPivot={allFieldsPivot}
                allBA={allBA}
                allBAWithLead={allBAWithLead}
                ftmData={ftmData}
              />
              //  </Suspense>
              // </Card>
            )}
            {/* <Card
              layout='horizontal'
              bordered={true}
              className='criclebox tablespace mb-24'
              title={
                <>
                  <strong>LEAD SUMMARY</strong>
                  {updatedOn && (
                    <span style={{ marginLeft: '10px', fontSize: '14px' }}>
                      <br />
                      <strong>Last Updated On</strong> - {updatedOn}
                    </span>
                  )}
                </>
              }
              centered
              style={{ width: '100%', marginTop: '20px' }}
              extra={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '12px'
                  }}
                ></div>
              }
            >
              <LeadsPivot allFieldsPivot={allFieldsPivot} />
            </Card> */}
            {/* </Panel> */}
            {/* <Divider orientation='left'>Large Size</Divider> */}
            <Divider orientation="left"></Divider>
            {/* <Collapse
            size='large'
            items={[
              {
                key: '1',
                label: 'This is large size panel header',
                children: <p>{text}</p>
              }
            ]}
          /> */}

            <Panel
              header={
                <div>
                  <strong>LEAD PIPELINE</strong>{" "}
                  <span className="expand-text">
                    (Click here to expand or collapse)
                  </span>
                </div>
              }
              key="1"
              className="custom-panel"
            >
              <Card
                layout="horizontal"
                bordered={true}
                className="criclebox tablespace mb-24"
                title={
                  <>
                    LEAD PIPELINE
                    {/* {updatedOn && (
                      <span style={{ marginLeft: '10px', fontSize: '14px' }}>
                        <br />
                        <strong>Last Updated On</strong> - {updatedOn}
                      </span>
                    )} */}
                  </>
                }
                centered
                style={{ width: "100%", marginTop: "20px" }}
                extra={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "12px",
                    }}
                  >
                    <StyledCSVLink

                      data={dataSource}
                      onClick={() => { }}
                    >
                      Download Report
                    </StyledCSVLink>
                  </div>
                }
              >
                <div className="table-responsive">
                  {loading ? (
                    <div className="spinner-container">
                      {/* <Spin size="large" /> */}
                      <HashLoader color="#5e60ce" />
                    </div>
                  ) : (
                    <StyledTable
                      columns={columns}
                      dataSource={dataSource}
                      pagination={paginationOptions}
                      bordered
                    // rowClassName={(record, index) =>
                    //   index % 2 === 0 ? "table-row-light" : "table-row-dark"
                    // }
                    />
                  )}
                </div>
              </Card>
            </Panel>
            {/* <Divider orientation='left'>Large Size</Divider> */}
            <Divider orientation="left"></Divider>
            <Panel
              header={
                <div>
                  <strong>CONVERTED LEAD</strong>{" "}
                  <span className="expand-text">
                    (Click here to expand or collapse)
                  </span>
                </div>
              }
              key="2"
              className="custom-panel"
            >
              <Card
                layout="horizontal"
                bordered={true}
                className="criclebox tablespace mb-24"
                title={
                  <>
                    CONVERTED LEAD
                    {/* {updatedOn && (
                      <span style={{ marginLeft: '10px', fontSize: '14px' }}>
                        <br />
                        <strong>Last Updated On</strong> - {updatedOn}
                      </span>
                    )} */}
                  </>
                }
                centered
                style={{ width: "100%", marginTop: "20px" }}
                extra={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "12px",
                    }}
                  >
                    <StyledCSVLink

                      data={convertDataSource}
                      onClick={() => { }}
                    >
                      Download Report
                    </StyledCSVLink>
                  </div>
                }
              >
                <div className="table-responsive">
                  {loading ? (
                    <div className="spinner-container">
                      {/* <Spin size="large" /> */}
                      <HashLoader color="#5e60ce" />
                    </div>
                  ) : (
                    <StyledTable
                      columns={ConvertColumns}
                      dataSource={convertDataSource}
                      pagination={paginationOptions}
                      bordered

                    />
                  )}
                </div>
              </Card>
            </Panel>
            {/* <LeadsPivot allFieldsPivot={allFieldsPivot} /> */}
            {/* <Card
            layout='horizontal'
            bordered={true}
            className='criclebox tablespace mb-24'
            title={<>LEAD</>}
            centered
            style={{ width: '100%', marginTop: '20px' }}
          >
            <div className='table-responsive'>
              {loading ? (
                <div className='spinner-container'>
                  <Spin size='large' />
                </div>
              ) : (
                <StyledTable
                  columns={columnsPivot}
                  dataSource={dataSourcePivot}
                  pagination={paginationOptions}
                  bordered
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                  }
                />
              )}
            </div>
          </Card> */}
          </Collapse>
        </div>
      </center>
    </>
  );
};

export default Leads;
