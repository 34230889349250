import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../apiConfig'

export const fetchPDDHoToBranch = createAsyncThunk(
    'fetchPDDHoToBranch',
    async ({ jwtBearer, movement }) => {
        const response = await fetch(`${BASE_URL}pdd/get/pdd/data/ho/to/branch/`, {
            headers: {
                Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
            }
        })
        return response.json()
    }
)

const pddDataHoToBranchSlice = createSlice({
    name: 'pddDataHoToBranchSlice',
    initialState: {
        isLoading: false,
        data: null,
        isError: false
    },
    extraReducers: builder => {
        builder.addCase(fetchPDDHoToBranch.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchPDDHoToBranch.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        })
        builder.addCase(fetchPDDHoToBranch.rejected, (state, action) => {
            console.log('Error', action.payload)
            state.isError = true
        })
    }
})

export default pddDataHoToBranchSlice.reducer
