import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { useAuth } from "../../../context/AuthContext";
import { addPortalUsers } from "../../../redux/slice/addPortalUsersSlice";
import { deactivatePortalUsers } from "../../../redux/slice/deactivatePortalUsersSlice";
import { unlockPortalUsers } from "../../../redux/slice/unlockPortalUser";
import "../../../../assets/styles/buttons.css";
import { fetchAdmin } from "../../../redux/slice/adminSlice";
import { fetchUserDetails } from "../../../redux/slice/getUserDetails";
import { updateUserPassword } from "../../../redux/slice/updateUserPassword";
import { fetchBranch } from "../../../redux/slice/getBranchData";
import { updateUserDetails } from "../../../redux/slice/updateUserDetails";
import { StyledButton } from "../style/styleComp";
import { ArrowRightOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv'
import {
  Spin,
  Table,
  Card,
  Modal,
  Button,
  Input,
  Tabs,
  Upload,
  message,
  Tooltip,
  Radio,
  Form,
  Row,
  Col,
  Alert,
  Typography,
  Select,
  Tag,
  Divider
} from "antd";
import {
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  FileExcelOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as XLSX from "xlsx";

const AddUSer = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [uploadAlert, setUploadAlert] = useState("");
  const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(null);
  const [fileList, setFileList] = useState([]); // To store the uploaded file
  const [userUploadType, setUserUploadType] = useState(null);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [userToBeDeactivated, setUserToBeDeactivated] = useState("");
  const [branchToBeRemoved, setBranchToBeRemoved] = useState([]);
  const [userToBeUnlocked, setUserToBeUnlocked] = useState("");
  const [unlockModalVisible, setUnlockModalVisible] = useState("");
  const [activationModalVisible, setActivationModalVisible] = useState(false);
  const [userToBeActivated, setUserToBeActivated] = useState("");
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [branchMapModalVisible, setBranchMapModalVisible] = useState(false);
  const [ModifyUserDetailsModalVisible, setModifyUserDetailsModalVisible] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [userID, setUserID] = useState("");
  const [userName, setUserName] = useState("");
  const [uploadFailedError, setUploadFailedError] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [policyAlert, setPolicyAlert] = useState(false);
  const [branchMappingData, setBranchMappingData] = useState([]);
  const [branchData, setBranchData] = useState([])
  const [modalPagination, setModalPagination] = useState({
    pageSize: 10,
    current: 1,
    total: branchMappingData.length,
  });
  const [branchToAssign, setBranchToAssign] = useState(null)
  const [selectedBranches, setSelectedBranches] = useState([])
  const [mappedBranches, setMappedBranches] = useState('')
  const [branchChangeUser, setBranchChangeUser] = useState('')
  const [modifyDetailsUser, setModifyDetailsUser] = useState('')
  const [userNameToChange, setUserNameToChange] = useState('')
  const [roleToChange, setRoleToChange] = useState('')
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false)
  const [newUserName, setNewUserName] = useState('')
  const [detailType, setDetailType] = useState('')
  const [roleValue, setRoleValue] = useState('')

  const csvHeaders = [
    { label: 'USER ID', key: 'userid' },
    { label: 'USERNAME', key: 'username' },
    { label: 'BRANCH ID', key: 'branch_id' },
    { label: 'STATUS', key: 'status' },
    { label: 'ROLE', key: 'role' },
    { label: 'LAST LOGIN', key: 'last_login' },
    { label: 'DEACTIVATION DATETIME', key: 'deactivation_datetime' },
    { label: 'UPDATED AT', key: 'updated_at' }
  ];
  // const [prateekKaVariable, setPrateekKaVariable] = useState(false)
  var prateekKaVariable = false;

  const { authState, onLogout } = useAuth();

  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var jwt_payload_group = [];
  var jwt_payload_expiry = [];
  var jwt_payload_userid = ""
  if (jwtBearer) {
    console.log("Token decoded");
    jwt_payload = jwt_decode(jwtBearer);
    jwt_payload_group = jwt_payload.group_id;
    jwt_payload_expiry = jwt_payload.exp;
    jwt_payload_userid = jwt_payload.user_id
    console.log("JWT Group Reports - ", jwt_payload_group);
  }

  // Session Timeout
  const TOKEN_KEY = "my-jwt";
  var currentTime = Math.floor(Date.now() / 1000);

  // Compare current time with token expiry time
  if (jwt_payload_expiry.length != 0) {
    if (currentTime > jwt_payload_expiry) {
      console.log("In token expired condition");
      // Token has expired, perform logout
      AsyncStorage.removeItem(TOKEN_KEY);
      onLogout();
      location.reload();
    }
  }
  // Session Timeout

  useEffect(() => {
    dispatch(fetchAdmin({ jwtBearer }));
  }, []);

  const state = useSelector((state) => state);
  // console.log('State Admin -', state)

  const allFields = state.admin.data?.message || [];
  // console.log('Admin all fields', allFields)

  const keys = state.admin.data
    ? Object.keys(allFields["userid"] ? allFields["userid"] : "")
    : [];

  // Create an array of objects in the desired format
  const objectsArray = keys.map((key) => {
    const obj = {};
    for (const subKey in allFields) {
      obj[subKey] = allFields[subKey][key];
    }
    return obj;
  });
  console.log("CMA Registered users JSON - ", objectsArray);
  // Extract unique roles
  const uniqueRoles = [...new Set(objectsArray.map(item => item.role))];


  useEffect(() => {
    setDataSource(objectsArray);
    // setLastUpdatedDate(updated_date)
    // setLastUpdatedTime(updated_time)
  }, [allFields]);

  const allKeys = objectsArray.reduce((keys, record) => {
    return keys.concat(Object.keys(record));
  }, []);

  const uniqueKeys = [...new Set(allKeys)];

  const columns = [
    ...uniqueKeys.map((key) => ({
      title: <strong>{key.toUpperCase()}</strong>,
      dataIndex: key,
      key: key,
      width: "30%",
      align: "left",
      filters: [...new Set(objectsArray.map((record) => record[key]))].map(
        (value) => ({
          text: value,
          value: value,
        })
      ),
      onFilter: (value, record) => record[key] === value,
      filterSearch: true,
    })),
    {
      title: <strong>UNLOCK ACCOUNT</strong>,
      dataIndex: "unlockAccount",
      key: "unlockAccount",
      width: "20%",
      align: "left",
      render: (text, record) => {
        if (record.wrong_password_attempt_count > 2) {
          return (
            <a
              style={{ color: "dodgerBlue" }}
              onClick={() => handleUnlock(record)}
            >
              Unlock
            </a>
          );
        }
        return null;
      },
    },
    {
      title: <strong>ACTIVATE/DEACTIVATE</strong>,
      dataIndex: "status", // Assuming 'status' is the key for the second column
      key: "status",
      width: "20%",
      align: "left",
      render: (text, record) => {
        if (text === "Active") {
          return (
            <a
              style={{ color: "red" }}
              onClick={() => handleDeactivate(record)}
            >
              Deactivate
            </a>
          );
        } else if (text === "Inactive") {
          return (
            <a
              style={{ color: "dodgerBlue" }}
              onClick={() => handleActivate(record)}
            >
              Activate
            </a>
          );
        } else {
          return null; // Handle other cases if necessary
        }
      },
    },
    {
      title: <strong>CHANGE BRANCH</strong>,
      dataIndex: "changeBranch", // Assuming 'status' is the key for the second column
      key: "changeBranch",
      width: "20%",
      align: "left",
      render: (text, record) => {
        return (
          <a
            style={{ color: "dodgerblue" }}
            onClick={() => handleModifyBranchMap(record)}
          >
            Modify Branch
          </a>
        );
      },
    },
    {
      title: <strong>MODIFY DETAILS</strong>,
      dataIndex: "changeDetails",
      key: "changeDetails",
      width: "20%",
      align: "left",
      render: (text, record) => {
        return (
          <a
            style={{ color: "dodgerblue" }}
            onClick={() => handleModifyUserDetails(record)}
          >
            Modify Details
          </a>
        );
      },
    }
  ];

  const branchMappingColumns = [
    {
      title: 'Branch ID',
      dataIndex: 'branchId',
      key: 'branchId',
    },
    {
      title: <strong>REMOVE BRANCH</strong>,
      dataIndex: "removeBranch",
      key: "removeBranch",
      width: "20%",
      align: "left",
      render: (text, record) => {
        return (
          <a
            style={{ color: "dodgerBlue" }}
            onClick={() => handleDeleteBranch(record)}
          >
            Delete
          </a>
        );
        // return null;
      },
    },
  ]

  // console.log("Admin Datasource - ", dataSource);

  const allowedExtensions = ["xlsx"];

  const handleFileChange = (info) => {
    console.log("In handle file change function");
    console.log("info", info);
    const fileExtension = info.file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      message.error(
        `Invalid file format. Allowed formats: ${allowedExtensions.join(", ")}`
      );
      return;
    }
    setFileList(info.fileList);
  };

  const handleUpload = async () => {
    setButtonLoading(true);
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });

          // Assuming the first sheet is the one you want to convert to JSON
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          console.log("Excel data - ", excelData);
          // Extract the headers (first sub-array)
          const headers = excelData[0];

          // Create an array of objects using headers as keys and data as values
          const formattedData = excelData.slice(1).map((row) => {
            const obj = {};
            headers.forEach((header, index) => {
              obj[header] = row[index].toString();
            });
            return obj;
          });

          console.log("Formatted Upload USer data", formattedData);

          // File size
          const jsonString = JSON.stringify(formattedData);

          console.log("Formatted Upload USer data string", jsonString);

          const fileSizeInBytes = new Blob([jsonString]).size;

          const fileSizeInKB = fileSizeInBytes / 1024;
          const fileSizeInMB = Math.floor(fileSizeInKB / 1024);
          console.log("Size number", fileSizeInMB);

          // Log the file size
          console.log(
            `File size: ${fileSizeInBytes} bytes (${fileSizeInKB} KB, ${fileSizeInMB} MB)`
          );

          const chunkNumber = parseInt(fileSizeInMB + 1);
          // File size

          const chunkSize = Math.ceil(formattedData.length / chunkNumber);
          const chunks = [];
          for (let i = 0; i < formattedData.length; i += chunkSize) {
            chunks.push(formattedData.slice(i, i + chunkSize));
          }

          // Call uploadOpsMIS API for each chunk with different uploadType
          for (let i = 0; i < chunks.length; i++) {
            const response = await dispatch(
              addPortalUsers({
                jwt_token: authState.token,
                requestBody: JSON.stringify(chunks[i]),
              })
            );

            console.log(`File upload response (Chunk ${i + 1}):`, response);
          }

          message.success("File uploaded successfully");

          // After successful file processing, await the dispatch and then execute the rest of the code
          setLoading(false);
          setButtonLoading(false);
          setFileList([]); // Clear the uploaded file list
        } catch (error) {
          console.error("Error:", error);
          message.error("File upload failed");
        }
      };

      reader.readAsArrayBuffer(file);
    }
    setUserUploadType(null);
  };

  const customRequest = ({ file, onSuccess }) => {
    // Prevent the default behavior by calling onSuccess immediately
    onSuccess("ok");
  };

  const handleInfoClick = async () => {
    setInfoModalVisible(true);
  };

  const paginationOptions = {
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
    defaultPageSize: 10, // Initial page size
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  const handleDeactivate = async (record) => {
    prateekKaVariable = true;
    setConfirmationModalVisible(true);
    setUserToBeDeactivated(record.userid);
    setTimeout(() => {
      prateekKaVariable = false;
    }, 1000); // Delay rendering by 500ms
  };

  const handleModifyBranchMap = async (record) => {
    prateekKaVariable = true;
    setBranchMapModalVisible(true)
    setTimeout(() => {
      prateekKaVariable = false;
    }, 1000); // Delay rendering by 500ms    

    setBranchChangeUser(record.userid)

    const branchResponse = await dispatch(fetchUserDetails({ jwtBearer, emp_code: record.userid }));
    const allBranchResponse = await dispatch(fetchBranch({ jwtBearer }));
    const branches = branchResponse.payload?.message.branch;
    setMappedBranches(branchResponse.payload?.message)
    console.log('User assigned branches - ', branchResponse.payload?.message)
    setBranchData(allBranchResponse.payload?.message.btrim)

    if (branches) {
      const branchIds = branches.slice(1, -1).split(',').map(id => id.trim());
      const branchData = branchIds.map(id => ({ key: id, branchId: id }));
      console.log('branch data - ', branchData)
      setBranchMappingData(branchData);
    }
  }

  const handleModifyUserDetails = async (record) => {
    prateekKaVariable = true;
    setModifyUserDetailsModalVisible(true)
    setTimeout(() => {
      prateekKaVariable = false;
    }, 1000); // Delay rendering by 500ms    

    setModifyDetailsUser(record.userid)
    setUserNameToChange(record.username)
    setRoleToChange(record.role)

    // const userDetailsResponse = await dispatch(fetchUserDetails({ jwtBearer, emp_code: record.userid }));
    // const userName = branchResponse.payload?.message.username;
    // setMappedBranches(branchResponse.payload?.message)
    // console.log('User assigned branches - ', allBranchResponse.payload?.message.btrim)
    // setBranchData(allBranchResponse.payload?.message.btrim)

    // if (branches) {
    //   const branchIds = branches.slice(1, -1).split(',').map(id => id.trim());
    //   const branchData = branchIds.map(id => ({ key: id, branchId: id }));
    //   setBranchMappingData(branchData);
    // }
  }

  const handleActivate = async (record) => {
    prateekKaVariable = true;
    setActivationModalVisible(true);
    setUserToBeActivated(record.userid);
    setTimeout(() => {
      prateekKaVariable = false;
    }, 1000); // Delay rendering by 500ms
  };

  const handleDeactivateFinal = async () => {
    console.log("Deactivation log", userToBeDeactivated);
    const response = await dispatch(
      deactivatePortalUsers({
        jwt_token: authState.token,
        type: "Deactivate",
        requestBody: userToBeDeactivated,
      })
    );
    setConfirmationModalVisible(false);
    dispatch(fetchAdmin({ jwtBearer: authState.token }));
  };

  const handleActivateFinal = async () => {
    console.log("Activation log", userToBeActivated);
    const response = await dispatch(
      deactivatePortalUsers({
        jwt_token: authState.token,
        type: "Activate",
        requestBody: userToBeActivated,
      })
    );
    setActivationModalVisible(false);
    dispatch(fetchAdmin({ jwtBearer: authState.token }));
  };

  const handleUnlock = async (record) => {
    prateekKaVariable = true;
    setUnlockModalVisible(true);
    setUserToBeUnlocked(record.userid);
    setTimeout(() => {
      prateekKaVariable = false;
    }, 1000); // Delay rendering by 500ms
  };

  const handleUnlockFinal = async () => {
    // const requestBody = {
    const userid = userToBeUnlocked;
    // };

    // const response = await dispatch(unlockPortalUsers({ jwt_token: authState.token, requestBody }))
    dispatch(unlockPortalUsers({ jwt_token: authState.token, userid }));

    setUnlockModalVisible(false);
    dispatch(fetchAdmin({ jwtBearer: authState.token }));
  };

  const handleModalClose = () => {
    setConfirmationModalVisible(false);
    setActivationModalVisible(false);
    setUserID(undefined);
    setUserName(undefined);
    setNewPassword(undefined);
    form.resetFields();
    form.setFieldsValue({
      userID: undefined,
      userName: undefined,
      newPassword: undefined,
    });
    setPasswordModalVisible(false);
    setUnlockModalVisible(false);
    setBranchMapModalVisible(false);
    setDeleteConfirmationVisible(false);
    setSelectedBranches([])
    setModifyUserDetailsModalVisible(false)
    setNewUserName('')
    setDetailType(null)
    setRoleValue(null)
  };

  const onRowClick = async (record) => {
    console.log("in on row click");
    if (prateekKaVariable == false) {
      setUserID(undefined);
      setUserName(undefined);
      setNewPassword(undefined);
      form.setFieldsValue({
        userID: undefined,
        userName: undefined,
        newPassword: undefined,
      });
      setUserID(record.userid);
      setUserName(record.username);
      setPasswordModalVisible(true);
    }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/;
    if (!strongPasswordRegex.test(password)) {
      setPolicyAlert(false);
    } else {
      setPolicyAlert(true);
    }
    setNewPassword(e.target.value);
  };

  // const handleResetPassword = () => {
  //   try {
  //     const requestBody = {
  //       userid: userID,
  //       new_password: newPassword,
  //       old_password: "None",
  //       category: "admin",
  //     };

  //     dispatch(updateUserPassword({ jwt_token: authState.token, requestBody }));
  //     // Reset the form and mode after submission
  //     form.resetFields();
  //     setPasswordModalVisible(false);
  //     // setUploadSuccess(true)
  //     message.success("Password changed successfully");
  //   } catch (error) {
  //     console.error("Error in change password:", error);
  //     // setUploadFailedError(true)
  //     message.error("Unknown error occurred while changing password");
  //   }
  // };

  const handleResetPassword = async () => {
    try {
      const requestBody = {
        userid: userID,
        new_password: newPassword,
        old_password: "None",
        category: "admin",
      };

      // Dispatch the updateUserPassword action and await the response
      const response = await dispatch(
        updateUserPassword({ jwt_token: authState.token, requestBody })
      ).unwrap();

      // Console the response
      console.log("API Response:", response);
      if (response.code == 200) {
        form.resetFields();
        setPasswordModalVisible(false);
        message.success("Password changed successfully");
      }
      else {
        message.error(response.message)
      }
      // Reset the form and mode after submission

    } catch (error) {
      console.error("Error in change password:", error);
      message.error("Unknown error occurred while changing password");
    }
  };

  // const handleUnlock = (record) => {
  //   // setPrateekKaVariable(true)
  //   prateekKaVariable = true
  //   console.log('Record to unlock - ', record,prateekKaVariable)
  //   setTimeout(() => {
  //     prateekKaVariable = false
  //   }, 1000) // Delay rendering by 500ms

  //   dispatch(
  //     unlockPortalUsers({
  //       jwt_token: authState.token,
  //       type: 'Activate',
  //       requestBody: record.userid
  //     })
  //   )
  //   setActivationModalVisible(false)
  //   dispatch(fetchAdmin({ jwtBearer: authState.token }))

  // }

  const handleSelectChange = (value) => {
    // Add the selected branch to the array if not already present
    if (!selectedBranches.includes(value)) {
      setSelectedBranches([...selectedBranches, value]);
    }
  };

  const handleRemoveBranch = (branch) => {
    // Remove the selected branch from the array
    setSelectedBranches(selectedBranches.filter((item) => item !== branch));
  };

  const handleAssignBranch = () => {

    const requestBody = {
      type: 'Add Branch',
      user_id: branchChangeUser,
      field_to_update: selectedBranches
    };

    dispatch(updateUserDetails({ jwtBearer, requestBody }))
      .then((response) => {
        if (response.payload.code === 200) {
          setBranchMapModalVisible(false)
          setSelectedBranches([])
          message.success('Branch allocated successfully')
        }
        else {
          message.error('Branch allocation failed')
        }
      })
      .catch((error) => {
        message.error('Branch allocation failed',)
        console.error("Error updating reschedule date:", error);
      });
    dispatch(fetchAdmin({ jwtBearer: authState.token }));
  };

  const handleChangeUsername = () => {

    const requestBody = {
      type: 'Change Name',
      user_id: modifyDetailsUser,
      field_to_update: [newUserName]
    };

    dispatch(updateUserDetails({ jwtBearer, requestBody }))
      .then((response) => {
        if (response.payload.code === 200) {
          setModifyUserDetailsModalVisible(false)
          setSelectedBranches([])
          message.success('Username changed successfully')
        }
        else {
          message.error('Username change failed')
        }
      })
      .catch((error) => {
        message.error('Username change failed',)
        console.error("Error updating username:", error);
      });
    dispatch(fetchAdmin({ jwtBearer: authState.token }));
    handleModalClose()
  };

  const handleDeleteBranch = async (record) => {
    prateekKaVariable = true;
    setDeleteConfirmationVisible(true);
    // setBranchToBeRemoved(record.branchId);
    setBranchToBeRemoved([...branchToBeRemoved, record.branchId]);
    setTimeout(() => {
      prateekKaVariable = false;
    }, 1000); // Delay rendering by 500ms
  };

  const handleDeleteBranchFinal = () => {

    console.log('Selected Branches - ', branchToBeRemoved)

    const requestBody = {
      type: 'Remove Branch',
      user_id: branchChangeUser,
      field_to_update: branchToBeRemoved
    };

    dispatch(updateUserDetails({ jwtBearer, requestBody }))
      .then((response) => {
        // console.log('Reassign Branch Response - ', response)
        if (response.payload.code === 200) {
          setBranchMapModalVisible(false)
          setSelectedBranches([])
          message.success('Branch removed successfully')
          setDeleteConfirmationVisible(false)
          setSelectedBranches([])
        }
        else {
          message.error('Failed to remove branch')
        }
      })
      .catch((error) => {
        console.error("Failed to remove branch - ", error);
      });
    dispatch(fetchAdmin({ jwtBearer: authState.token }));

  };

  const handleModalCloseDelete = () => {
    setDeleteConfirmationVisible(false)
    setSelectedBranches([])
  }

  const handleDetailTypeChange = (e) => {
    setDetailType(e)
  }

  const handleChangeRole = () => {

    const requestBody = {
      type: 'Change Role',
      user_id: modifyDetailsUser,
      field_to_update: [roleValue]
    };

    dispatch(updateUserDetails({ jwtBearer, requestBody }))
      .then((response) => {
        if (response.payload.code === 200) {
          setModifyUserDetailsModalVisible(false)
          setSelectedBranches([])
          message.success('Role changed successfully')
        }
        else {
          message.error('Role change failed')
        }
      })
      .catch((error) => {
        message.error('Role change failed',)
        console.error("Error updating username:", error);
      });
    dispatch(fetchAdmin({ jwtBearer: authState.token }));
    handleModalClose()
  };

  const downloadExcel = (dataSource, csvHeaders) => {
    const formattedData = dataSource.map(row => {
      const newRow = {};
      csvHeaders.forEach(header => {
        newRow[header.label] = row[header.key];
      });
      return newRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'portal_users.xlsx');
  };


  return (
    <>
      <center>
        <div>
          {/* {jwt_payload_group == '0' ||
          jwt_payload_group == '1' ||
          jwt_payload_group == '2' ||
          jwt_payload_group == '3' ||
          jwt_payload_group == '4' ? null : ( */}
          <Card
            style={{
              width: "60%",
              marginTop: "20px",
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", // Add the box-shadow property
              borderRadius: "5px", // Add rounded corners for a more aesthetic look
            }}
            bordered={true}
          >
            <center>
              <div>
                <p>
                  <strong>
                    <FileExcelOutlined /> UPLOAD USER DATA
                  </strong>
                </p>
                <Upload
                  customRequest={customRequest}
                  name="avatar" // Set the name attribute to "avatar"
                  fileList={fileList}
                  listType="picture-card" // Set the listType to "picture-card"
                  onChange={handleFileChange}
                  maxCount={1}
                >
                  {fileList.length === 0 && (
                    <div>
                      <UploadOutlined />
                      <div style={{ marginTop: 8 }}>Select File</div>
                    </div>
                  )}
                </Upload>

                <Button
                  className="regular-button"
                  style={{
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    display: "inline-flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    marginTop: "20px",
                  }}
                  onClick={handleUpload}
                  loading={buttonLoading}
                  disabled={fileList.length === 0}
                >
                  Upload
                </Button>
                <div
                  style={{
                    textAlign: "justify",
                    marginLeft: "20px",
                  }}
                >
                  <p>
                    <strong>Instructions to be followed -</strong>
                  </p>
                  <p>1. The extension of the file should be 'xlsx'.</p>
                  <p>
                    2. Name of one of the column of the excel file should
                    'Branch Name' and it should have data is this format -
                    '10001-Jobner'.
                  </p>
                  <p>
                    3. The excel sheet should not be having any formula in any
                    of the cells and cells should only have values in it.
                  </p>
                </div>

                {uploadAlert && (
                  <div style={{ marginTop: "10px" }}>{uploadAlert}</div>
                )}
              </div>
            </center>
          </Card>
          <Card style={{ width: "100%", marginTop: "20px" }}
            extra={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '12px'
                }}
              >
                {/* {jwt_payload_group == '2' || jwt_payload_group == '3' ? null : ( */}
                {/* <CSVLink
                  className='regular-button'
                  style={{
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    textDecoration: 'none',
                    fontWeight: 'bold'
                  }}
                  data={dataSource}
                  headers={csvHeaders}
                  onClick={() => { }}
                >
                  Download Report
                </CSVLink> */}
                <StyledButton
                  type="primary"
                  onClick={() => downloadExcel(dataSource, csvHeaders)}
                  htmlType="submit"
                // icon={<ArrowRightOutlined />}
                >
                  Download Report
                </StyledButton>
                {/* )} */}
              </div>
            }>
            <div
              style={{
                overflowX: "auto",
                maxWidth: "100%", // Adjust as needed for your layout
              }}
            >
              <Table
                columns={columns}
                dataSource={dataSource}
                pagination={paginationOptions}
                bordered
                rowClassName={(record, index) =>
                  index % 2 === 0
                    ? "table-row-light table-row-clickable"
                    : "table-row-dark table-row-clickable"
                }
                onRow={(record) => ({
                  onClick: () => onRowClick(record),
                })}
              />
            </div>
            {/* <Table
              columns={columns}
              dataSource={dataSource}
              pagination={paginationOptions}
              bordered
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? 'table-row-light table-row-clickable'
                  : 'table-row-dark table-row-clickable'
              }
              onRow={(record) => ({
                onClick: (event) => {
                  if (
                    event.target.tagName !== 'BUTTON' && // Check if the clicked element is not a button
                    event.target.tagName !== 'A' && // Check if the clicked element is not an anchor
                    !event.target.innerText.includes('Unlock Account') // Check if the clicked element doesn't contain 'Unlock Account'
                  ) {
                    onRowClick(record);
                  }
                },
              })}
            /> */}
          </Card>
          <Modal
            visible={confirmationModalVisible}
            onCancel={handleModalClose}
            footer={[
              <Button key="cancel" onClick={handleModalClose}>
                Cancel
              </Button>,
              <Button
                key="deactivate"
                type="primary"
                onClick={handleDeactivateFinal}
              >
                Deactivate
              </Button>,
            ]}
          >
            <p>Are you sure you want to deactivate the user?</p>
          </Modal>
          <Modal
            visible={deleteConfirmationVisible}
            onCancel={handleModalClose}
            footer={[
              <Button key="cancel" onClick={handleModalCloseDelete}>
                Cancel
              </Button>,
              <Button
                key="deactivate"
                type="primary"
                onClick={handleDeleteBranchFinal}
              >
                Remove
              </Button>,
            ]}
          >
            <p>Are you sure you want to remove this branch?</p>
          </Modal>
          <Modal
            visible={activationModalVisible}
            onCancel={handleModalClose}
            footer={[
              <Button key="cancel" onClick={handleModalClose}>
                Cancel
              </Button>,
              <Button
                key="deactivate"
                type="primary"
                onClick={handleActivateFinal}
              >
                Activate
              </Button>,
            ]}
          >
            <p>Are you sure you want to activate the user?</p>
          </Modal>
          <Modal
            title={<strong>RESET PASSWORD</strong>}
            visible={passwordModalVisible}
            onCancel={handleModalClose}
            footer={null}
            width={1000}
            style={{ textAlign: "center" }}
          >
            <Form
              layout="vertical" // Set the form layout to vertical
              style={{ marginTop: "20px" }}
            >
              <Form.Item label={<strong>USER ID :</strong>}>
                <Input value={userID} style={{ color: "black" }} disabled />
              </Form.Item>
              <Form.Item label={<strong>USER NAME :</strong>}>
                <Input value={userName} style={{ color: "black" }} disabled />
              </Form.Item>
              <Form.Item
                label={<strong>ENTER THE NEW PASSWORD :</strong>}
              // name="newPassword"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter a new password.",
              //   },
              //   {
              //     pattern:
              //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/,
              //     message:
              //       "Password must contain at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character.",
              //   },
              // ]}
              >
                <>
                  <Input.Password
                    // type="password"
                    value={newPassword}
                    style={{ color: "#404040" }}
                    onChange={handlePasswordChange}
                  />
                </>
              </Form.Item>
              {newPassword && policyAlert === true && (
                // <div
                // style={{
                //     alignItems: 'left'
                //   }}>
                <Button
                  key="deactivate"
                  type="primary"
                  onClick={handleResetPassword}
                >
                  Reset Password
                </Button>
                // </div>
              )}
            </Form>
            {policyAlert === true ? null : (
              <Alert
                style={{
                  width: "60%",
                  margin: "auto",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                // message={<strong>WELCOME TO THE ZIEL PORTAL!</strong>}
                description="Password must contain at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character(@$!%*?&). Length of the password should be 8 to 30 characters."
                type="error"
              />
            )}
          </Modal>
          <Modal
            visible={unlockModalVisible}
            onCancel={handleModalClose}
            footer={[
              <Button key="cancel" onClick={handleModalClose}>
                Cancel
              </Button>,
              <Button key="unlock" type="primary" onClick={handleUnlockFinal}>
                Unlock
              </Button>,
            ]}
          >
            <p>Are you sure you want to unlock the user?</p>
          </Modal>
          <Modal
            visible={branchMapModalVisible}
            onCancel={handleModalClose}
            width={1300}
            footer={null}
          >
            <div style={{ marginLeft: 15, overflowY: "auto", height: "100%" }}>
              <Row style={{ width: "100%", height: "100%" }}>
                <Col
                  flex={10}
                  style={{ marginLeft: 15, overflowY: "auto", height: "100%" }}
                >
                  <div
                    style={{
                      marginLeft: 15,
                      overflowY: "auto",
                      height: "100%",
                    }}
                  >
                    {/* {branchMappingData.length === 1 && branchMappingData[0].branchId === "" ? ( */}
                    <Table
                      columns={branchMappingColumns}
                      dataSource={branchMappingData}
                      pagination={paginationOptions}
                      bordered
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                      }
                      title={() => (
                        <Typography.Title level={4} style={{ textAlign: 'center' }}>
                          BRANCHES ASSIGNED TO SELECTED USER - {branchChangeUser}
                        </Typography.Title>
                      )}
                    />
                    {/* ) : (
                      <Typography.Paragraph style={{ textAlign: 'center' }}>
                        No data available
                      </Typography.Paragraph>
                    )} */}
                    <Form style={{ marginLeft: '10px', marginRight: '20px', marginTop: '10px', marginBottom: '10px' }} form={form} layout="vertical">
                      <Form.Item label={<p style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }}><strong>Select branch:</strong></p>}>
                        <Select
                          showSearch
                          style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}
                          placeholder={<p style={{ fontSize: '18px', fontWeight: '600', borderRadius: '10px' }}>Select a branch</p>}
                          optionFilterProp="children"
                          // onChange={(value) => setBranchToAssign(value)}
                          onChange={handleSelectChange}
                          filterOption={(input, option) =>
                            option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {Object.entries(branchData).map(([key, branchName]) => (
                            <Option key={key} value={branchName}>
                              <p style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}>{branchName}</p>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Form>

                    <div style={{ marginTop: '20px' }}>
                      {Array.isArray(selectedBranches) && selectedBranches.map((branch) => (
                        <Tag
                          key={branch}
                          closable
                          onClose={() => handleRemoveBranch(branch)}
                          style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginBottom: '10px' }}
                        >
                          {branch}
                        </Tag>
                      ))}
                    </div>

                    <div style={{ display: "flex", justifyContent: "right" }}>
                      {/* {customerAvailability === 'Available' && customerFeedbackValue && customerBureauFeedbackValue && leadDecisionValue && ( */}
                      {selectedBranches.length > 0 && (
                        <StyledButton
                          type="primary"
                          onClick={handleAssignBranch}
                          htmlType="submit"
                          icon={<ArrowRightOutlined />}
                          style={{ marginRight: "8px" }}
                        >
                          Assign Branch
                        </StyledButton>
                      )}
                    </div>

                  </div>
                </Col>
              </Row>
            </div>

          </Modal>

          <Modal
            visible={ModifyUserDetailsModalVisible}
            onCancel={handleModalClose}
            width={1300}
            footer={null}
          >
            <div style={{ marginLeft: 15, overflowY: "auto", height: "100%", marginTop: '20px' }}>
              <Row style={{ width: "100%", height: "100%" }}>
                <Col
                  flex={10}
                  style={{ marginLeft: 15, overflowY: "auto", height: "100%" }}
                >
                  <div
                    style={{
                      marginLeft: 15,
                      overflowY: "auto",
                      height: "100%",
                    }}
                  >
                    <Form style={{ marginLeft: '10px', marginRight: '20px' }} form={form} layout="vertical">
                      <p className="dispoPara"><strong>User Details</strong></p>
                      <Divider style={{ borderColor: 'lightgrey' }} />
                      <Form.Item>
                        <Radio.Group
                          id="DetailChangeType"
                          onChange={(e) => {
                            handleDetailTypeChange(e.target.value);
                          }}
                          value={detailType}
                        >
                          <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="Username">Change Username</Radio>
                          <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="Role">Change Role</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Divider />
                      {detailType == 'Username' && (
                        <>
                          <Form.Item label={<strong style={{ fontSize: '20px', fontWeight: '700', color: 'grey' }}>Current Username</strong>}>
                            <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={userNameToChange} disabled />
                          </Form.Item>
                          <Form.Item label={<strong style={{ fontSize: '20px', fontWeight: '700', color: 'grey' }}>New Username</strong>}>
                            <Input
                              style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}
                              value={newUserName}
                              onChange={(e) => setNewUserName(e.target.value)}
                            // onChange={(e) => setNewUserName([...newUserName, e.target.value])}
                            />
                          </Form.Item>
                          {newUserName && (
                            <StyledButton
                              type="primary"
                              onClick={handleChangeUsername}
                              htmlType="submit"
                              icon={<ArrowRightOutlined />}
                              style={{ marginRight: "8px" }}
                            >
                              Change Username
                            </StyledButton>
                          )}
                        </>
                      )}
                      {detailType == 'Role' && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Form style={{ marginLeft: '10px', marginRight: '20px' }} form={form} layout="vertical">
                            <Form.Item label={<strong style={{ fontSize: '20px', fontWeight: '700', color: 'grey' }}>Current Role</strong>}>
                              <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={roleToChange} disabled />
                            </Form.Item>
                            <p ><strong>Select New Role</strong></p>
                            <Select
                              style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1, width: '300px' }}
                              value={roleValue}
                              onChange={(value) => setRoleValue(value)}
                              placeholder='Select Role'
                            >
                              {/* {uniqueRoles.map((role, index) => (
                                <Option key={index} value={role}>{role}</Option>
                              ))} */}
                              {uniqueRoles
                                .filter(role => role !== roleToChange) // Exclude the current role
                                .map((role, index) => (
                                  <Option key={index} value={role}>
                                    {role}
                                  </Option>
                                ))}
                            </Select>
                            {roleValue && (
                              <StyledButton
                                type="primary"
                                onClick={handleChangeRole}
                                htmlType="submit"
                                icon={<ArrowRightOutlined />}
                                style={{ marginRight: "8px" }}
                              >
                                Change Role
                              </StyledButton>
                            )}
                          </Form>
                        </div>
                      )}
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>

          </Modal>

          {/* )} */}
        </div>
      </center>
    </>
  );
};

export default AddUSer;
