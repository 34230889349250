import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../apiConfig';

// Define the thunk to fetch operations data
export const fetchOperationsData = createAsyncThunk(
  'operationsData/fetchOperationsData',
  async ({ jwtBearer }, thunkAPI) => {
    try {
      const response = await fetch(`${BASE_URL}pipeline/operations/data`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${jwtBearer}`,
          'Content-Type': 'application/json'
        }
      });
      
      // Check if the response is ok
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} ${errorText}`);
      }
      
      // Parse the JSON response
      const data = await response.json();
      
      // Return the actual data part of the response
      return data['operations data'];
    } catch (error) {
      console.error('Fetch error:', error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Define the slice for operations data
const operationsSlice = createSlice({
  name: 'operationsData',
  initialState: {
    isLoading: false,
    postData: [],
    preData: [],
    availableData: [],
    isError: false,
    error: null
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOperationsData.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchOperationsData.fulfilled, (state, action) => {
        state.isLoading = false;
        const { post_data, pre_data, available_data } = action.payload;
        state.postData = post_data || [];
        state.preData = pre_data || [];
        state.availableData = available_data || [];
        state.isError = false;
      })
      .addCase(fetchOperationsData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export default operationsSlice.reducer;
