import React, { useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { Card, Form, Input, Button, Spin } from "antd";
import { UserOutlined, LockOutlined, WindowsOutlined } from "@ant-design/icons";
import { useAuth } from "../context/AuthContext";
import axios from "axios";
import logo_rounded from "../../assets/logo_rounded.png";
import * as WebBrowser from "expo-web-browser";
import { resendOTP } from "../redux/slice/resendOTP";
import { useDispatch } from 'react-redux'

import {
  exchangeCodeAsync,
  makeRedirectUri,
  useAuthRequest,
  useAutoDiscovery,
} from "expo-auth-session";
import { SafeAreaView } from "react-native";
import Microsoft from "../../assets/Microsoft.png";

const customData = require("../../package.json");

WebBrowser.maybeCompleteAuthSession();

const isIncognitoMode = () => {
  return new Promise((resolve) => {
    const fs = window.RequestFileSystem || window.webkitRequestFileSystem;
    if (!fs) {
      resolve(false);
    } else {
      fs(
        window.TEMPORARY,
        100,
        () => {
          resolve(false);
        },
        () => {
          console.log("In incognito mode");
          resolve(true);
        }
      );
    }
  });
};

const Login = () => {
  const dispatch = useDispatch() // Get the dispatch function
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const { onLogin, onVerifyOtp } = useAuth();

  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [otpError, setOtpError] = useState('');
  const [formError, setFormError] = useState("");
  const [resendingOtp, setResendingOtp] = useState(false);


  const login = async () => {
    setLoading(true);

    if (!navigator.geolocation) {
      console.log("Geolocation not supported");
      setLoading(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        console.log("Latitude:", latitude);
        console.log("Longitude:", longitude);

        // doLogin();
        if (otpSent) {
          console.log('In OTP sent')
          verifyOtp();
        } else {
          doLogin();
        }
      },
      (error) => {
        console.log("Geolocation error:", error);
        alert("Please provide location access to login");
        setLoading(false);
      }
    );
  };

  const doLogin = async () => {
    const isIncognito = await isIncognitoMode();
    console.log("Incognito", isIncognito);

    if (!username && !password) {
      setUsernameError("Please input your Username!");
      setPasswordError("Please input your Password!");
      setLoading(false);
      return;
    } else if (!username) {
      setUsernameError("Please input your Username!");
      setLoading(false);
      return;
    } else if (!password) {
      setPasswordError("Please input your Password!");
      setLoading(false);
      return;
    } else {
      setUsernameError("");
      setPasswordError("");
    }

    console.log("In login func");
    const result = await onLogin!(username, password);
    if (result && result.error) {
      setFormError(result.msg);
    } else if (result && result.otp_required) {
      setOtpSent(true);
    } else {
      setFormError("");
    }
    setLoading(false);
  };

  const verifyOtp = async () => {
    if (!otp) {
      setOtpError('Please input the OTP!');
      setLoading(false);
      return;
    }

    const result = await onVerifyOtp!(username, otp);
    console.log('Login verify otp - ', result);
    if (result.error.message === 'Your account is locked as you have entered wrong otp multiple times. Please contact IT support team') {
      setLoading(false);
      setOtp('');
      setOtpSent(false)
    }
    else if (result.error.message === 'Your OTP has expired.') {
      console.log('In OTP expired cond');
      setOtpError('Your OTP has expired. Please resend OTP.');
      setLoading(false);
      setOtp('');
    } else if (result && result.error) {
      console.log('verify error - ', result);
      if (result.error.message === 'Your OTP has expired.') {
        setOtpError('Your OTP has expired. Please resend OTP.');
      }
      else {
        setOtpError('Invalid OTP');
      }
      setLoading(false);
      setOtp('');
    } else {
      setFormError('');
      setOtpError('');
      // Proceed to the next step or redirect to the dashboard
    }
    setLoading(false);
  };


  const resendOtp = async () => {
    setResendingOtp(true);
    try {
      console.log('Resend username - ', username)
      const requestBody = {
        username: username
      }
      // await axios.post('http://localhost:8000/login/resend/otp/', { username: username });
      const result = await dispatch(resendOTP({ requestBody }));
      console.log('Resend Response - ', result)
      setOtpError('');
      alert('OTP resent successfully');
    } catch (error) {
      setOtpError('Failed to resend OTP. Please try again.');
      console.log('Resend failed - ', error)
    } finally {
      setResendingOtp(false);
    }
  };

  // AZURE
  // Endpoint
  const discovery = useAutoDiscovery(
    "https://login.microsoftonline.com/ZIELFIN.onmicrosoft.com/v2.0"
  );
  const redirectUri = makeRedirectUri({
    scheme: undefined,
    path: "",
  });
  const clientId = "4002d5ff-33d1-4574-9356-6766c7c673fa";

  // We store the JWT in here
  const [token, setToken] = React.useState<string | null>(null);

  // Request
  const [request, , promptAsync] = useAuthRequest(
    {
      clientId,
      // scopes: ["openid", "profile", "email", "offline_access", "user.read"],
      scopes: ["openid", "profile", "email", "user.read"],
      redirectUri,
    },
    discovery
  );
  // AZURE

  return (
    <div
      // className="login-page"
      className="gradient-background"
    // style={{
    //   // background: linear-gradient(to bottom, #fff, #f2f2f2),
    //   background: linear-gradient(45deg, #ff512f, #dd2476); /* Gradient background */
    //   minHeight: "100vh",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    // }}
    >
      {/* <img
        src={logo_rounded}
        style={{
          height: 48,
          marginTop: "40px",
        }}
      /> */}
      <Card
        // layout='horizontal'
        bordered={true}
        // className='criclebox tablespace mb-24'
        // centered
        // style={{ width: '25%' }}
        style={{
          width: "25%",
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", // Add the box-shadow property
          borderRadius: "20px",
        }}
      >
        <div className="form-container">
          <View style={styles.formContainer}>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
            >
              <Form.Item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={logo_rounded}
                  style={{
                    height: 48,
                    marginTop: "40px",
                  }}
                />
                <h1 className="form-title">Welcome</h1>
              </Form.Item>

              {!otpSent && (
                <>
                  <Form.Item
                    name="username"
                    validateStatus={usernameError ? "error" : ""}
                    help={usernameError}
                  >
                    <Text>Employee ID</Text>
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Enter Employee ID"
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                        setUsernameError("");
                      }}
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    validateStatus={passwordError ? "error" : ""}
                    help={passwordError}
                  >
                    <Text>Password</Text>
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError("");
                      }}
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
                      }}
                    />
                  </Form.Item>
                </>
              )}

              {formError && (
                <Form.Item>
                  <p className="error-text">{formError}</p>
                </Form.Item>
              )}

              {otpSent && (
                <>
                  <Form.Item
                    name="otp"
                    validateStatus={otpError ? 'error' : ''}
                    help={otpError}
                  >
                    <Text>OTP</Text>
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => {
                        setOtp(e.target.value);
                        setOtpError('');
                      }}
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.2)',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
                      }}
                    />
                  </Form.Item>

                  {otpError === 'Your OTP has expired. Please resend OTP.' && (
                    <Form.Item>
                      <Button
                        type="default"
                        onClick={resendOtp}
                        loading={resendingOtp}
                      >
                        Resend OTP
                      </Button>
                    </Form.Item>
                  )}
                </>
              )}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    fontSize: "18px",
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
                  }}
                  className="login-form-button"
                  onClick={login}
                  loading={loading}
                >
                  {/* Continue */}
                  {otpSent ? 'Verify OTP' : 'Continue'}
                  {/* {loading ? <Spin /> : 'Continue'} */}
                </Button>
              </Form.Item>
              {/* <Text
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "15px",
                  fontSize: "15px",
                }}
              >
                or
              </Text> */}
              {/* <Form.Item>
                <Button
                  type=""
                  style={{
                    width: "100%",
                    fontSize: "18px",
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
                  }}
                  className="login-form-button"
                  onClick={() => {
                    promptAsync().then((codeResponse) => {
                      if (
                        request &&
                        codeResponse?.type === "success" &&
                        discovery
                      ) {
                        exchangeCodeAsync(
                          {
                            clientId,
                            code: codeResponse.params.code,
                            extraParams: request.codeVerifier
                              ? { code_verifier: request.codeVerifier }
                              : undefined,
                            redirectUri,
                          },
                          discovery
                        ).then((res) => {
                          setToken(res.accessToken);
                          console.log("Azure Token - ", res.accessToken);
                          console.log("Azure Token - ", res.tokenType);
                          console.log("Azure Token - ", res.expiresIn);
                          console.log("Azure Token - ", res.user_det);
                          console.log("Azure ID Token - ", res.idToken);
                        });
                      }
                    });
                  }}
                // loading={loading}
                >
                  <img
                    src={Microsoft}
                    alt="Microsoft"
                    style={{
                      height: "1em",
                      marginRight: "15px",
                    }}
                  />
                  Sign in with Microsoft
                </Button>
              </Form.Item> */}
            </Form>
            <p>Version: {customData.version}</p>
          </View>
        </div>
      </Card>
    </div>
  );
};

const styles = StyleSheet.create({
  formContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Login;
