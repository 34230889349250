import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCreditReport } from '../../../redux/slice/creditReportSlice'
import { downloadCreditReport } from '../../../redux/slice/downloadCreditSlice'
import { detailedStageReport } from '../../../redux/slice/stageReportSlice'
import { useAuth } from '../../../context/AuthContext'
import jwt_decode from 'jwt-decode'
// import { CSVLink } from 'react-csv'
import { Spin, Table, Card, Modal, Button, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { setCreditUpdatedOn } from '../../../redux/slice/updatedOnCreditSlice'
import AsyncStorage from '@react-native-async-storage/async-storage'
import '../../../../assets/styles/buttons.css'
import { StyledTable, StyledCSVLink, StyledButton } from '../components/styleComp'
import { HashLoader } from 'react-spinners'
import { utc } from 'moment'

const { Search } = Input

function CreditPipeline(props) {
  const [dataSource, setDataSource] = useState([])
  const [stageDataSource, setStageDataSource] = useState([])
  const [casesTotal, setCasesTotal] = useState()
  const [selectedRowData, setSelectedRowData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [detailedLoading, setDetailedLoading] = useState(true)
  const [selectedStage, setSelectedStage] = useState('')

  const dispatch = useDispatch()

  // const handleRowClick = record => {
  //   setIsModalVisible(true)
  //   const stage = record.stage // Get the stage from the clicked record

  //   dispatch(detailedStageReport({ stage })).then(() => {
  //     setLoading(false)
  //   })
  //   const state = useSelector(state => state)
  //   console.log('Stage Data - ', state)
  // }

  // const getColumnSearchProps = dataIndex => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters
  //   }) => (
  //     <div style={{ padding: 8 }}>
  //       {/* <Search */}
  //       <Input
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={e =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm)}
  //         style={{ marginBottom: 8, display: 'block' }}
  //       />
  //       <Button
  //         type='primary'
  //         onClick={() => handleSearch(selectedKeys, confirm)}
  //         // onChange={e => setSelectedKeys([])}
  //         // onClick={() => handleSearch([], confirm)}
  //         icon={<SearchOutlined />}
  //         size='small'
  //         style={{ width: 90, marginRight: 8, height: 40 }}
  //       >
  //         Search
  //       </Button>
  //       <Button
  //         onClick={() => handleReset(clearFilters)}
  //         // onClick={() => handleSearch([], confirm)}
  //         size='small'
  //         style={{ width: 90, height: 40 }}
  //       >
  //         Clear
  //       </Button>
  //     </div>
  //   ),
  //   filterIcon: filtered => (
  //     <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  //   ),
  //   onFilter: (value, record) =>
  //     // record.APP_ID.toLowerCase().includes(value.toLowerCase())
  //     record[data].toLowerCase().includes(value.toLowerCase())
  // })

  const uniqueAppIDs = [...new Set(stageDataSource.map(item => item.APP_ID))]
  // const uniqueBranchIDs = [
  //   ...new Set(stageDataSource.map(item => item.BRANCH_ID))
  // ]
  // const uniqueBranchName = [
  //   ...new Set(stageDataSource.map(item => item.BRANCH_NAME))
  // ]
  const uniqueBranchCode = [...new Set(stageDataSource.map(item => item.BR_CD))]
  uniqueBranchCode.sort((a, b) => {
    const codeA = parseInt(a.split(' - ')[0])
    const codeB = parseInt(b.split(' - ')[0])
    return codeA - codeB
  })
  const uniqueZone = [...new Set(stageDataSource.map(item => item.ZONE))]
  const uniqueSupervisor = [
    ...new Set(stageDataSource.map(item => item.supervisor_name))
  ]
  const uniqueBACode = [...new Set(stageDataSource.map(item => item.BA_CODE))]
  const uniqueBAName = [...new Set(stageDataSource.map(item => item.BA_NAME))]
  const uniqueType = [...new Set(stageDataSource.map(item => item.TYPE))]
  const uniqueRepeatAppID = [...new Set(stageDataSource.map(item => item.REPEAT_APP_ID))]
  const uniqueAppName = [
    ...new Set(stageDataSource.map(item => item.APPLICANT_NAME))
  ]
  const uniqueAppMobile = [
    ...new Set(stageDataSource.map(item => item.APPLICANT_MOBILE))
  ]
  const uniqueProduct = [...new Set(stageDataSource.map(item => item.PRODUCT))]
  const uniqueBusType = [...new Set(stageDataSource.map(item => item.BUSINESS_TYPE))]
  const uniqueBusType1 = [...new Set(stageDataSource.map(item => item.BUSINESS_TYPE_1))]
  const uniquePipeStatus = [...new Set(stageDataSource.map(item => item.PIPELINE_STATUS))]
  const uniqueINSEntity = [...new Set(stageDataSource.map(item => item.INS_ENTITY))]
  const uniqueUdyamAadhar = [...new Set(stageDataSource.map(item => item.UDYAM_AADHAR))]
  const uniqueCurrStatus = [
    ...new Set(stageDataSource.map(item => item.CURR_STATUS))
  ]
  const uniqueAssignee = [
    ...new Set(stageDataSource.map(item => item.ASSIGNEE))
  ]

  const detailedColumns = [
    {
      title: <strong style={{ fontSize: "18px" }}>Application ID</strong>,
      dataIndex: 'APP_ID',
      key: 'APP_ID',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueAppIDs.map(APP_ID => ({
        text: APP_ID,
        value: APP_ID
      })),
      onFilter: (value, record) => record.APP_ID === value,
      filterSearch: true
    },
    // {
    //   title: <strong style={{ fontSize: "18px" }}>Branch ID</strong>,
    //   dataIndex: 'BRANCH_ID',
    //   key: 'BRANCH_ID',
    //   width: '30%',
    //   align: 'left',
    //   filters: uniqueBranchIDs.map(BRANCH_ID => ({
    //     text: BRANCH_ID,
    //     value: BRANCH_ID
    //   })),
    //   onFilter: (value, record) => record.BRANCH_ID === value,
    //   filterSearch: true
    // },
    // {
    //   title: <strong style={{ fontSize: "18px" }}>Branch Name</strong>,
    //   dataIndex: 'BRANCH_NAME',
    //   key: 'BRANCH_NAME',
    //   width: '30%',
    //   align: 'left',
    //   filters: uniqueBranchName.map(BRANCH_NAME => ({
    //     text: BRANCH_NAME,
    //     value: BRANCH_NAME
    //   })),
    //   onFilter: (value, record) => record.BRANCH_NAME === value,
    //   filterSearch: true
    // },
    {
      title: <strong style={{ fontSize: "18px" }}>Zone</strong>,
      dataIndex: 'ZONE',
      key: 'ZONE',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueZone.map(ZONE => ({
        text: ZONE,
        value: ZONE
      })),
      onFilter: (value, record) => record.ZONE === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Supervisor</strong>,
      dataIndex: 'supervisor_name',
      key: 'supervisor_name',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueSupervisor.map(supervisor_name => ({
        text: supervisor_name,
        value: supervisor_name
      })),
      onFilter: (value, record) => record.supervisor_name === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Branch</strong>,
      dataIndex: 'BR_CD',
      key: 'BR_CD',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueBranchCode.map(BR_CD => ({
        text: BR_CD,
        value: BR_CD
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>BA Code</strong>,
      dataIndex: 'BA_CODE',
      key: 'BA_CODE',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueBACode.map(BA_CODE => ({
        text: BA_CODE,
        value: BA_CODE
      })),
      onFilter: (value, record) => record.BA_CODE === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>BA Name</strong>,
      dataIndex: 'BA_NAME',
      key: 'BA_NAME',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueBAName.map(BA_NAME => ({
        text: BA_NAME,
        value: BA_NAME
      })),
      onFilter: (value, record) => record.BA_NAME === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Loan Type</strong>,
      dataIndex: 'TYPE',
      key: 'TYPE',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueType.map(TYPE => ({
        text: TYPE,
        value: TYPE
      })),
      onFilter: (value, record) => record.TYPE === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Repeat Application ID</strong>,
      dataIndex: 'REPEAT_APP_ID',
      key: 'REPEAT_APP_ID',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueRepeatAppID.map(REPEAT_APP_ID => ({
        text: REPEAT_APP_ID,
        value: REPEAT_APP_ID
      })),
      onFilter: (value, record) => record.REPEAT_APP_ID === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Name</strong>,
      dataIndex: 'APPLICANT_NAME',
      key: 'APPLICANT_NAME',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueAppName.map(APPLICANT_NAME => ({
        text: APPLICANT_NAME,
        value: APPLICANT_NAME
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Mobile</strong>,
      dataIndex: 'APPLICANT_MOBILE',
      key: 'APPLICANT_MOBILE',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueAppMobile.map(APPLICANT_MOBILE => ({
        text: APPLICANT_MOBILE,
        value: APPLICANT_MOBILE
      })),
      onFilter: (value, record) => record.APPLICANT_MOBILE === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Product</strong>,
      dataIndex: 'PRODUCT',
      key: 'PRODUCT',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueProduct.map(PRODUCT => ({
        text: PRODUCT,
        value: PRODUCT
      })),
      onFilter: (value, record) => record.PRODUCT === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type</strong>,
      dataIndex: 'BUSINESS_TYPE',
      key: 'BUSINESS_TYPE',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueBusType.map(BUSINESS_TYPE => ({
        text: BUSINESS_TYPE,
        value: BUSINESS_TYPE
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type 1</strong>,
      dataIndex: 'BUSINESS_TYPE_1',
      key: 'BUSINESS_TYPE_1',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueBusType1.map(BUSINESS_TYPE_1 => ({
        text: BUSINESS_TYPE_1,
        value: BUSINESS_TYPE_1
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE_1 === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pipeline Status</strong>,
      dataIndex: 'PIPELINE_STATUS',
      key: 'PIPELINE_STATUS',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniquePipeStatus.map(PIPELINE_STATUS => ({
        text: PIPELINE_STATUS,
        value: PIPELINE_STATUS
      })),
      onFilter: (value, record) => record.PIPELINE_STATUS === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>INS Entity</strong>,
      dataIndex: 'INS_ENTITY',
      key: 'INS_ENTITY',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueINSEntity.map(INS_ENTITY => ({
        text: INS_ENTITY,
        value: INS_ENTITY
      })),
      onFilter: (value, record) => record.INS_ENTITY === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Udyam Aadhar</strong>,
      dataIndex: 'UDYAM_AADHAR',
      key: 'UDYAM_AADHAR',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueUdyamAadhar.map(UDYAM_AADHAR => ({
        text: UDYAM_AADHAR,
        value: UDYAM_AADHAR
      })),
      onFilter: (value, record) => record.UDYAM_AADHAR === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Current Status</strong>,
      dataIndex: 'CURR_STATUS',
      key: 'CURR_STATUS',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueCurrStatus.map(CURR_STATUS => ({
        text: CURR_STATUS,
        value: CURR_STATUS
      })),
      onFilter: (value, record) => record.CURR_STATUS === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Current Status Updated</strong>,
      dataIndex: 'CURR_STATUS_UPDATED',
      key: 'CURR_STATUS_UPDATED',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left'
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applied Loan Amount</strong>,
      dataIndex: 'REQ_LOAN_AMT',
      key: 'REQ_LOAN_AMT',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left'
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Approved Loan Amount</strong>,
      dataIndex: 'APPROVED_LOAN_AMT',
      key: 'APPROVED_LOAN_AMT',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left'
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Assignee</strong>,
      dataIndex: 'ASSIGNEE',
      key: 'ASSIGNEE',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      filters: uniqueAssignee.map(ASSIGNEE => ({
        text: ASSIGNEE,
        value: ASSIGNEE
      })),
      onFilter: (value, record) => record.ASSIGNEE === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Legal Query</strong>,
      dataIndex: 'LEGAL_QUERY',
      key: 'LEGAL_QUERY',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left'
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Technical Query</strong>,
      dataIndex: 'TECHNICAL_QUERY',
      key: 'TECHNICAL_QUERY',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left'
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Application Technical Status</strong>,
      dataIndex: 'APP_TECHNICAL_STATUS',
      key: 'APP_TECHNICAL_STATUS',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left'
    },
    {
      title: <strong style={{ fontSize: '18px' }}>Application Legal Status</strong>,
      dataIndex: 'APP_LEGAL_STATUS',
      key: 'APP_LEGAL_STATUS',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left'
    },
    {
      title: <strong style={{ fontSize: '18px' }}>Business Verification Completion</strong>,
      dataIndex: 'BUSINESS_VERIFICATION_COMPLETION',
      key: 'BUSINESS_VERIFICATION_COMPLETION',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left'
    },
    {
      title: <strong style={{ fontSize: '18px' }}>Credit Decisioning Completion</strong>,
      dataIndex: 'CREDIT_DECISIONING_COMPLETION',
      key: 'CREDIT_DECISIONING_COMPLETION',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left'
    }
  ]

  // const convertUTCToIST = (utcDateString) => {
  //   console.log('Wrong Datetime - ', utcDateString)
  //   // Parse the UTC date string
  //   const utcDate = new Date(utcDateString);

  //   // Convert to IST (UTC+5:30)
  //   const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
  //   const istDate = new Date(utcDate.getTime() + istOffset);

  //   // Get the components of the date
  //   const options = {
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     second: '2-digit',
  //     hour12: true,
  //     year: 'numeric',
  //     month: '2-digit',
  //     day: '2-digit',
  //     timeZone: 'Asia/Kolkata', // Set the time zone to IST
  //   };

  //   // Format the date
  //   const formattedDate = new Intl.DateTimeFormat('en-IN', options).format(istDate);

  //   // Split the formatted date into date and time parts
  //   const [datePart, timePart] = formattedDate.split(', ');

  //   // Replace slashes with dashes in the date part
  //   const dateWithDashes = datePart.replace(/\//g, '-');

  //   // Return the final formatted date with time
  //   return `${dateWithDashes}, ${timePart}`;
  // };

  const convertUTCToIST = (utcDateString) => {
    console.log('Wrong time - ', utcDateString)
    // Split by comma if multiple date strings are present
    const dateStrings = utcDateString.split(',').map(date => date.trim());

    // Process each date string and convert it to IST format
    const formattedDates = dateStrings.map(dateStr => {
      const utcDate = new Date(dateStr);
      if (isNaN(utcDate)) {
        console.warn("Invalid datetime received:", dateStr);
        return null;
      }

      // Convert to IST (UTC+5:30)
      const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
      const istDate = new Date(utcDate.getTime() + istOffset);

      // Format components manually
      const day = String(istDate.getDate()).padStart(2, '0');
      const month = String(istDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = istDate.getFullYear();
      const hours = String(istDate.getHours() % 12 || 12).padStart(2, '0'); // Handle 12-hour format
      const minutes = String(istDate.getMinutes()).padStart(2, '0');
      const seconds = String(istDate.getSeconds()).padStart(2, '0');
      const ampm = istDate.getHours() >= 12 ? 'pm' : 'am';

      return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds} ${ampm}`;
    });

    // Filter out any null values (in case of invalid date strings)
    return formattedDates.filter(Boolean).join(' | ');
  };




  const handleRowClick = async record => {
    setStageDataSource([])
    // setIsModalVisible(true)
    const stage = record.stage // Get the stage from the clicked record
    console.log('Stage Name', stage)

    if (stage != 'GRAND TOTAL') {
      try {
        const response = await dispatch(
          detailedStageReport({ stage, jwtBearer })
        )
        const responseData = response.payload // Access the response data

        setDetailedLoading(false)

        console.log('Stage Data - ', responseData)

        // Detailed Report

        const allDetailedFields = responseData?.message || []
        console.log('Credit Pipeline All Fields Detailed - ', allDetailedFields)

        // if (allDetailedFields) {
        //   const fieldArrays = Object.keys(allDetailedFields.APP_ID || {}).map(
        //     key => ({
        //       APP_ID: allDetailedFields.APP_ID?.[key],
        //       ZONE: allDetailedFields.ZONE?.[key],
        //       supervisor_name: allDetailedFields.supervisor_name?.[key],
        //       BR_CD: allDetailedFields.BR_CD?.[key],
        //       BA_CODE: allDetailedFields.BA_CODE?.[key],
        //       BA_NAME: allDetailedFields.BA_NAME?.[key],
        //       TYPE: allDetailedFields.TYPE?.[key],
        //       REPEAT_APP_ID: allDetailedFields.REPEAT_APP_ID?.[key],
        //       APPLICANT_NAME: allDetailedFields.APPLICANT_NAME?.[key],
        //       APPLICANT_MOBILE: allDetailedFields.APPLICANT_MOBILE?.[key],
        //       PRODUCT: allDetailedFields.PRODUCT?.[key],
        //       CURR_STATUS: allDetailedFields.CURR_STATUS?.[key],
        //       CURR_STATUS_UPDATED: allDetailedFields.CURR_STATUS_UPDATED?.[key],
        //       REQ_LOAN_AMT: allDetailedFields.REQ_LOAN_AMT?.[key],
        //       APPROVED_LOAN_AMT: allDetailedFields.APPROVED_LOAN_AMT
        //         ? allDetailedFields.APPROVED_LOAN_AMT?.[key]
        //         : 0,
        //       ASSIGNEE: allDetailedFields.ASSIGNEE?.[key],
        //       LEGAL_QUERY: allDetailedFields.LEGAL_QUERY?.[key] != 'NULL' ? JSON.parse(allDetailedFields.LEGAL_QUERY?.[key]).sort((a, b) => new Date(b.DateTime) - new Date(a.DateTime))[0].Feedback : 'NULL',
        //       // LEGAL_QUERY: stage == 'PAYMENT QUEUE' ? 'NULL' : allDetailedFields.LEGAL_QUERY?.[key] != 'NULL' ? JSON.parse(allDetailedFields.LEGAL_QUERY?.[key]).sort((a, b) => new Date(b.DateTime) - new Date(a.DateTime))[0].Feedback : 'NULL',
        //       TECHNICAL_QUERY: allDetailedFields.TECHNICAL_QUERY?.[key] != 'NULL' ? JSON.parse(allDetailedFields.TECHNICAL_QUERY?.[key]).sort((a, b) => new Date(b.DateTime) - new Date(a.DateTime))[0].Feedback : 'NULL'

        //     })
        //   )

        //   console.log('Format Detailed', fieldArrays)

        //   setSelectedRowData(fieldArrays)
        // }

        if (allDetailedFields) {
          const fieldArrays = Object.keys(allDetailedFields.APP_ID || {}).map(
            key => {
              try {
                return {
                  APP_ID: allDetailedFields.APP_ID?.[key],
                  ZONE: allDetailedFields.ZONE?.[key],
                  supervisor_name: allDetailedFields.supervisor_name?.[key],
                  BR_CD: allDetailedFields.BR_CD?.[key],
                  BA_CODE: allDetailedFields.BA_CODE?.[key],
                  BA_NAME: allDetailedFields.BA_NAME?.[key],
                  TYPE: allDetailedFields.TYPE?.[key],
                  REPEAT_APP_ID: allDetailedFields.REPEAT_APP_ID?.[key],
                  APPLICANT_NAME: allDetailedFields.APPLICANT_NAME?.[key],
                  APPLICANT_MOBILE: allDetailedFields.APPLICANT_MOBILE?.[key],
                  PRODUCT: allDetailedFields.PRODUCT?.[key],
                  BUSINESS_TYPE: allDetailedFields.BUSINESS_TYPE?.[key],
                  BUSINESS_TYPE_1: allDetailedFields.BUSINESS_TYPE_1?.[key],
                  PIPELINE_STATUS: allDetailedFields.PIPELINE_STATUS?.[key],
                  INS_ENTITY: allDetailedFields.INS_ENTITY?.[key],
                  UDYAM_AADHAR: allDetailedFields.UDYAM_AADHAR?.[key],
                  CURR_STATUS: allDetailedFields.CURR_STATUS?.[key],
                  // CURR_STATUS_UPDATED: allDetailedFields.CURR_STATUS_UPDATED?.[key],
                  CURR_STATUS_UPDATED: allDetailedFields.CURR_STATUS_UPDATED?.[key]
                    ? convertUTCToIST(allDetailedFields.CURR_STATUS_UPDATED?.[key])
                    : null, // Only convert if there is a valid date
                  REQ_LOAN_AMT: allDetailedFields.REQ_LOAN_AMT?.[key],
                  APPROVED_LOAN_AMT: allDetailedFields.APPROVED_LOAN_AMT
                    ? allDetailedFields.APPROVED_LOAN_AMT?.[key]
                    : 0,
                  ASSIGNEE: allDetailedFields.ASSIGNEE?.[key],
                  LEGAL_QUERY: allDetailedFields.LEGAL_QUERY?.[key] !== 'NULL'
                    ? JSON.parse(allDetailedFields.LEGAL_QUERY?.[key]).sort((a, b) => new Date(b.DateTime) - new Date(a.DateTime))[0]?.Feedback
                    : 'NULL',
                  TECHNICAL_QUERY: allDetailedFields.TECHNICAL_QUERY?.[key] !== 'NULL'
                    ? JSON.parse(allDetailedFields.TECHNICAL_QUERY?.[key]).sort((a, b) => new Date(b.DateTime) - new Date(a.DateTime))[0]?.Feedback
                    : 'NULL',
                  APP_TECHNICAL_STATUS: allDetailedFields.APP_TECHNICAL_STATUS?.[key],
                  APP_LEGAL_STATUS: allDetailedFields.APP_LEGAL_STATUS?.[key],
                  BUSINESS_VERIFICATION_COMPLETION: allDetailedFields.BUSINESS_VERIFICATION_COMPLETION?.[key],
                  CREDIT_DECISIONING_COMPLETION: allDetailedFields.CREDIT_DECISIONING_COMPLETION?.[key]
                };
              } catch (error) {
                console.error("Error processing LEGAL_QUERY for key:", key, "Error:", error);
                console.log("allDetailedFields.APP_ID for key:", key, allDetailedFields.APP_ID?.[key]);
                return null; // or handle the error as needed
              }
            }
          ).filter(Boolean); // remove any null values from the resulting array

          console.log('Format Detailed', fieldArrays);

          setSelectedRowData(fieldArrays);
        }



        // useEffect(() => {
        // if (allDetailedFields) {
        //   const fieldArrays = Object.keys(allDetailedFields.APP_ID).map(key => ({
        //     APP_ID: allDetailedFields.APP_ID[key],
        //     ZONE: allDetailedFields.ZONE[key],
        //     supervisor_name: allDetailedFields.supervisor_name[key],
        //     BR_CD: allDetailedFields.BR_CD[key],
        //     BA_CODE: allDetailedFields.BA_CODE[key],
        //     BA_NAME: allDetailedFields.BA_NAME[key],
        //     APPLICANT_NAME: allDetailedFields.APPLICANT_NAME[key],
        //     APPLICANT_MOBILE: allDetailedFields.APPLICANT_MOBILE[key],
        //     PRODUCT: allDetailedFields.PRODUCT[key],
        //     CURR_STATUS: allDetailedFields.CURR_STATUS[key],
        //     CURR_STATUS_UPDATED: allDetailedFields.CURR_STATUS_UPDATED[key],
        //     REQ_LOAN_AMT: allDetailedFields.REQ_LOAN_AMT[key],
        //     ASSIGNEE: allDetailedFields.ASSIGNEE[key]
        //   }))

        // console.log('Format Detailed', fieldArrays)

        //   setSelectedRowData(fieldArrays)
        // }
        // }, [allDetailedFields])

        // Detailed Report
      } catch (error) {
        // Handle error
        console.error('Error fetching stage data:', error)
      }
      setSelectedStage(stage)
      setIsModalVisible(true)
    }
  }
  useEffect(() => {
    // Fetch and set detailed data when selected row data changes
    if (selectedRowData.length > 0) {
      setDetailedLoading(true) // Show the spinner while loading detailed data
      setStageDataSource(selectedRowData)
      setDetailedLoading(false)
    }
  }, [selectedRowData])

  const handleModalClose = () => {
    setIsModalVisible(false)
  }

  const handleSearch = (selectedKeys, confirm) => {
    confirm()
  }

  const handleReset = clearFilters => {
    // setSelectedKeys([])
    // handleSearch([], confirm)
    clearFilters()
  }

  const uniqueStages = [...new Set(dataSource.map(item => item.stage))]
  const uniqueCases = [...new Set(dataSource.map(item => item.cases))]
  const columns = [
    {
      title: <strong style={{ fontSize: '18px' }}>STAGES</strong>,
      dataIndex: 'stage',
      key: 'stage',
      width: '70%',
      align: 'left',
      className: 'table-heading',
      filters: uniqueStages.map(stage => ({
        text: stage,
        value: stage
      })),
      onFilter: (value, record) => record.stage === value,
      filterSearch: true
    },
    {
      title: <strong style={{ fontSize: '18px' }}>CASES</strong>,
      dataIndex: 'cases',
      key: 'cases',
      width: '30%',
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      align: 'left',
      // className: 'table-heading',
      filters: uniqueCases.map(cases => ({
        text: cases,
        value: cases
      })),
      onFilter: (value, record) => record.cases === value,
      filterSearch: true
    }
  ]
  const { authState, onLogout } = useAuth()
  var token_decoded = authState.token
  var jwtBearer = authState.token
  var jwt_payload = ''
  var jwt_payload_group = []
  if (jwtBearer) {
    console.log('Token decoded')
    jwt_payload = jwt_decode(jwtBearer)
    // jwt_payload_group = jwt_payload.group_id
    jwt_payload_group = props.userDetails.group_id
    console.log('JWT Group Reports - ', jwt_payload_group)
  }

  console.log('JWT Bearer Credit - ', jwtBearer)

  useEffect(() => {
    dispatch(fetchCreditReport({ jwtBearer })).then(() => {
      setLoading(false) // Set loading state to false when the data is fetched
    })
  }, [])

  const state = useSelector(state => state)
  console.log('Credit Pipeline State - ', state)

  const tokenExpired = state.creditReports.data?.detail || ''
  const TOKEN_KEY = 'my-jwt'
  if (tokenExpired == 'Signature has expired.') {
    console.log('In token expired condition')
    AsyncStorage.removeItem(TOKEN_KEY)
    onLogout
    location.reload()
  }

  const allFields = state.creditReports.data?.message || []
  // const updatedOn = state.creditReports.data?.updated_on || []
  // if (
  //   state.creditReports.data &&
  //   state.creditReports.data.updated_on.length > 0
  // ) {
  const updatedOn = state.creditReports.data?.updated_on || ''
  dispatch(setCreditUpdatedOn(updatedOn))

  console.log('Credit Updated On - ', updatedOn)
  // } else {
  //   console.log('No updated dates found.')
  // }
  console.log('Credit Pipeline All Fields - ', allFields)

  // New Layout

  // useEffect(() => {
  //   const fieldArrays = Object.values(allFields)

  //   console.log('Credit Fields Array - ', fieldArrays)

  //   if (fieldArrays.length > 0) {
  //     console.log('In credit condition')
  //     const numRows = fieldArrays[0].length
  //     const formattedData = []

  //     for (let i = 0; i < numRows; i++) {
  //       const data = {}
  //       for (let j = 0; j < fieldArrays.length; j++) {
  //         const fieldName = Object.keys(allFields)[j]
  //         data[fieldName] = fieldArrays[j][i]
  //       }

  //       formattedData.push(data)
  //     }
  //     console.log('Credit Formatted Data', formattedData)

  //     setDataSource(formattedData)
  //   }
  // }, [allFields])
  useEffect(() => {
    if (allFields && allFields.stage && allFields.value) {
      const fieldArrays = Object.keys(allFields.stage).map(key => ({
        stage: allFields.stage[key],
        cases: allFields.value[key]
      }))

      setDataSource(fieldArrays)
    }
  }, [allFields])

  console.log('Credit Datasource - ', dataSource)

  // New Layout

  // useEffect(() => {
  //   setDataSource(allFields)
  // }, [allFields])

  // console.log('Credit Pipeline Datasource - ', allFields)

  // useEffect(() => {
  //   const fieldArrays = Object.values(allFields)

  //   if (fieldArrays.length > 0) {
  //     const formattedData = fieldArrays[0].map((stage, index) => ({
  //       stage,
  //       cases: fieldArrays[1][index]
  //     }))

  //     setDataSource(formattedData)
  //   }
  // }, [allFields])

  const downloadReport = () => {
    setButtonLoading(true)
    dispatch(downloadCreditReport({ jwtBearer, updatedOn })).then(() => {
      setButtonLoading(false)
    })
  }

  // useEffect(() => {
  //   downloadReport()
  // }, []) // Call the function directly inside useEffect

  const paginationOptions = {
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    defaultPageSize: 10, // Initial page size
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
  }

  return (
    <>
      {/* <div className='tabled'> */}
      <Card
        layout='horizontal'
        bordered={true}
        className='criclebox tablespace mb-24'
        title='Pipeline Report'
        centered
        style={{ width: '100%', borderRadius: '20px' }}
        extra={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* {jwt_payload_group.includes('3') ? null : ( */}
            {/* {jwt_payload_group == '1' || */}
            {jwt_payload_group == '2' || jwt_payload_group == '3' ? null : (
              <StyledButton
                style={{
                  borderRadius: "20px",
                  background: "linear-gradient(90deg, #4e54c8, #8f94fb)",

                  fontWeight: "600",
                  padding: "0.8rem 1.3rem",
                  border: "0",
                  color: "white",

                  fontSize: "16px",
                }}
                onClick={downloadReport}
                loading={buttonLoading}
              >
                {/* {loading ? <Spin /> : 'Download Report'} */}
                Download Report
              </StyledButton>
            )}
          </div>
        }
      >
        <div
          className='table-responsive'
          style={{
            // overflow: 'scroll',
            height: '100vh'
          }}
        >
          {loading ? (
            <div className='spinner-container'>
              {/* <Spin size='large' /> */}
              <HashLoader color="#f57171" />
            </div>
          ) : (
            <StyledTable
              onRow={
                // jwt_payload_group.includes('3')
                //   ? null
                // :
                (record, rowIndex) => ({
                  onClick: () => handleRowClick(record)
                })
              }
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              bordered
              // rowClassName={(record, index) =>
              //   index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
              // }
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? 'table-row-light table-row-clickable'
                  : 'table-row-dark table-row-clickable'
              }
            />
          )}
        </div>
        <Modal
          title={<strong style={{ fontSize: "1.7rem" }}>{`${selectedStage} REPORT`}</strong>}
          visible={isModalVisible}
          width={2000}
          centered
          onCancel={handleModalClose}
          footer={[
            <Button key='close' onClick={handleModalClose}>
              Close
            </Button>
          ]}
          style={{ textAlign: 'center' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '12px'
            }}
          >
            {/* {jwt_payload_group == '2' || jwt_payload_group == '3' ? null : ( */}
            <StyledCSVLink

              data={stageDataSource}
              onClick={() => { }}
            >
              Download Report
            </StyledCSVLink>
            {/* )} */}
          </div>
          <div className='table-responsive'>
            {detailedLoading ? (
              <div className='spinner-container'>
                {/* <Spin size='large' /> */}
                <HashLoader color="#f57171" />
              </div>
            ) : (
              <StyledTable
                // onRow={(record, rowIndex) => ({
                //   onClick: () => handleRowClick(record)
                // })}
                columns={detailedColumns}
                dataSource={stageDataSource}
                pagination={paginationOptions}
                bordered
              // rowClassName={(record, index) =>
              //   index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
              // }
              />
            )}
          </div>
          {/* </div> */}
        </Modal>
      </Card>
      {/* </div> */}
    </>
  )
}

export default CreditPipeline
