import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../apiConfig'

export const updateUserPassword = createAsyncThunk(
  'updateUserPassword',
  async ({ jwt_token, requestBody }) => {
    console.log('Request Body - ', requestBody)
    const options = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwt_token,
        'Content-Type': 'application/json' // Set content type to JSON
      },
      // body: JSON.stringify({ inventory_json: requestBody }) // Wrap requestBody as needed
      body: JSON.stringify(requestBody) // Wrap requestBody as needed
    }

    try {
      const response = await fetch(
        `${BASE_URL}admin/update/user/password/`,
        options
      )
      console.log('Request Options - ', options)
      console.log('Response -- ', response)
      console.log('Response Payload - ', response.status)
      // if(response.status == 201) {
      //   alert('Old and new password does not match.')
      // }
      return response.json() // Assuming the response is JSON
    } catch (error) {
      console.log('Error', error)
      alert(error.message)
      throw error
    }
  }
)

const updateUserPasswordSlice = createSlice({
  name: 'updateUserPassword',
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: null
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateUserPassword.pending, state => {
      state.isLoading = true
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(updateUserPassword.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(updateUserPassword.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.errorMessage = action.error.message
    })
  }
})

export default updateUserPasswordSlice.reducer
