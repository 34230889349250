import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { useAuth } from "../../../context/AuthContext";
import { fetchUserTickets } from "../../../redux/slice/getCMAUserTickets";
// import { fetchDefaultResolution } from '../../../redux/slice/getDefaultResolution'
import { fetchDefaultQuestion } from "../../../redux/slice/getDefaultQuestion";
import { fetchDepartment } from "../../../redux/slice/getCMADepartment";
import { fetchTrail } from "../../../redux/slice/getCMATrail";
import { updateResolution } from "../../../redux/slice/updateCMAResolution";
import { acknowledgeTicket } from "../../../redux/slice/acknowledgeTicket";
import { updateCustomerQuery } from "../../../redux/slice/updateCMAQuery";
import { returnTicketToSupport } from "../../../redux/slice/returnTicket";
import { updateDepartment } from "../../../redux/slice/updateCMADepartment";
import { updateRescheduleDate } from "../../../redux/slice/updateTicketRescheduleDate";
import { updateDeptToDept } from "../../../redux/slice/updateCMADeptToDept";
import { updateDescription } from "../../../redux/slice/updateTicketDescription";
// import { updateDescription } from '../../../redux/slice/updateTicketDescription_BKP'
import moment from "moment"; // Import moment library
import deptModal from "../components/deptModal";
import {
  LikeOutlined,
  MessageOutlined,
  StarOutlined,
  PhoneOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Space,
  Spin,
  Table,
  Card,
  Modal,
  Button,
  Input,
  Tabs,
  Upload,
  message,
  Tooltip,
  Radio,
  Form,
  Typography,
  Divider,
  Alert,
  Row,
  Col,
  List,
  Tag,
  DatePicker,
} from "antd";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { CSVLink } from "react-csv";

const CMAUserTickets = () => {
  console.log("In open ticket");
  const dispatch = useDispatch();
  const [assetDataSource, setAssetDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deptModalVisible, setDeptModalVisible] = useState(false);
  const [resolutions, setResolutions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [showInputArea, setShowInputArea] = useState(false);
  const [showResolutionOptions, setShowResolutionOptions] = useState(false);
  const [showSubmitDescription, setShowSubmitDescription] = useState(false);
  const [showAdditionalTextArea, setShowAdditionalTextArea] = useState(false);
  const [departments, setDepartments] = useState(false);
  const [reschedule, setReschedule] = useState(false);
  const [resolutionValue, setResolutionValue] = useState(null);
  const [departmentValue, setDepartmentValue] = useState(null);
  const [departmentToDeptValue, setDepartmentToDeptValue] = useState(null);
  const [deptChangeValue, setDeptChangeValue] = useState(null);
  const [deptReChangeValue, setDeptReChangeValue] = useState(null);
  const [deptResolutionSubmit, setDeptResolutionSubmit] = useState(null);
  const [ticketNumber, setTicketNumber] = useState(null);
  const [additionalInformationValue, setAdditionalInformationValue] =
    useState("");
  const [customerQueryValue, setCustomerQueryValue] = useState("");
  const [rescheduleDate, setRescheduleDate] = useState("");
  const [deptAdditionalInformationValue, setDeptAdditionalInformationValue] =
    useState("");
  const [deptRelevance, setDeptRelevance] = useState("");
  const [showDeptChangeRadio, setShowDeptChangeRadio] = useState(false);
  const [showDeptModal, setShowDeptModal] = useState(false);
  const [relatedToDept, setRelatedToDept] = useState(false);
  const [deptResolution, setDeptResolution] = useState(false);
  const [descriptionSubmitted, setDescriptionSubmitted] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [trailData, setTrailData] = useState([]);
  const [deptFromAPI, setDeptFromAPI] = useState("");

  useEffect(() => {
    dispatch(
      fetchUserTickets({ jwtBearer: "JWT", userid: user_id, status: "Open" })
    ).then(() => {
      setLoading(false); // Set loading state to false when the data is fetched
    });
  }, []);

  const state = useSelector((state) => state);

  console.log("User Ticket State - ", state);

  const allFieldsReject = state.userTicketsSlice.data?.message || [];

  console.log("Inventory Pandas Reject - ", allFieldsReject);

  const keysReject = allFieldsReject["ticket_number"]
    ? Object.keys(allFieldsReject["ticket_number"])
    : [];

  // Create an array of objects in the desired format

  const objectsArrayReject = keysReject.map((key) => {
    const obj = {};
    for (const subKey in allFieldsReject) {
      obj[subKey] = allFieldsReject[subKey][key];
    }
    return obj;
  });

  console.log("CMA Open tickets complete - ", objectsArrayReject);

  useEffect(() => {
    // Check if the resolution value is true (Yes)
    setShowAdditionalTextArea(resolutionValue === true);
    setShowDeptChangeRadio(resolutionValue === false);
  }, [resolutionValue]);

  useEffect(() => {
    // Check if the resolution value is true (Yes)
    setDepartments(deptChangeValue === true);
    setReschedule(deptChangeValue === false);
  }, [deptChangeValue]);

  const { authState, onLogout } = useAuth();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Inside on finish function");

    let resolutionValue;

    const requestBody = {
      ticket_number: values.ticket_number,
      customerQuery: values.customerQuery,
      description: customerQueryValue,
      admin_id: user_id,
    };
    setTicketNumber(values.ticket_number);

    dispatch(updateCustomerQuery({ jwt_token: authState.token, requestBody }));
    dispatch(fetchUserTickets({ jwtBearer, userid: user_id, status: "Open" }));
    // Reset the form and mode after submission

    setShowResolutionOptions(true);
  };

  const handleReturnToSupport = () => {
    const ticket_number = ticketNumber;

    const requestBody = {
      dept_description: deptAdditionalInformationValue,
    };

    dispatch(
      returnTicketToSupport({
        jwt_token: authState.token,
        ticket_number,
        requestBody,
      })
    );
    setDeptModalVisible(false);
    dispatch(fetchUserTickets({ jwtBearer, userid: user_id, status: "Open" }));
  };

  const handleUpdateDescription = () => {
    const requestBody = {
      ticket_number: ticketNumber,
      description: customerQueryValue,
    };

    dispatch(updateDescription({ jwt_token: authState.token, requestBody }));

    setShowSubmitDescription(true);
    setDescriptionSubmitted(true);
  };

  const handleUpdateRescheduleDate = () => {
    const requestBody = {
      ticket_number: ticketNumber,
      rescheduleDate: rescheduleDate,
    };

    dispatch(updateRescheduleDate({ jwt_token: authState.token, requestBody }));

    setIsModalVisible(false);
    dispatch(fetchUserTickets({ jwtBearer, userid: user_id, status: "Open" }));
  };

  const handleAdditionalInformationChange = (e) => {
    const value = e.target.value;
    setAdditionalInformationValue(value);
  };

  const handleCustomerQueryChange = (e) => {
    const value = e.target.value;
    setCustomerQueryValue(value);
  };

  const handleRescheduleDateChange = (date, dateString) => {
    console.log("Rescheduled Date String - ", dateString);
    setRescheduleDate(dateString);
  };

  const handleDeptAdditionalInformationChange = (e) => {
    const value = e.target.value;
    setDeptAdditionalInformationValue(value);
  };

  const handleDeptRelevanceChange = (e) => {
    const value = e.target.value;
    setDeptRelevance(value);
  };

  const handleDeptChange = (e) => {
    setSelectedDepartment(value);
  };

  const handleCloseTicket = () => {
    console.log("Ticket Number - ", ticketNumber);
    console.log("Additional Information:", additionalInformationValue);

    const requestBody = {
      ticket_number: ticketNumber,
      resolution: additionalInformationValue,
      admin_id: user_id,
    };

    dispatch(updateResolution({ jwt_token: authState.token, requestBody }));
    dispatch(fetchUserTickets({ jwtBearer, userid: user_id, status: "Open" }));
    // Reset the form and mode after submission
    form.resetFields();
    setSelectedRow(null);

    handleCancel();
    dispatch(fetchUserTickets({ jwtBearer, userid: user_id, status: "Open" }));
  };

  const handleCancel = () => {
    setCustomerQueryValue(undefined);
    form.setFieldsValue({
      customerQuery: undefined,
    });
    setDescriptionSubmitted(false);
    setIsModalVisible(false);
    setDeptModalVisible(false);
  };

  // State to store the selected row data
  const [selectedRow, setSelectedRow] = useState(null);

  // let trailData = []
  const onRowClick = async (record) => {
    // console.log('Row Data Support - ', record.category_ID)
    console.log("Row Data Support - ", record);
    setTicketNumber(record.ticket_number);

    const requestBody = {
      ticket_number: record.ticket_number,
    };

    dispatch(acknowledgeTicket({ jwt_token: authState.token, requestBody }));

    const deptResponse = await dispatch(
      fetchDepartment({ jwtBearer, ticket_number: record.ticket_number })
    );
    const departmentData = deptResponse.payload.message || {};

    setDeptFromAPI(deptResponse.payload.message);

    console.log("Current Department - ", departmentData);

    const trailResponse = await dispatch(
      fetchTrail({ jwtBearer, ticket_number: record.ticket_number })
    );
    // trailData = trailResponse.payload.message || [];
    setTrailData(trailResponse.payload.message);

    console.log("Current Trail - ", trailData);

    // const response = await dispatch(
    //   fetchDefaultResolution({ jwtBearer, category_id: record.category_ID })
    // )

    // const questionResponse = await dispatch(
    //   fetchDefaultQuestion({ jwtBearer, category_id: record.category_ID })
    // )
    // const resolutionData = response.payload.message.resolution || {};
    // const resolutionsArray = Object.values(resolutionData);
    // setResolutions(resolutionsArray);

    // const questionData = response.payload.message.question || {};
    // const questionsArray = Object.values(questionData);
    // setQuestions(questionsArray);

    // console.log('Default Resolution Data - ', response.payload.message.resolution)
    // Set the selected row data
    setSelectedRow(record);

    // Populate the form with the selected row data
    // form.setFieldsValue(record);
    if (record.description) {
      form.setFieldsValue({
        ...record,
        customerQuery: record.description, // Set the initial value for customerQuery
      });
      setDescriptionSubmitted(true);
    } else {
      form.setFieldsValue(record);
    }

    if (departmentData == "Support") {
      setIsModalVisible(true);
    } else {
      setDeptModalVisible(true);
    }
  };

  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var jwt_payload_group = [];
  var user_id = "";
  if (jwtBearer) {
    jwt_payload = jwt_decode(jwtBearer);
    console.log("Token decoded", jwt_payload.username);
    user_id = jwt_payload.user_id;
    jwt_payload_group = jwt_payload.group_id;
    console.log("JWT Group Reports - ", jwt_payload_group);
  }

  console.log("JWT Bearer Branch MIS - ", jwtBearer);

  useEffect(() => {
    dispatch(
      fetchUserTickets({ jwtBearer, userid: user_id, status: "Open" })
    ).then(() => {
      setLoading(false); // Set loading state to false when the data is fetched
    });
  }, []);

  // const state = useSelector(state => state)
  // console.log('CMA User Ticket State - ', state)

  const tokenExpired = state.userTicketsSlice.data?.detail || "";
  const TOKEN_KEY = "my-jwt";
  if (tokenExpired == "Signature has expired.") {
    console.log("In token expired condition");
    AsyncStorage.removeItem(TOKEN_KEY);
    onLogout;
    location.reload();
  }

  // const allFieldsReject = state.userTicketsSlice.data?.message || []

  // console.log('Inventory Pandas Reject - ', allFieldsReject)

  // const keysReject = allFieldsReject['ticket_number'] ? Object.keys(allFieldsReject['ticket_number']) : []

  // // Create an array of objects in the desired format

  // const objectsArrayReject = keysReject.map(key => {
  //   const obj = {}
  //   for (const subKey in allFieldsReject) {
  //     obj[subKey] = allFieldsReject[subKey][key]
  //   }
  //   return obj
  // })

  useEffect(() => {
    setAssetDataSource(objectsArrayReject);
  }, [allFieldsReject]);

  const allKeysReject = objectsArrayReject.reduce((keys, record) => {
    return keys.concat(Object.keys(record));
  }, []);

  const uniqueKeysReject = [...new Set(allKeysReject)];

  const totalColumns = uniqueKeysReject.length;
  const equalColumnWidth = 100; // Set your desired width here

  const columnsAsset = uniqueKeysReject.map((key) => ({
    title: <strong>{key.toUpperCase()}</strong>,
    dataIndex: key,
    key: key,
    width: totalColumns > 0 ? `${100 / totalColumns}%` : equalColumnWidth,
    align: "left",
    filters: [...new Set(objectsArrayReject.map((record) => record[key]))].map(
      (value) => ({
        text: value,
        value: value,
      })
    ),
    onFilter: (value, record) => record[key] === value,
    filterSearch: true,
  }));

  const paginationOptions = {
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
    defaultPageSize: 10, // Initial page size
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  const excludedColumns = [
    "loan_id",
    "customer_query",
    "main_id",
    "ticket_status",
    "description",
    "support_executive_id",
    "user_id",
    "resolution",
    "created_at",
    "updated_at",
    "category_ID",
  ];
  const deptExcludedColumns = [
    "title",
    "ticket_number",
    "username",
    "customer_name",
    "loan_id",
    "main_id",
    "ticket_status",
    "support_executive_id",
    "user_id",
    "resolution",
    "created_at",
    "updated_at",
    "category_ID",
  ];

  const usernameColumnIndex = columnsAsset.findIndex(
    (column) => column.dataIndex === "username"
  );

  // If "username" column exists, update its title to "Contact Number"
  if (usernameColumnIndex !== -1) {
    columnsAsset[usernameColumnIndex].title = <strong>CONTACT NUMBER</strong>;
  }

  const userIdColumnIndex = columnsAsset.findIndex(
    (column) => column.dataIndex === "customer_name"
  );

  // If "user_id" column exists, update its title to "Customer name"
  if (userIdColumnIndex !== -1) {
    columnsAsset[userIdColumnIndex].title = <strong>CUSTOMER NAME</strong>;
  }

  const DepartmentBoxes = ({ trailData }) => {
    return (
      <Space>
        {/* {trailData.map((trailItem, index) => ( */}
        {trailData?.length > 0 &&
          trailData.map((trailItem, index) => (
            <React.Fragment key={index}>
              <Tag color="blue">{trailItem.transferred_from}</Tag>
              {index < trailData.length - 1 && <ArrowRightOutlined />}
              {index === trailData.length - 1 && (
                <>
                  <ArrowRightOutlined />
                  <Tag color="blue">{trailItem.transferred_to}</Tag>
                </>
              )}
            </React.Fragment>
          ))}
      </Space>
    );
  };

  const columnsAssetWithPriority = [
    {
      title: <strong>PRIORITY</strong>,
      dataIndex: "created_at",
      key: "priority",
      width: "10%",
      align: "center",
      render: (text, record) => {
        // const createdAtMoment = moment(text);
        const createdAtMoment = moment.utc(text); // Parse the UTC timestamp
        const createdAtIST = createdAtMoment.utcOffset("+05:30"); // Convert to IST (UTC+5:30)

        const currentTime = moment();
        const diffInHours = currentTime.diff(createdAtIST, "hours");

        console.log("Created at time - ", createdAtIST);
        console.log("created at time current - ", currentTime);
        console.log("created at time diff hours - ", diffInHours);

        let priorityText = "";
        let priorityColor = "";

        if (diffInHours >= 4) {
          priorityText = "High";
          priorityColor = "red";
        } else if (diffInHours >= 2) {
          priorityText = "Medium";
          priorityColor = "gold";
        } else if (diffInHours >= 0) {
          priorityText = "Low";
          priorityColor = "green";
        }

        return (
          // <span style={{ backgroundColor: priorityColor }}><strong>{priorityText}</strong></span>
          <span>
            <strong>
              {
                <Tag
                  color={priorityColor}
                  style={{ width: 90, textAlign: "center" }}
                >
                  {priorityText}
                </Tag>
              }
            </strong>
          </span>
        );
      },
    },
    ...columnsAsset.filter(
      (column) => !excludedColumns.includes(column.dataIndex)
    ),
    {
      title: <strong>CREATED AT</strong>,
      dataIndex: "created_at",
      key: "created_at",
      width: "10%",
      render: (text, record) => {
        const createdAtMoment = moment.utc(text); // Parse the UTC timestamp
        const createdAtIST = createdAtMoment.utcOffset("+05:30"); // Convert to IST (UTC+5:30)

        const date = createdAtIST.format("YYYY-MM-DD");
        const time = createdAtIST.format("HH:mm");

        return (
          <div>
            <p>
              DATE : <strong>{date}</strong>
            </p>
            <p>
              TIME : <strong>{time}</strong>
            </p>
          </div>
        );
      },
    },
  ];

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  const handleReassign = () => {
    const requestBody = {
      ticket_number: ticketNumber,
      // description: customerQueryValue,
      description: deptRelevance,
      department: departmentValue,
    };

    dispatch(updateDepartment({ jwt_token: authState.token, requestBody }));

    setIsModalVisible(false);

    console.log("Reassign before fetch ticket");
    dispatch(fetchUserTickets({ jwtBearer, userid: user_id, status: "Open" }));
  };

  const handleDeptToDeptReassign = () => {
    const requestBody = {
      ticket_number: ticketNumber,
      description: deptRelevance,
      department: departmentValue,
    };

    dispatch(updateDeptToDept({ jwt_token: authState.token, requestBody }));

    setDeptModalVisible(false);

    dispatch(fetchUserTickets({ jwtBearer, userid: user_id, status: "Open" }));
  };

  return (
    <>
      <center>
        <div>
          <Card
            layout="horizontal"
            bordered={true}
            className="criclebox tablespace mb-24"
            title={<>CUSTOMER MOBILE APP TICKETS - OPEN</>}
            centered
            style={{ width: "100%", marginTop: "20px" }}
            extra={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              ></div>
            }
          >
            <div className="table-responsive">
              {loading ? (
                <div className="spinner-container">
                  <Spin size="large" />
                </div>
              ) : (
                <>
                  <Table
                    columns={columnsAssetWithPriority}
                    dataSource={assetDataSource}
                    pagination={paginationOptions}
                    bordered
                    rowClassName={(record, index) =>
                      index % 2 === 0
                        ? "table-row-light table-row-clickable"
                        : "table-row-dark table-row-clickable"
                    }
                    onRow={(record) => ({
                      onClick: () => onRowClick(record),
                    })}
                  />
                  <Modal
                    title={
                      <>
                        <Typography.Title
                          level={3}
                          style={{ textAlign: "center" }}
                        >
                          TICKET DETAILS
                        </Typography.Title>
                        <Divider />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            marginLeft: 20,
                            marginBottom: 20,
                          }}
                        >
                          <DepartmentBoxes trailData={trailData} />
                        </div>
                      </>
                    }
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                    width={1300}
                  >
                    {/* <Form form={form} onFinish={onFinish} layout="vertical"> */}
                    <Form form={form} layout="vertical">
                      <div style={{ height: 750 }}>
                        <Row style={{ width: "100%", height: "100%" }}>
                          {/* First Column */}
                          <Col flex={1} style={{ marginLeft: 18 }}>
                            <Card
                              style={{
                                borderRadius: "25px",
                                width: "300px",
                                marginBottom: 35,
                                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                              }}
                            >
                              {[
                                "ticket_number",
                                "customer_name",
                                "username",
                                "title",
                                "customer_query",
                                "description",
                              ].map((key) => {
                                const column = columnsAsset.find(
                                  (col) => col.dataIndex === key
                                );
                                if (column && key !== "application_id") {
                                  // Rename the title for 'username' to 'customer_number'
                                  column.title =
                                    key === "username" ? (
                                      <strong>CUSTOMER NUMBER</strong>
                                    ) : (
                                      column.title
                                    );
                                  column.title =
                                    key === "description" ? (
                                      <strong>SUPPORT DESCRIPTION</strong>
                                    ) : (
                                      column.title
                                    );

                                  return (
                                    <Form.Item
                                      key={column.key}
                                      label={column.title}
                                      name={column.dataIndex}
                                    >
                                      {key === "customer_query" ||
                                      key === "description" ? (
                                        <Input.TextArea
                                          disabled={true}
                                          style={{ color: "maroon" }}
                                          rows={4}
                                        />
                                      ) : (
                                        <Input
                                          disabled={true}
                                          style={{ color: "maroon" }}
                                        />
                                      )}
                                    </Form.Item>
                                  );
                                }
                                return null;
                              })}
                            </Card>
                          </Col>
                          <Col
                            flex={10}
                            style={{
                              marginLeft: 15,
                              overflowY: "auto",
                              height: "100%",
                            }}
                          >
                            {trailData?.length > 0 && (
                              <p>
                                <strong>ACTIVITY</strong>
                                <br />
                                <br />
                              </p>
                            )}
                            {/* {trailData.map((trailItem, index) => ( */}
                            {trailData?.length > 0 &&
                              trailData.map((trailItem, index) => (
                                <div key={index}>
                                  <Tag color="blue">
                                    {trailItem.transferred_from}
                                  </Tag>
                                  <ArrowRightOutlined />
                                  <Tag color="blue" style={{ marginLeft: 8 }}>
                                    {trailItem.transferred_to}
                                  </Tag>
                                  <Tag color="green" style={{ marginLeft: 8 }}>
                                    {trailItem.transferred_at}
                                  </Tag>
                                  {/* <p><strong>Transferred From:</strong> {trailItem.transferred_from}</p>
      <p><strong>Transferred To:</strong> {trailItem.transferred_to}</p> */}
                                  {/* <p><strong>Comment:</strong> {trailItem.comment}</p> */}
                                  {/* <p><strong>Transferred At:</strong> {trailItem.transferred_at}</p> */}
                                  <div
                                    style={{
                                      maxHeight: "90px", // Adjust the max height as needed
                                      maxWidth: "800px",
                                      marginTop: "10px",
                                      overflowY: "auto",
                                      border: "1px solid #e8e8e8",
                                      padding: "8px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    {trailItem.comment}
                                  </div>
                                  <Divider />
                                </div>
                              ))}
                            {!trailData?.length > 0 && (
                              <Form.Item
                                label={
                                  <strong>
                                    PLEASE ENTER THE DETAILED DESCRIPTION OF THE
                                    ISSUE FACED BY CUSTOMER:
                                  </strong>
                                }
                                // name="customerQuery"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter customer issue.",
                                  },
                                ]}
                              >
                                {/* <Input.TextArea style={{width: '825px', height: '100px', color: '#404040'}} onChange={handleCustomerQueryChange} disabled={descriptionSubmitted}/> */}
                                <Input.TextArea
                                  value={customerQueryValue}
                                  style={{ height: "100px", color: "#404040" }}
                                  onChange={handleCustomerQueryChange}
                                />
                              </Form.Item>
                            )}

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "right",
                              }}
                            >
                              {/* {customerQueryValue && !descriptionSubmitted && ( */}
                              {customerQueryValue && (
                                // <Button type='primary' onClick={handleUpdateDescription} htmlType='submit' style={{ marginRight: '8px' }} disabled={descriptionSubmitted} >
                                <Button
                                  type="primary"
                                  onClick={handleUpdateDescription}
                                  htmlType="submit"
                                  style={{ marginRight: "8px" }}
                                >
                                  Submit Description
                                </Button>
                              )}
                            </div>
                            {/* {showResolutionOptions && ( */}
                            {descriptionSubmitted && (
                              <Form.Item
                                label={<strong>IS THE QUERY RESOLVED ?</strong>}
                              >
                                <Radio.Group
                                  id="resolutionOptions"
                                  onChange={(e) => {
                                    setResolutionValue(e.target.value);

                                    // Set the value of setDepartments and setReschedule based on the resolution value
                                    if (e.target.value === true) {
                                      setDepartments(false);
                                      setReschedule(false);
                                    }
                                  }}
                                >
                                  <Radio value={true}>Yes</Radio>
                                  <Radio value={false}>No</Radio>
                                </Radio.Group>
                              </Form.Item>
                            )}
                            {showAdditionalTextArea && (
                              <Form.Item
                                label={
                                  <strong>
                                    FILL THE DETAILS ABOUT THE RESOLUTION
                                    PROVIDED
                                  </strong>
                                }
                                name="resolution"
                              >
                                <>
                                  <Input.TextArea
                                    style={{ height: "100px" }}
                                    onChange={handleAdditionalInformationChange}
                                  />
                                </>
                              </Form.Item>
                            )}
                            {showDeptChangeRadio && (
                              <Form.Item
                                label={
                                  <strong>
                                    DO YOU WANT TO TRANSFER THE TICKET TO OTHER
                                    DEPARTMENT ?
                                  </strong>
                                }
                                name="deptChange"
                              >
                                <Radio.Group
                                  id="deptChange"
                                  onChange={(e) => {
                                    setDeptChangeValue(e.target.value);
                                    setSelectedDepartment(null);
                                  }}
                                >
                                  <Radio value={true}>Yes</Radio>
                                  <Radio value={false}>No</Radio>
                                </Radio.Group>
                              </Form.Item>
                            )}
                            {departments && (
                              <Form.Item
                                label={<strong>SELECT DEPARTMENT</strong>}
                                name="dept"
                                rules={[
                                  {
                                    required:
                                      showDeptChangeRadio &&
                                      selectedDepartment === null,
                                    message: "Please select a department.",
                                  },
                                ]}
                              >
                                <Radio.Group
                                  id="dept"
                                  onChange={(e) => {
                                    setDepartmentValue(e.target.value);
                                  }}
                                >
                                  <Radio value="IT">IT</Radio>
                                  <Radio value="Credit">Credit</Radio>
                                  <Radio value="Operations">Operations</Radio>
                                </Radio.Group>
                              </Form.Item>
                            )}
                            {departmentValue && deptChangeValue && (
                              <Form.Item
                                label={
                                  <strong>
                                    EXPLAIN HOW IT IS NOT RELEVANT TO YOUR
                                    DEPARTMENT
                                  </strong>
                                }
                                name="deptRelevance"
                              >
                                <>
                                  <Input.TextArea
                                    style={{ height: "100px" }}
                                    onChange={handleDeptRelevanceChange}
                                  />
                                </>
                              </Form.Item>
                            )}
                            {/* {reschedule && (
                    <Form.Item
                      label={<strong>DO YOU WANT TO RESCHEDULE CALLBACK ?</strong>}
                      name="resch"
                    >
                        <Radio.Group
                        id="resch"
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      
                    </Form.Item>
                    )} */}
                            {reschedule && (
                              <Form.Item
                                label={<strong>RESCHEDULE CALLBACK</strong>}
                                name="resch"
                              >
                                <DatePicker
                                  showTime
                                  format="YYYY-MM-DD HH:mm"
                                  placeholder="Select Date and Time"
                                  disabledDate={(current) =>
                                    current && current < moment().startOf("day")
                                  }
                                  onChange={handleRescheduleDateChange}
                                />
                              </Form.Item>
                            )}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "right",
                              }}
                            >
                              {rescheduleDate && (
                                <Button
                                  type="primary"
                                  onClick={handleUpdateRescheduleDate}
                                  htmlType="submit"
                                  style={{ marginRight: "8px" }}
                                >
                                  Rechedule
                                </Button>
                              )}
                            </div>
                            <Form.Item style={{ width: "100%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "right",
                                }}
                              >
                                {/* {!showResolutionOptions && ( */}
                                {selectedDepartment && (
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ marginRight: "8px" }}
                                  >
                                    Submit
                                  </Button>
                                )}
                                {deptChangeValue && departmentValue && (
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ marginRight: "8px" }}
                                    onClick={handleReassign}
                                  >
                                    Reassign
                                  </Button>
                                )}
                                {/* {showAdditionalTextArea && ( */}
                                {showAdditionalTextArea &&
                                  additionalInformationValue && (
                                    <Button
                                      type="primary"
                                      onClick={handleCloseTicket}
                                      style={{ marginLeft: "8px" }}
                                    >
                                      Close Ticket
                                    </Button>
                                  )}
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </Modal>
                  <Modal
                    title={
                      <>
                        <Typography.Title
                          level={3}
                          style={{ textAlign: "center" }}
                        >
                          TICKET DETAILS DEPARTMENT
                        </Typography.Title>
                        <Divider />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <DepartmentBoxes trailData={trailData} />
                        </div>
                      </>
                    }
                    visible={deptModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                    width={1300}
                  >
                    {/* <Form form={form} onFinish={onFinish} layout="vertical"> */}
                    <Form form={form} layout="vertical">
                      <div style={{ height: 750 }}>
                        <Row style={{ width: "100%", height: "100%" }}>
                          {/* First Column */}
                          <Col flex={1} style={{ marginLeft: 18 }}>
                            <Card
                              style={{
                                borderRadius: "25px",
                                width: "300px",
                                marginBottom: 35,
                                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                              }}
                            >
                              {[
                                "ticket_number",
                                "customer_name",
                                "username",
                                "title",
                                "customer_query",
                                "description",
                              ].map((key) => {
                                const column = columnsAsset.find(
                                  (col) => col.dataIndex === key
                                );
                                if (column && key !== "application_id") {
                                  // Rename the title for 'username' to 'customer_number'
                                  column.title =
                                    key === "username" ? (
                                      <strong>CUSTOMER NUMBER</strong>
                                    ) : (
                                      column.title
                                    );

                                  return (
                                    <Form.Item
                                      key={column.key}
                                      label={column.title}
                                      name={column.dataIndex}
                                    >
                                      {key === "customer_query" ||
                                      key === "description" ? (
                                        <Input.TextArea
                                          disabled={true}
                                          style={{ color: "maroon" }}
                                          rows={4}
                                        />
                                      ) : (
                                        <Input
                                          disabled={true}
                                          style={{ color: "maroon" }}
                                        />
                                      )}
                                    </Form.Item>
                                  );
                                }
                                return null;
                              })}
                            </Card>
                          </Col>
                          <Col
                            flex={10}
                            style={{
                              marginLeft: 15,
                              marginRight: 18,
                              overflowY: "auto",
                              height: "100%",
                            }}
                          >
                            {trailData?.length > 0 && (
                              <p>
                                <strong>ACTIVITY</strong>
                                <br />
                                <br />
                              </p>
                            )}
                            {trailData?.length > 0 &&
                              trailData.map((trailItem, index) => (
                                <div key={index}>
                                  <Tag color="blue">
                                    {trailItem.transferred_from}
                                  </Tag>
                                  <ArrowRightOutlined />
                                  <Tag color="blue" style={{ marginLeft: 8 }}>
                                    {trailItem.transferred_to}
                                  </Tag>
                                  <Tag color="green" style={{ marginLeft: 8 }}>
                                    {trailItem.transferred_at}
                                  </Tag>

                                  {/* <p><strong>Comment:</strong> {trailItem.comment}</p> */}
                                  <div
                                    style={{
                                      maxHeight: "90px", // Adjust the max height as needed
                                      maxWidth: "800px",
                                      marginTop: "10px",
                                      overflowY: "auto",
                                      border: "1px solid #e8e8e8",
                                      padding: "8px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    {trailItem.comment}
                                  </div>
                                  <Divider />
                                </div>
                              ))}
                            <Form.Item
                              label={
                                <strong>
                                  IS THE QUERY RELATED TO YOUR DEPARTMENT ?
                                </strong>
                              }
                            >
                              <Radio.Group
                                id="relatedToDept"
                                onChange={(e) => {
                                  setDeptReChangeValue(!e.target.value);
                                  setDeptResolutionSubmit(e.target.value);

                                  // Set the value of setDepartments and setReschedule based on the resolution value
                                  if (e.target.value === false) {
                                    setDeptResolution(false);
                                    setRelatedToDept(true);
                                  } else {
                                    setRelatedToDept(false);
                                    setDeptResolution(true);
                                  }
                                }}
                              >
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                              </Radio.Group>
                            </Form.Item>
                            {relatedToDept && (
                              <Form.Item
                                label={
                                  <strong>
                                    TRANSFER THE TICKET TO THE RELATED
                                    DEPARTMENT
                                  </strong>
                                }
                                name="dept"
                              >
                                <Radio.Group
                                  id="dept"
                                  onChange={(e) => {
                                    setDepartmentValue(e.target.value);
                                  }}
                                >
                                  {[
                                    "Support",
                                    "IT",
                                    "Credit",
                                    "Operations",
                                  ].map(
                                    (department) =>
                                      // Render the Radio only if it's not the current department
                                      deptFromAPI !== department && (
                                        <Radio
                                          key={department}
                                          value={department}
                                        >
                                          {department}
                                        </Radio>
                                      )
                                  )}
                                  {/* <Radio value='Support'>Support</Radio>
                          <Radio value='IT'>IT</Radio>
                          <Radio value='Credit'>Credit</Radio>
                          <Radio value='Operations'>Operations</Radio> */}
                                </Radio.Group>
                              </Form.Item>
                            )}
                            {departmentValue && relatedToDept && (
                              <Form.Item
                                label={
                                  <strong>
                                    EXPLAIN HOW IT IS NOT RELEVANT TO YOUR
                                    DEPARTMENT
                                  </strong>
                                }
                                name="deptRelevance"
                              >
                                <>
                                  <Input.TextArea
                                    style={{ height: "100px" }}
                                    onChange={handleDeptRelevanceChange}
                                  />
                                </>
                              </Form.Item>
                            )}
                            {deptResolution && (
                              <Form.Item
                                label={
                                  <strong>
                                    FILL THE DETAILS ABOUT THE RESOLUTION
                                    PROVIDED
                                  </strong>
                                }
                                name="deptResProvided"
                              >
                                <>
                                  <Input.TextArea
                                    style={{ height: "100px" }}
                                    onChange={
                                      handleDeptAdditionalInformationChange
                                    }
                                  />
                                </>
                              </Form.Item>
                            )}
                            <Form.Item style={{ width: "100%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "right",
                                }}
                              >
                                {deptResolutionSubmit &&
                                  deptAdditionalInformationValue && (
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      style={{ marginRight: "8px" }}
                                      onClick={handleReturnToSupport}
                                    >
                                      Submit
                                    </Button>
                                  )}
                                {deptReChangeValue &&
                                  departmentValue &&
                                  deptRelevance && (
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      style={{ marginRight: "8px" }}
                                      onClick={handleDeptToDeptReassign}
                                    >
                                      Reassign
                                    </Button>
                                  )}
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </Modal>
                </>
              )}
            </div>
          </Card>
        </div>
      </center>
    </>
  );
};

export default CMAUserTickets;
