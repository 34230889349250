import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { useAuth } from '../../../context/AuthContext'
import { fetchLeads } from '../../../redux/slice/leadsSlice'
import LeadsPivot from './LeadsPivot'
import LeadsSummary from './LeadsSummary'

import { Spin, Table, Card, Collapse, Divider } from 'antd'

import AsyncStorage from '@react-native-async-storage/async-storage'
import { CSVLink } from 'react-csv'
import * as XLSX from 'xlsx'

const { Panel } = Collapse

const Leads = () => {
  const dispatch = useDispatch()
  const [selectedFile, setSelectedFile] = useState(null)
  const [dataSource, setDataSource] = useState([])
  const [convertDataSource, setConvertDataSource] = useState([])
  const [pivotDataSource, setPivotDataSource] = useState([])
  const [loading, setLoading] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [uploadAlert, setUploadAlert] = useState('')
  const [lastUpdatedTime, setLastUpdatedTime] = useState(null)
  const [fileList, setFileList] = useState([]) // To store the uploaded file
  const [userUploadType, setUserUploadType] = useState(null)
  const [isPanelExpanded, setIsPanelExpanded] = useState(false)

  // Pivot
  // const pivotData = {
  //   "('NULL', 'NULL')": {
  //     'ZFT0007-Praveen Rajwat': 12,
  //     'ZFT0014-Sharad Kumar Singh': 21,
  //     'ZFT0023-Yogesh Kuntal': 21,
  //     'ZFT0031-Praveen Solanki': 45,
  //     'ZFT0039-Irfan Beg': 33,
  //     'ZFT0045-Bhupendra Sain': 36,
  //     'ZFT0052-Vinod Pareek': 15,
  //     'ZFT0059-Sonu Soni': 27,
  //     'ZFT0066-Dilip Jaiman': 18,
  //     'ZFT0072-Pramod Sharma': 18,
  //     'ZFT0397-Satender Panwar': 48,
  //     'ZFT0488-Akash Sharma': 36,
  //     'ZFT0503-Rajesh Negi': 36,
  //     'ZFT0528-Parvin Yadav': 3,
  //     'ZFT0528-Praveen Yadav': 24,
  //     'ZFT0528-Pravin kumar Yadav': null
  //   },
  //   "('NULL', 'cold')": {
  //     'ZFT0007-Praveen Rajwat': null,
  //     'ZFT0014-Sharad Kumar Singh': null,
  //     'ZFT0023-Yogesh Kuntal': null,
  //     'ZFT0031-Praveen Solanki': null,
  //     'ZFT0039-Irfan Beg': null,
  //     'ZFT0045-Bhupendra Sain': null,
  //     'ZFT0052-Vinod Pareek': null,
  //     'ZFT0059-Sonu Soni': 30,
  //     'ZFT0066-Dilip Jaiman': null,
  //     'ZFT0072-Pramod Sharma': null,
  //     'ZFT0397-Satender Panwar': 18,
  //     'ZFT0488-Akash Sharma': 6,
  //     'ZFT0503-Rajesh Negi': null,
  //     'ZFT0528-Parvin Yadav': null,
  //     'ZFT0528-Praveen Yadav': 3,
  //     'ZFT0528-Pravin kumar Yadav': null
  //   },
  //   "('NULL', 'hot')": {
  //     'ZFT0007-Praveen Rajwat': 9,
  //     'ZFT0014-Sharad Kumar Singh': 9,
  //     'ZFT0023-Yogesh Kuntal': 9,
  //     'ZFT0031-Praveen Solanki': 9,
  //     'ZFT0039-Irfan Beg': 6,
  //     'ZFT0045-Bhupendra Sain': 24,
  //     'ZFT0052-Vinod Pareek': 6,
  //     'ZFT0059-Sonu Soni': 6,
  //     'ZFT0066-Dilip Jaiman': 18,
  //     'ZFT0072-Pramod Sharma': 9,
  //     'ZFT0397-Satender Panwar': 39,
  //     'ZFT0488-Akash Sharma': 30,
  //     'ZFT0503-Rajesh Negi': 6,
  //     'ZFT0528-Parvin Yadav': null,
  //     'ZFT0528-Praveen Yadav': 9,
  //     'ZFT0528-Pravin kumar Yadav': 3
  //   },
  //   "('NULL', 'warm')": {
  //     'ZFT0007-Praveen Rajwat': null,
  //     'ZFT0014-Sharad Kumar Singh': null,
  //     'ZFT0023-Yogesh Kuntal': 6,
  //     'ZFT0031-Praveen Solanki': 3,
  //     'ZFT0039-Irfan Beg': null,
  //     'ZFT0045-Bhupendra Sain': null,
  //     'ZFT0052-Vinod Pareek': null,
  //     'ZFT0059-Sonu Soni': null,
  //     'ZFT0066-Dilip Jaiman': null,
  //     'ZFT0072-Pramod Sharma': null,
  //     'ZFT0397-Satender Panwar': null,
  //     'ZFT0488-Akash Sharma': null,
  //     'ZFT0503-Rajesh Negi': 9,
  //     'ZFT0528-Parvin Yadav': null,
  //     'ZFT0528-Praveen Yadav': null,
  //     'ZFT0528-Pravin kumar Yadav': null
  //   },
  //   "('PL-SEC', 'cold')": {
  //     'ZFT0007-Praveen Rajwat': 3,
  //     'ZFT0014-Sharad Kumar Singh': 48,
  //     'ZFT0023-Yogesh Kuntal': 3,
  //     'ZFT0031-Praveen Solanki': 3,
  //     'ZFT0039-Irfan Beg': 36,
  //     'ZFT0045-Bhupendra Sain': 57,
  //     'ZFT0052-Vinod Pareek': 6,
  //     'ZFT0059-Sonu Soni': 45,
  //     'ZFT0066-Dilip Jaiman': 48,
  //     'ZFT0072-Pramod Sharma': 21,
  //     'ZFT0397-Satender Panwar': 66,
  //     'ZFT0488-Akash Sharma': 9,
  //     'ZFT0503-Rajesh Negi': 36,
  //     'ZFT0528-Parvin Yadav': null,
  //     'ZFT0528-Praveen Yadav': 18,
  //     'ZFT0528-Pravin kumar Yadav': null
  //   },
  //   "('PL-SEC', 'hot')": {
  //     'ZFT0007-Praveen Rajwat': 150,
  //     'ZFT0014-Sharad Kumar Singh': 279,
  //     'ZFT0023-Yogesh Kuntal': 141,
  //     'ZFT0031-Praveen Solanki': 204,
  //     'ZFT0039-Irfan Beg': 159,
  //     'ZFT0045-Bhupendra Sain': 156,
  //     'ZFT0052-Vinod Pareek': 129,
  //     'ZFT0059-Sonu Soni': 249,
  //     'ZFT0066-Dilip Jaiman': 177,
  //     'ZFT0072-Pramod Sharma': 333,
  //     'ZFT0397-Satender Panwar': 360,
  //     'ZFT0488-Akash Sharma': 360,
  //     'ZFT0503-Rajesh Negi': 180,
  //     'ZFT0528-Parvin Yadav': 3,
  //     'ZFT0528-Praveen Yadav': 90,
  //     'ZFT0528-Pravin kumar Yadav': 15
  //   },
  //   "('PL-SEC', 'warm')": {
  //     'ZFT0007-Praveen Rajwat': null,
  //     'ZFT0014-Sharad Kumar Singh': 27,
  //     'ZFT0023-Yogesh Kuntal': 21,
  //     'ZFT0031-Praveen Solanki': 12,
  //     'ZFT0039-Irfan Beg': 6,
  //     'ZFT0045-Bhupendra Sain': 12,
  //     'ZFT0052-Vinod Pareek': 12,
  //     'ZFT0059-Sonu Soni': 18,
  //     'ZFT0066-Dilip Jaiman': 3,
  //     'ZFT0072-Pramod Sharma': null,
  //     'ZFT0397-Satender Panwar': 6,
  //     'ZFT0488-Akash Sharma': 3,
  //     'ZFT0503-Rajesh Negi': 87,
  //     'ZFT0528-Parvin Yadav': null,
  //     'ZFT0528-Praveen Yadav': 12,
  //     'ZFT0528-Pravin kumar Yadav': null
  //   },
  //   "('PL-USEC', 'NULL')": {
  //     'ZFT0007-Praveen Rajwat': null,
  //     'ZFT0014-Sharad Kumar Singh': null,
  //     'ZFT0023-Yogesh Kuntal': null,
  //     'ZFT0031-Praveen Solanki': null,
  //     'ZFT0039-Irfan Beg': null,
  //     'ZFT0045-Bhupendra Sain': null,
  //     'ZFT0052-Vinod Pareek': null,
  //     'ZFT0059-Sonu Soni': null,
  //     'ZFT0066-Dilip Jaiman': null,
  //     'ZFT0072-Pramod Sharma': null,
  //     'ZFT0397-Satender Panwar': null,
  //     'ZFT0488-Akash Sharma': 6,
  //     'ZFT0503-Rajesh Negi': null,
  //     'ZFT0528-Parvin Yadav': null,
  //     'ZFT0528-Praveen Yadav': null,
  //     'ZFT0528-Pravin kumar Yadav': null
  //   },
  //   "('PL-USEC', 'cold')": {
  //     'ZFT0007-Praveen Rajwat': 3,
  //     'ZFT0014-Sharad Kumar Singh': 81,
  //     'ZFT0023-Yogesh Kuntal': null,
  //     'ZFT0031-Praveen Solanki': null,
  //     'ZFT0039-Irfan Beg': 126,
  //     'ZFT0045-Bhupendra Sain': 252,
  //     'ZFT0052-Vinod Pareek': 27,
  //     'ZFT0059-Sonu Soni': 57,
  //     'ZFT0066-Dilip Jaiman': 174,
  //     'ZFT0072-Pramod Sharma': 69,
  //     'ZFT0397-Satender Panwar': 222,
  //     'ZFT0488-Akash Sharma': 18,
  //     'ZFT0503-Rajesh Negi': 117,
  //     'ZFT0528-Parvin Yadav': null,
  //     'ZFT0528-Praveen Yadav': 156,
  //     'ZFT0528-Pravin kumar Yadav': null
  //   },
  //   "('PL-USEC', 'hot')": {
  //     'ZFT0007-Praveen Rajwat': 378,
  //     'ZFT0014-Sharad Kumar Singh': 441,
  //     'ZFT0023-Yogesh Kuntal': 906,
  //     'ZFT0031-Praveen Solanki': 786,
  //     'ZFT0039-Irfan Beg': 705,
  //     'ZFT0045-Bhupendra Sain': 576,
  //     'ZFT0052-Vinod Pareek': 546,
  //     'ZFT0059-Sonu Soni': 726,
  //     'ZFT0066-Dilip Jaiman': 849,
  //     'ZFT0072-Pramod Sharma': 720,
  //     'ZFT0397-Satender Panwar': 1341,
  //     'ZFT0488-Akash Sharma': 1344,
  //     'ZFT0503-Rajesh Negi': 174,
  //     'ZFT0528-Parvin Yadav': 9,
  //     'ZFT0528-Praveen Yadav': 636,
  //     'ZFT0528-Pravin kumar Yadav': 231
  //   },
  //   "('PL-USEC', 'warm')": {
  //     'ZFT0007-Praveen Rajwat': 3,
  //     'ZFT0014-Sharad Kumar Singh': 48,
  //     'ZFT0023-Yogesh Kuntal': 123,
  //     'ZFT0031-Praveen Solanki': 108,
  //     'ZFT0039-Irfan Beg': 27,
  //     'ZFT0045-Bhupendra Sain': 36,
  //     'ZFT0052-Vinod Pareek': 60,
  //     'ZFT0059-Sonu Soni': 39,
  //     'ZFT0066-Dilip Jaiman': 33,
  //     'ZFT0072-Pramod Sharma': null,
  //     'ZFT0397-Satender Panwar': 117,
  //     'ZFT0488-Akash Sharma': 54,
  //     'ZFT0503-Rajesh Negi': 195,
  //     'ZFT0528-Parvin Yadav': null,
  //     'ZFT0528-Praveen Yadav': 171,
  //     'ZFT0528-Pravin kumar Yadav': null
  //   }
  // }

  // const dataSourcePivot = Object.entries(pivotData).map(([key, values]) => {
  //   const [leadType, leadProduct] = key.slice(1, -1).split(', ')
  //   // const supervisorManager = Object.keys(values)[0]
  //   const supervisorManager = Object.keys(values)
  //   console.log('Supervisors Leads', supervisorManager)
  //   const data = Object.values(values)
  //   // const sum = data.reduce((accumulator, value) => {
  //   //   return accumulator + value
  //   // }, 0)
  //   // console.log('Sum pivot', sum)
  //   // return { leadType, leadProduct, supervisorManager, sum }
  //   return { leadType, leadProduct, supervisorManager, data }
  // })
  // console.log('Pivot frontend', dataSourcePivot)

  // const columnsPivot = [
  //   {
  //     title: 'Supervisor Manager',
  //     dataIndex: 'supervisorManager',
  //     key: 'supervisorManager'
  //   },
  //   ...Array.from(new Set(dataSourcePivot.map(item => item.leadType))).map(
  //     leadType => ({
  //       title: leadType,
  //       children: Array.from(
  //         new Set(
  //           dataSourcePivot
  //             .filter(item => item.leadType === leadType)
  //             .map(item => item.leadProduct)
  //         )
  //       ).map(leadProduct => ({
  //         title: leadProduct,
  //         dataIndex: 'data',
  //         key: `${leadType}-${leadProduct}`,
  //         render: (data, record) =>
  //           data
  //             .filter(
  //               (value, index) =>
  //                 record.leadType === leadType &&
  //                 record.leadProduct === leadProduct
  //             )
  //             .join(', ')
  //       }))
  //     })
  //   )
  // ]

  // Pivot

  const { authState, onLogout } = useAuth()

  var token_decoded = authState.token
  var jwtBearer = authState.token
  var jwt_payload = ''
  var jwt_payload_group = []
  if (jwtBearer) {
    console.log('Token decoded')
    jwt_payload = jwt_decode(jwtBearer)
    jwt_payload_group = jwt_payload.group_id
    console.log('JWT Group Reports - ', jwt_payload_group)
  }

  console.log('JWT Bearer Branch MIS - ', jwtBearer)

  useEffect(() => {
    dispatch(fetchLeads({ jwtBearer })).then(() => {
      setLoading(false) // Set loading state to false when the data is fetched
    })
  }, [])

  const state = useSelector(state => state)
  console.log('Leads State - ', state)

  const tokenExpired = state.leadsReports.data?.detail || ''
  const TOKEN_KEY = 'my-jwt'
  if (tokenExpired == 'Signature has expired.') {
    console.log('In token expired condition')
    AsyncStorage.removeItem(TOKEN_KEY)
    onLogout
    location.reload()
  }

  const allFields = state.leadsReports.data?.message || []
  const allBA = state.leadsReports.data?.total_ba || []
  const allBAWithLead = state.leadsReports.data?.ba_with_lead || []
  const ftmData = state.leadsReports.data?.ftm_data || []
  const updatedOn = state.leadsReports.data?.updated_on || ''
  const allFieldsPivot = state.leadsReports.data?.lead_pivot || {}

  console.log('Pivot Response', allFieldsPivot)

  console.log('Total BA', allBA)
  console.log('BA with lead', allBAWithLead)
  console.log('FTM Data', ftmData)
  // let allFieldsPivotJSON
  // const allFieldsPvotJSON = JSON.parse(allFieldsPivot)
  // if (Object.keys(allFieldsPivot).length > 0) {
  // useEffect(() => {
  //   setPivotDataSource(JSON.parse(allFieldsPivot))
  // }, [allFieldsPivot])
  // setPivotDataSource(allFieldsPivot)
  // allFieldsPivotJSON = JSON.parse(allFieldsPivot)
  // console.log('PIVOT', allFieldsPivotJSON)
  // }
  // console.log('PIVOT', allFieldsPivot)
  console.log('CMA Registered users Pandas - ', allFields)

  // const keys = state.leadsReports.data
  //   ? Object.keys(allFields['current_state'])
  //   : []
  // const keys = Object.keys(allFields['current_state'])

  const current_state = allFields['current_state']
    ? allFields['current_state']
    : []

  const keys = current_state ? Object.keys(current_state) : []

  // Create an array of objects in the desired format
  const objectsArray = keys.map(key => {
    const obj = {}
    for (const subKey in allFields) {
      const value = String(allFields[subKey][key])
        .toUpperCase()
        .replace(/NULL/gi, '')
      obj[subKey] = value
    }
    return obj
  })
  console.log('CMA Registered users JSON - ', objectsArray)

  useEffect(() => {
    setDataSource(objectsArray)
  }, [allFields])

  const allKeys = objectsArray.reduce((keys, record) => {
    return keys.concat(Object.keys(record))
  }, [])

  const uniqueKeys = [...new Set(allKeys)]

  const columns = uniqueKeys.map(key => ({
    title: <strong>{key.toUpperCase()}</strong>,
    dataIndex: key,
    key: key,
    width: '30%',
    align: 'left',
    filters: [...new Set(objectsArray.map(record => record[key]))].map(
      value => ({
        text: value,
        value: value
      })
    ),
    onFilter: (value, record) => record[key] === value,
    filterSearch: true
  }))
  console.log('Branch MIS Datasource - ', dataSource)

  // Convert Leads

  const allFieldsConvert = state.leadsReports.data?.convert_leads || []
  // }
  // console.log('CMA Registered users Pandas - ', allFieldsConvert)

  // const keysConvert = state.leadsReports.data
  //   ? Object.keys(allFieldsConvert['current_state'])
  //   : []

  const current_state_converted = allFieldsConvert['current_state']
    ? allFieldsConvert['current_state']
    : []

  const keysConvert = current_state_converted
    ? Object.keys(current_state_converted)
    : []

  // Create an array of objects in the desired format
  const objectsArrayConvert = keysConvert.map(key => {
    const obj = {}
    for (const subKey in allFieldsConvert) {
      // obj[subKey] = allFieldsConvert[subKey][key]
      const value = String(allFieldsConvert[subKey][key])
        .toUpperCase()
        .replace(/NULL/gi, '')
      obj[subKey] = value
    }
    return obj
  })
  console.log('CMA Registered users JSON convert- ', objectsArrayConvert)

  useEffect(() => {
    setConvertDataSource(objectsArrayConvert)
  }, [allFieldsConvert])

  const allKeysConvert = objectsArrayConvert.reduce((keys, record) => {
    return keys.concat(Object.keys(record))
  }, [])

  const uniqueKeysConvert = [...new Set(allKeysConvert)]

  const ConvertColumns = uniqueKeysConvert.map(key => ({
    title: <strong>{key.toUpperCase()}</strong>,
    dataIndex: key,
    key: key,
    width: '30%',
    align: 'left',
    filters: [...new Set(objectsArrayConvert.map(record => record[key]))].map(
      value => ({
        text: value,
        value: value
      })
    ),
    onFilter: (value, record) => record[key] === value,
    filterSearch: true
  }))

  // Convert Leads

  const paginationOptions = {
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    defaultPageSize: 10, // Initial page size
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
  }

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`

  return (
    <>
      <center>
        <div>
          {/* <Collapse defaultActiveKey={['1']} bordered={false}> */}
          <Collapse bordered={true}>
            {/* <Panel header='LEAD SUMMARY' key='1'> */}
            <Card
              layout='horizontal'
              bordered={true}
              className='criclebox tablespace mb-24'
              title={
                <>
                  <strong>LEAD SUMMARY TEST</strong>
                  {updatedOn && (
                    <span style={{ marginLeft: '10px', fontSize: '14px' }}>
                      <br />
                      <strong>Last Updated On</strong> - {updatedOn}
                    </span>
                  )}
                </>
              }
              centered
              style={{ width: '100%', marginTop: '20px' }}
              
            >
              <LeadsSummary
                allFieldsPivot={allFieldsPivot}
                allBA={allBA}
                allBAWithLead={allBAWithLead}
                ftmData={ftmData}
              />
            </Card>
            {/* <Card
              layout='horizontal'
              bordered={true}
              className='criclebox tablespace mb-24'
              title={
                <>
                  <strong>LEAD SUMMARY</strong>
                  {updatedOn && (
                    <span style={{ marginLeft: '10px', fontSize: '14px' }}>
                      <br />
                      <strong>Last Updated On</strong> - {updatedOn}
                    </span>
                  )}
                </>
              }
              centered
              style={{ width: '100%', marginTop: '20px' }}
              extra={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '12px'
                  }}
                ></div>
              }
            >
              <LeadsPivot allFieldsPivot={allFieldsPivot} />
            </Card> */}
            {/* </Panel> */}
            {/* <Divider orientation='left'>Large Size</Divider> */}
            <Divider orientation='left'></Divider>
            {/* <Collapse
            size='large'
            items={[
              {
                key: '1',
                label: 'This is large size panel header',
                children: <p>{text}</p>
              }
            ]}
          /> */}

            <Panel
              header={
                <div>
                  <strong>LEAD PIPELINE</strong>{' '}
                  <span className='expand-text'>
                    (Click here to expand or collapse)
                  </span>
                </div>
              }
              key='1'
              className='custom-panel'
            >
              <Card
                layout='horizontal'
                bordered={true}
                className='criclebox tablespace mb-24'
                title={
                  <>
                    LEAD PIPELINE
                    {updatedOn && (
                      <span style={{ marginLeft: '10px', fontSize: '14px' }}>
                        <br />
                        <strong>Last Updated On</strong> - {updatedOn}
                      </span>
                    )}
                  </>
                }
                centered
                style={{ width: '100%', marginTop: '20px' }}
                extra={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '12px'
                    }}
                  ></div>
                }
              >
                <div className='table-responsive'>
                  {loading ? (
                    <div className='spinner-container'>
                      <Spin size='large' />
                    </div>
                  ) : (
                    <Table
                      columns={columns}
                      dataSource={dataSource}
                      pagination={paginationOptions}
                      bordered
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                      }
                    />
                  )}
                </div>
              </Card>
            </Panel>
            {/* <Divider orientation='left'>Large Size</Divider> */}
            <Divider orientation='left'></Divider>
            <Panel
              header={
                <div>
                  <strong>CONVERTED LEAD</strong>{' '}
                  <span className='expand-text'>
                    (Click here to expand or collapse)
                  </span>
                </div>
              }
              key='2'
              className='custom-panel'
            >
              <Card
                layout='horizontal'
                bordered={true}
                className='criclebox tablespace mb-24'
                title={
                  <>
                    CONVERTED LEAD
                    {updatedOn && (
                      <span style={{ marginLeft: '10px', fontSize: '14px' }}>
                        <br />
                        <strong>Last Updated On</strong> - {updatedOn}
                      </span>
                    )}
                  </>
                }
                centered
                style={{ width: '100%', marginTop: '20px' }}
                extra={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '12px'
                    }}
                  ></div>
                }
              >
                <div className='table-responsive'>
                  {loading ? (
                    <div className='spinner-container'>
                      <Spin size='large' />
                    </div>
                  ) : (
                    <Table
                      columns={ConvertColumns}
                      dataSource={convertDataSource}
                      pagination={paginationOptions}
                      bordered
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                      }
                    />
                  )}
                </div>
              </Card>
            </Panel>
            {/* <LeadsPivot allFieldsPivot={allFieldsPivot} /> */}
            {/* <Card
            layout='horizontal'
            bordered={true}
            className='criclebox tablespace mb-24'
            title={<>LEAD</>}
            centered
            style={{ width: '100%', marginTop: '20px' }}
          >
            <div className='table-responsive'>
              {loading ? (
                <div className='spinner-container'>
                  <Spin size='large' />
                </div>
              ) : (
                <Table
                  columns={columnsPivot}
                  dataSource={dataSourcePivot}
                  pagination={paginationOptions}
                  bordered
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                  }
                />
              )}
            </div>
          </Card> */}
          </Collapse>
        </div>
      </center>
    </>
  )
}

export default Leads
