import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DIGITAL_JOURNEY_BASE_URL } from '../apiConfig'

export const fetchPhoneChat = createAsyncThunk(
    'fetchPhoneChat',
    async ({ jwtBearer, uuid }) => {
        const response = await fetch(`${DIGITAL_JOURNEY_BASE_URL}portal/get/user/phone/chat/?uuid=${uuid}`, {
            headers: {
                Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
            }
        })
        return response.json()
    }
)

const phoneChatSlice = createSlice({
    name: 'phoneChatSlice',
    initialState: {
        isLoading: false,
        data: null,
        isError: false
    },
    extraReducers: builder => {
        builder.addCase(fetchPhoneChat.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchPhoneChat.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        })
        builder.addCase(fetchPhoneChat.rejected, (state, action) => {
            console.log('Error', action.payload)
            state.isError = true
        })
    }
})

export default phoneChatSlice.reducer
