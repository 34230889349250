import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../apiConfig'

export const fetchGroupDetails = createAsyncThunk(
  'fetchGroupDetails',
  async () => {
    const response = await fetch(`${BASE_URL}admin/group/details`, {})
    return response.json()
  }
  //   async ({ jwtBearer }) => {
  //     const response = await fetch('http://127.0.0.1:8000/admin/group/details', {
  //       headers: {
  //         Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
  //       }
  //     })
  //     return response.json()
  //   }
)

const groupDetailsSlice = createSlice({
  name: 'groupDetails',
  initialState: {
    isLoading: false,
    data: null,
    isError: false
  },
  extraReducers: builder => {
    builder.addCase(fetchGroupDetails.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchGroupDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(fetchGroupDetails.rejected, (state, action) => {
      console.log('Error', action.payload)
      state.isError = true
    })
  }
})

export default groupDetailsSlice.reducer
