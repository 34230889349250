import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../apiConfig'

export const unlockPortalUsers = createAsyncThunk(
  'unlockPortalUsers',
  async ({ jwt_token, userid }) => {
    console.log('Request Body - ', userid)
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwt_token
      },
      body: JSON.stringify({ userid })
    }

    try {
      const response = await fetch(
        `${BASE_URL}admin/unlock/user/`,
        options
      )
      console.log('Request Options - ', options)
      console.log('REsponse -- ', response)
      return response.data
    } catch (error) {
      console.log('Error', error.response.data)
      alert(error.response.data.message)
      throw error.response.data
    }
  }
)

const unlockPortalUsersSlice = createSlice({
  name: 'unlockPortalUsers',
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: null
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(unlockPortalUsers.pending, state => {
      state.isLoading = true
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(unlockPortalUsers.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(unlockPortalUsers.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.errorMessage = action.error.message
    })
  }
})

export default unlockPortalUsersSlice.reducer
