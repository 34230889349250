import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { useAuth } from "../../../context/AuthContext";
import { fetchInventory } from "../../../redux/slice/getInventorySlice";
import { uploadInventory } from "../../../redux/slice/uploadInventory";
import { updateInventory } from "../../../redux/slice/updateInventory";
import { assignAssetToUser } from "../../../redux/slice/assignAssetToUser";
import { deleteInventory } from "../../../redux/slice/deleteInventory";
import "../../../../assets/styles/buttons.css";
import {
  Spin,
  Table,
  Card,
  Modal,
  Button,
  Input,
  Tabs,
  Upload,
  message,
  Tooltip,
  Radio,
  Alert,
  Form,
  Row,
  Col,
  Select,
} from "antd";
import {
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  FileExcelOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { StyledCSVLink, StyledTable } from '../styleComp'
import { HashLoader } from "react-spinners";

const MISComp = (props) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [dataSource, setDataSource] = useState([]);
  const [summaryDataSource, setSummaryDataSource] = useState([]);
  const [trailDataSource, setTrailDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [uploadAlert, setUploadAlert] = useState("");
  const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(null);
  const [fileList, setFileList] = useState([]); // To store the uploaded file
  const [branchFieldError, setBranchFieldError] = useState(false);
  const [uploadFailedError, setUploadFailedError] = useState(false);
  const [invalidFormatError, setInvalidFormatError] = useState(false);
  const [duplicateHeadersError, setDuplicateHeadersError] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [inventoryUpdateModalVisible, setInventoryUpdateModalVisible] =
    useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [assetType, setAssetType] = useState("");
  const [assetStatus, setAssetStatus] = useState("");
  const [assetID, setAssetID] = useState("");
  const [make, setMake] = useState("");
  const [assetModel, setAssetModel] = useState("");
  const [owner, setOwner] = useState("");
  const [osCDKey, setOsCDKey] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [location, setLocation] = useState("");
  const [department, setDepartment] = useState("");
  const [assetCode, setAssetCode] = useState("");
  const [assetCategory, setAssetCategory] = useState("");
  const [remarks, setRemarks] = useState("");
  const [assignAssetModalVisible, setAssignAssetModalVisible] = useState(false);
  const [deleteAssetModalVisible, setDeleteAssetModalVisible] = useState(false);
  const [employeeToAssign, setEmployeeToAssign] = useState("");
  const [designation, setDesignation] = useState("");
  const [requestBody, setRequestBody] = useState({});
  const [assignType, setAssignType] = useState("");
  const [empEmail, setEmpEmail] = useState("");
  var prateekKaVariable = false;

  const { authState, onLogout } = useAuth();

  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var jwt_payload_group = [];
  var jwt_payload_expiry = [];
  if (jwtBearer) {
    console.log("Token decoded");
    jwt_payload = jwt_decode(jwtBearer);
    // jwt_payload_group = jwt_payload.group_id;
    jwt_payload_group = props.userDetails?.group_id;
    jwt_payload_expiry = jwt_payload.exp;
  }

  // Session Timeout
  const TOKEN_KEY = "my-jwt";
  var currentTime = Math.floor(Date.now() / 1000);

  // Compare current time with token expiry time
  if (jwt_payload_expiry.length != 0) {
    if (currentTime > jwt_payload_expiry) {
      console.log("In token expired condition");
      // Token has expired, perform logout
      AsyncStorage.removeItem(TOKEN_KEY);
      onLogout();
      window.location.reload();
    }
  }
  // Session Timeout

  console.log("JWT Bearer Branch MIS - ", jwtBearer);

  useEffect(() => {
    dispatch(fetchInventory({ type: "IT", jwtBearer })).then(() => {
      setLoading(false); // Set loading state to false when the data is fetched
    });
  }, []);

  const state = useSelector((state) => state);
  console.log("MIS State - ", state);

  const tokenExpired = state.inventory.data?.detail || "";
  // const TOKEN_KEY = "my-jwt";
  if (tokenExpired == "Signature has expired.") {
    console.log("In token expired condition");
    AsyncStorage.removeItem(TOKEN_KEY);
    onLogout;
    location.reload();
  }

  var token_decoded = authState.token;
  console.log("Main Token - ", token_decoded);
  var jwt_payload = "";
  var jwt_payload_username = "";
  var jwt_payload_userid = "";
  var jwt_role = "";
  if (token_decoded) {
    jwt_payload = jwt_decode(token_decoded);
    console.log("JWT Payload Main", jwt_payload);
    jwt_payload_username = jwt_payload.username;
    jwt_payload_userid = jwt_payload.user_id;
    jwt_role = jwt_payload.role;
    console.log("JWT Group Main", jwt_payload_username);
  }

  const allFields = state.inventory.data?.message || [];
  const allFieldsSummary = state.inventory.data?.summary_data || [];
  const allFieldsTrail = state.inventory.data?.trail_data || [];
  const updated_date = state.inventory.data?.updated_date || [];
  const updated_time = state.inventory.data?.updated_time || [];
  // const empData = state.inventory.data?.emp_data.employee || [];
  const empData = state.inventory.data?.emp_data || [];
  const employeeData = empData && empData.employee ? empData.employee : {};

  // console.log("IT inventory emp data - ", employeeData);
  console.log("IT inventory all data - ", allFields);

  // Updated
  const keysAll = allFields["asset_type"]
    ? Object.keys(allFields["asset_type"])
    : [];

  const objectsArray = keysAll.map((key) => {
    const obj = {};
    for (const subKey in allFields) {
      obj[subKey] = allFields[subKey][key];
    }
    return obj;
  });

  const keysAllSummary = allFieldsSummary["asset_type"]
    ? Object.keys(allFieldsSummary["asset_type"])
    : [];

  const objectsArraySummary = keysAllSummary.map((key) => {
    const obj = {};
    for (const subKey in allFieldsSummary) {
      obj[subKey] = allFieldsSummary[subKey][key];
    }
    return obj;
  });

  const keysAllTrail = allFieldsTrail["asset_id"]
    ? Object.keys(allFieldsTrail["asset_id"])
    : [];

  const objectsArrayTrail = keysAllTrail.map((key) => {
    const obj = {};
    for (const subKey in allFieldsTrail) {
      obj[subKey] = allFieldsTrail[subKey][key];
    }
    return obj;
  });

  console.log("IT inventory all data object - ", objectsArray);

  useEffect(() => {
    setDataSource(objectsArray);
    setSummaryDataSource(objectsArraySummary);
    setTrailDataSource(objectsArrayTrail);
    setLastUpdatedDate(updated_date);
    setLastUpdatedTime(updated_time);
  }, [allFields]);

  const allKeys = objectsArray.reduce((keys, record) => {
    return keys.concat(Object.keys(record));
  }, []);

  const allKeyssummary = objectsArraySummary.reduce((keys, record) => {
    return keys.concat(Object.keys(record));
  }, []);

  const allKeysTrail = objectsArrayTrail.reduce((keys, record) => {
    return keys.concat(Object.keys(record));
  }, []);

  const uniqueKeys = [...new Set(allKeys)];
  const uniqueKeysSummary = [...new Set(allKeyssummary)];
  const uniqueKeysTrail = [...new Set(allKeysTrail)];

  const totalColumns = uniqueKeys.length;
  const equalColumnWidth = 100; // Set your desired width here

  const columnsInventory = [
    ...uniqueKeys.map((key) => ({
      title: <strong style={{ fontSize: "18px" }}>{key.toUpperCase()}</strong>,
      dataIndex: key,
      key: key,
      // width: '30%',
      width: totalColumns > 0 ? `${100 / totalColumns}%` : equalColumnWidth,
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: [...new Set(objectsArray.map((record) => record[key]))].map(
        (value) => ({
          text: value,
          value: value,
        })
      ),
      onFilter: (value, record) => record[key] === value,
      filterSearch: true,
    })),
    {
      title: <strong style={{ fontSize: "18px" }}>ASSIGN/REASSIGN ASSET</strong>,
      dataIndex: "unlockAccount",
      key: "unlockAccount",
      width: "20%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      render: (text, record) => {
        if (record.asset_status == "Stock" && record.asset_type == "Laptop") {
          return (
            <a
              style={{ color: "dodgerBlue" }}
              onClick={() => handleAssignAsset(record, "Assign")}
            >
              Assign
            </a>
          );
        } else if (
          record.asset_status == "Production" &&
          record.asset_type == "Laptop"
        ) {
          return (
            <a
              style={{ color: "dodgerBlue" }}
              onClick={() => handleAssignAsset(record, "Reassign")}
            >
              Reassign
            </a>
          );
        }
        return null;
      },
    },
    {
      title: <strong style={{ fontSize: "18px" }}>DELETE ASSET</strong>,
      dataIndex: "deleteAsset",
      key: "deleteAsset",
      width: "20%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      render: (text, record) => {
        return (
          <a
            style={{ color: "dodgerBlue" }}
            onClick={() => handleDeleteAsset(record, "Delete")}
          >
            Delete
          </a>
        );
        // return null;
      },
    },
  ];

  const columnsInventorySummary = [
    ...uniqueKeysSummary.map((key) => ({
      title: <strong style={{ fontSize: "18px" }}>{key.toUpperCase()}</strong>,
      dataIndex: key,
      key: key,
      // width: '30%',
      width: totalColumns > 0 ? `${100 / totalColumns}%` : equalColumnWidth,
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      // filters: [
      //   ...new Set(objectsArraySummary.map((record) => record[key])),
      // ].map((value) => ({
      //   text: value,
      //   value: value,
      // })),
      // onFilter: (value, record) => record[key] === value,
      // filterSearch: true,
    })),
  ];

  const columnsInventoryTrail = [
    ...uniqueKeysTrail.map((key) => ({
      title: <strong style={{ fontSize: "18px" }}>{key.toUpperCase()}</strong>,
      dataIndex: key,
      key: key,
      // width: '30%',
      width: totalColumns > 0 ? `${100 / totalColumns}%` : equalColumnWidth,
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: [...new Set(objectsArrayTrail.map((record) => record[key]))].map(
        (value) => ({
          text: value,
          value: value,
        })
      ),
      onFilter: (value, record) => record[key] === value,
      filterSearch: true,
    })),
  ];

  // Updated

  // console.log("Branch MIS All Fields - ", allFields);

  // console.log("Branch MIS Datasource - ", dataSource);

  const allowedExtensions = ["xlsx"];
  const handleFileChange = (info) => {
    console.log("In handle file change function");
    console.log("info", info);
    const fileExtension = info.file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      setInvalidFormatError(true);
      setFileList([]);
      return;
    }
    setFileList(info.fileList);
  };

  const handleUpload = async () => {
    setButtonLoading(true);
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });

          // Assuming the first sheet is the one you want to convert to JSON
          let sheetNum = 0;
          // if (props.reportType == "DAILY STATUS") {
          //   sheetNum = 1;
          // }

          const sheet = workbook.Sheets[workbook.SheetNames[sheetNum]];
          const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          // Extract the headers (first sub-array)
          const headers = excelData[0];
          const headersUpper = headers.map((element) => {
            return element.toUpperCase();
          });

          if (
            headersUpper.includes(
              "COMPUTER STATUS",
              "COMPUTER ID TAG",
              "COMPUTER MODEL",
              "MAKE",
              "OWNER",
              "OS CD KEY",
              "Service Tag/Serial Number",
              "LOCATION",
              "DEPARTMENT",
              "ASSET_CODE",
              "ASSET_CATEGORY",
              "ASSET_TYPE",
              "PURCHASE DATE",
              "PURCHASE PRICE",
              "VENDOR",
              "AMC/SUPPORT EXPIRY",
              "REMARKS"
            )
          ) {
            const headersSet = new Set(headersUpper);
            if (headersSet.size !== headersUpper.length) {
              // Duplicate headers found
              console.error("Duplicate headers found");
              setDuplicateHeadersError(true);
              setFileList([]);
              setButtonLoading(false);
              return; // Exit the function
            }
            // Create an array of objects using headers as keys and data as values
            const formattedData = excelData.slice(1).map((row) => {
              const obj = {};
              headersUpper.forEach((header, index) => {
                // obj[header] = row[index];
                obj[header] = row[index] || "";
              });
              return obj;
            });

            // File size
            const jsonString = JSON.stringify(formattedData);

            // Calculate the size of the JSON string in bytes
            const fileSizeInBytes = new Blob([jsonString]).size;

            // Convert the size to kilobytes (KB) and megabytes (MB)
            const fileSizeInKB = fileSizeInBytes / 1024;
            // const fileSizeInMB = fileSizeInKB / 1024
            const fileSizeInMB = Math.floor(fileSizeInKB / 1024);
            console.log("Size number", fileSizeInMB);

            // Log the file size
            console.log(
              `File size: ${fileSizeInBytes} bytes (${fileSizeInKB} KB, ${fileSizeInMB} MB)`
            );

            const chunkNumber = parseInt(fileSizeInMB + 1);
            // File size

            const chunkSize = Math.ceil(formattedData.length / chunkNumber);
            const chunks = [];
            for (let i = 0; i < formattedData.length; i += chunkSize) {
              chunks.push(formattedData.slice(i, i + chunkSize));
            }

            // Call uploadOpsMIS API for each chunk with different uploadType
            for (let i = 0; i < chunks.length; i++) {
              const response = await dispatch(
                uploadInventory({
                  jwt_token: authState.token,
                  type: "IT",
                  requestBody: chunks[i],
                })
              );

              console.log(`File upload response (Chunk ${i + 1}):`, response);
            }

            // message.success('File uploaded successfully')
            setBranchFieldError(false);
            setUploadFailedError(false);
            setInvalidFormatError(false);
            setDuplicateHeadersError(false);
            setUploadSuccess(true);

            // After successful file processing, await the dispatch and then execute the rest of the code
            await dispatch(fetchInventory({ type: "IT", jwtBearer }));
            setLoading(false);
            setButtonLoading(false);
            setFileList([]); // Clear the uploaded file list
          } else {
            setUploadFailedError(false);
            setInvalidFormatError(false);
            setDuplicateHeadersError(false);
            setUploadSuccess(false);
            setBranchFieldError(true);
            setFileList([]);
            setButtonLoading(false);
          }
        } catch (error) {
          console.error("Error:", error);
          //   message.error('File upload failed')
          setInvalidFormatError(false);
          setDuplicateHeadersError(false);
          setUploadSuccess(false);
          setBranchFieldError(true);
          setUploadFailedError(true);
          setFileList([]);
          setButtonLoading(false);
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const paginationOptions = {
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
    defaultPageSize: 10, // Initial page size
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  const customRequest = ({ file, onSuccess }) => {
    // Prevent the default behavior by calling onSuccess immediately
    onSuccess("ok");
  };

  const onRowClick = async (record) => {
    if (prateekKaVariable == false) {
      console.log("in on row click - ", record);
      setSelectedRecord(record);
      setInventoryUpdateModalVisible(true);
      setAssetType(record.asset_type);
      setAssetStatus(record.asset_status);
      setAssetID(record.asset_id);
      setMake(record.make);
      setMake(record.make);
      setAssetModel(record.asset_model);
      setAssetModel(record.asset_model);
      setOsCDKey(record.os_cd_key);
      setSerialNumber(record.serial_number);
      setLocation(record.location);
      setDepartment(record.department);
      setAssetCode(record.asset_code);
      setAssetCategory(record.asset_category);
      setRemarks(record.remarks);
    }
  };

  const handleModalClose = () => {
    setInventoryUpdateModalVisible(false);
    setAssignAssetModalVisible(false);
    setDeleteAssetModalVisible(false);
    setEmployeeToAssign("");
    setDesignation("");
    setLocation("");
  };

  // const handleInputChange = (key, value) => {
  //   console.log("Input change key - ", key);
  //   console.log("Input change value - ", value);
  //   const updatedRequestBody = { ...requestBody };

  //   // Update the value of the corresponding key in the requestBody
  //   updatedRequestBody[key] = value;

  //   console.log("Updated request body - ", updatedRequestBody);
  //   // Set the updated requestBody
  //   setRequestBody(updatedRequestBody);
  // };

  // const renderInputFields = () => {
  //   console.log("Selected Records - ", selectedRecord);
  //   if (!selectedRecord) return null; // Ensure a record is selected

  //   return Object.keys(selectedRecord).map((key) => (
  //     <div key={key} style={{ marginBottom: "10px" }}>
  //       {/* <label htmlFor={key}>{key}</label> */}
  //       <Form layout="vertical">
  //         <Form.Item label={<strong style={{fontSize:"18px"}}>{key}</strong>}>
  //           <Input
  //             id={key}
  //             type="text"
  //             value={selectedRecord[key]}
  //             onChange={(e) => handleInputChange(key, e.target.value)}
  //           />
  //         </Form.Item>
  //       </Form>
  //     </div>
  //   ));
  // };

  const renderInputFields = () => {
    console.log("Selected Records - ", selectedRecord);
    if (!selectedRecord) return null; // Ensure a record is selected

    return (
      <div style={{ marginBottom: "10px" }}>
        <Form layout="vertical">
          <Form.Item label={<strong style={{ fontSize: "18px" }}>ASSET TYPE</strong>}>
            <Input
              id="assetType"
              type="text"
              value={assetType}
              onChange={(e) => setAssetType(e.target.value)}
            />
          </Form.Item>
          <Form.Item label={<strong style={{ fontSize: "18px" }}>ASSET STATUS</strong>}>
            <Select
              id="assetStatus"
              value={assetStatus}
              onChange={(value) => setAssetStatus(value)}
            >
              <Option value="Production">Production</Option>
              <Option value="Stock">Stock</Option>
              <Option value="Faulty">Faulty</Option>
              <Option value="Damaged">Damaged</Option>
              <Option value="In Repair">In Repair</Option>
              <Option value="Missing">Missing</Option>
              <Option value="Scrap">Scrap</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label={<strong style={{fontSize:"18px"}}>ASSET STATUS</strong>}>
            <Input
              id="assetStatus"
              type="text"
              value={assetStatus}
              onChange={(e) => setAssetStatus(e.target.value)}
            />
          </Form.Item> */}
          <Form.Item label={<strong style={{ fontSize: "18px" }}>ASSET ID</strong>}>
            <Input
              id="assetID"
              type="text"
              value={assetID}
              onChange={(e) => setAssetID(e.target.value)}
            />
          </Form.Item>
          <Form.Item label={<strong style={{ fontSize: "18px" }}>MAKE</strong>}>
            <Input
              id="make"
              type="text"
              value={make}
              onChange={(e) => setMake(e.target.value)}
            />
          </Form.Item>
          <Form.Item label={<strong style={{ fontSize: "18px" }}>ASSET MODEL</strong>}>
            <Input
              id="assetModel"
              type="text"
              value={assetModel}
              onChange={(e) => setAssetModel(e.target.value)}
            />
          </Form.Item>
          <Form.Item label={<strong style={{ fontSize: "18px" }}>OS CD KEY</strong>}>
            <Input
              id="osCDKey"
              type="text"
              value={osCDKey}
              onChange={(e) => setOsCDKey(e.target.value)}
            />
          </Form.Item>
          <Form.Item label={<strong style={{ fontSize: "18px" }}>SERIAL NUMBER</strong>}>
            <Input
              id="serialNumber"
              type="text"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
            />
          </Form.Item>
          <Form.Item label={<strong style={{ fontSize: "18px" }}>LOCATION</strong>}>
            <Input
              id="location"
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </Form.Item>
          <Form.Item label={<strong style={{ fontSize: "18px" }}>DEPARTMENT</strong>}>
            <Input
              id="department"
              type="text"
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
            />
          </Form.Item>
          <Form.Item label={<strong style={{ fontSize: "18px" }}>ASSET CODE</strong>}>
            <Input
              id="assetCode"
              type="text"
              value={assetCode}
              onChange={(e) => setAssetCode(e.target.value)}
            />
          </Form.Item>
          <Form.Item label={<strong style={{ fontSize: "18px" }}>ASSET CATEGORY</strong>}>
            <Input
              id="assetCategory"
              type="text"
              value={assetCategory}
              onChange={(e) => setAssetCategory(e.target.value)}
            />
          </Form.Item>
          <Form.Item label={<strong style={{ fontSize: "18px" }}>REMARKS</strong>}>
            <Input
              id="remarks"
              type="text"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Form.Item>
        </Form>
      </div>
    );
  };

  const handleUpdateInventory = async () => {
    const requestBody = {
      asset_type: assetType ? assetType : "",
      asset_status: assetStatus ? assetStatus : "",
      asset_id: assetID ? assetID : "",
      make: make ? make : "",
      asset_model: assetModel ? assetModel : "",
      os_cd_key: osCDKey ? osCDKey : "",
      serial_number: serialNumber ? serialNumber : "",
      location: location ? location : "",
      department: department ? department : "",
      asset_code: assetCode ? assetCode : "",
      asset_category: assetCategory ? assetCategory : "",
      remarks: remarks ? remarks : "",
    };

    const response = await dispatch(
      updateInventory({ jwt_token: authState.token, requestBody })
    );
    if (response.payload?.code == 200) {
      message.success("Asset deails updated successfully");
    }
    console.log("Response update inventory - ", response);
    // form.resetFields();
    dispatch(fetchInventory({ type: "IT", jwtBearer }));
    setInventoryUpdateModalVisible(false);
  };

  const handleAssignAsset = async (e, assignType) => {
    setAssetID(e.asset_id);
    setAssignType(assignType);
    setSerialNumber(e.serial_number);
    prateekKaVariable = true;
    setAssignAssetModalVisible(true);
    setTimeout(() => {
      prateekKaVariable = false;
    }, 1000); // Delay rendering by 500ms
  };

  const handleDeleteAsset = async (e, assignType) => {
    setAssetID(e.asset_id);
    setAssignType(assignType);
    setSerialNumber(e.serial_number);
    prateekKaVariable = true;
    setDeleteAssetModalVisible(true);
    setTimeout(() => {
      prateekKaVariable = false;
    }, 1000); // Delay rendering by 500ms
  };

  const handleEmployeeChange = (emp) => {
    console.log("Selected employee - ", emp);
    setEmployeeToAssign(empData.employee[emp]);
    setDesignation(empData.emp_designation[emp]);
    setLocation(empData.emp_location[emp]);
    setEmpEmail(empData.Emp_Email[emp]);
  };

  const handleAssignToEmployee = async () => {
    console.log("Employee to assign  - ", employeeToAssign);
    const requestBody = {
      // assigned_by: jwt_payload_userid,
      assigned_by: jwt_payload_userid + " - " + jwt_payload_username,
      assigned_to: employeeToAssign,
      asset_id: assetID,
      emp_designation: designation,
      emp_location: location,
      emp_email: empEmail,
      serial_number: serialNumber,
      assign_type: assignType,
    };

    const response = await dispatch(
      assignAssetToUser({ jwt_token: authState.token, requestBody })
    );
    if (response.payload?.code == 200) {
      message.success("Asset assigned/reassigned successfully");
    }
    console.log("Assign asset response - ", response);
    await dispatch(fetchInventory({ type: "IT", jwtBearer }));
    setAssignAssetModalVisible(false);
  };

  const handleDeleteAssetEntry = async () => {
    const requestBody = {
      asset_id: assetID,
      serial_number: serialNumber,
    };

    const response = await dispatch(
      deleteInventory({ jwt_token: authState.token, requestBody })
    );

    if (response.payload?.code == 200) {
      message.success("Asset deleted successfully");
    }
    await dispatch(fetchInventory({ type: "IT", jwtBearer }));
    setDeleteAssetModalVisible(false);
  };

  const handleTemplateDownload = () => {
    try {
      // Create workbook
      const workbook = XLSX.utils.book_new();

      // Add worksheet
      const worksheet = XLSX.utils.aoa_to_sheet([
        [
          "Computer Status",
          "Computer ID Tag",
          "Computer Model",
          "Make",
          "Owner",
          "OS CD Key",
          "Service Tag/Serial Number",
          "Location",
          "Department",
          "Asset_Code",
          "Asset_Category",
          "Asset_Type",
          "Purchase Date",
          "Purchase Price",
          "Vendor",
          "AMC/Support Expiry",
          "Remarks",
        ],
        [
          "Production",
          "ZFT010001",
          "Latitude E5470",
          "Dell",
          "RamNiwas(ZFT0849)",
          "MK3K4-KNFD4-6YRFC-WKY76-TJF9G",
          "8WPYVD2",
          "10001-Jobner",
          "Distribution",
          "ZFT01",
          "Hardware",
          "Laptop",
          "12-Feb-2024",
          "24000",
          "Veer Traders",
          "12-Feb-2026",
          "Remarks",
        ],
      ]);

      // Add worksheet to workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Convert workbook to blob
      const wbBlob = new Blob(
        [XLSX.write(workbook, { type: "array", bookType: "xlsx" })],
        { type: "application/octet-stream" }
      );

      // Save workbook as file
      FileSaver.saveAs(wbBlob, "IT_Inventory.xlsx");
    } catch (error) {
      console.error("Error generating template:", error);
    }
  };

  return (
    <>
      <center>
        <div>
          {jwt_payload_group == "0" ||
            jwt_payload_group == "1" ||
            jwt_payload_group == "2" ||
            jwt_payload_group == "3" ||
            jwt_payload_group == "4" ? null : (
            <>
              <Row gutter={14} style={{ width: "100%" }} justify="center">
                <Col xxl={10}
                  xl={10}
                  lg={10}
                  sm={24}
                  xs={24}>
                  <Card
                    style={{
                      // width: "40%",
                      marginTop: "20px",
                      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", // Add the box-shadow property
                      borderRadius: "5px", // Add rounded corners for a more aesthetic look
                      minHeight: 600,
                    }}
                    bordered={true}
                  >
                    <center>
                      <div>
                        <p>
                          <strong style={{ fontSize: "18px" }}>
                            <FileExcelOutlined /> UPLOAD INVENTORY
                          </strong>
                        </p>
                        <Upload
                          customRequest={customRequest}
                          name="avatar" // Set the name attribute to "avatar"
                          fileList={fileList}
                          listType="picture-card" // Set the listType to "picture-card"
                          onChange={handleFileChange}
                          maxCount={1}
                        >
                          {fileList.length === 0 && (
                            <div>
                              <UploadOutlined />
                              <div style={{ marginTop: 8 }}>Select File</div>
                            </div>
                          )}
                        </Upload>
                        <Button

                          style={{
                            color: "white",
                            fontSize: "18px",
                            padding: "20px 20px",
                            borderRadius: "5px",
                            display: "inline-flex",
                            alignItems: "center",
                            fontWeight: "bold",
                            marginTop: "20px",
                            borderRadius: "30px",
                            background: "linear-gradient(90deg, #ff512f, #dd2476)"
                          }}
                          onClick={handleUpload}
                          loading={buttonLoading}
                          disabled={fileList.length === 0}
                        >
                          Upload
                        </Button>
                        {uploadFailedError === false ? null : (
                          <Alert
                            style={{
                              marginTop: "20px",
                              textAlign: "justify",
                              width: "50%",
                            }}
                            message="Error"
                            description="File Upload Failed."
                            type="error"
                            showIcon
                          />
                        )}
                        {invalidFormatError == false ? null : (
                          <Alert
                            style={{
                              marginTop: "20px",
                              textAlign: "justify",
                              width: "50%",
                            }}
                            message="Error"
                            description="Invalid file format. Allowed formats: xlsx"
                            type="error"
                            showIcon
                          />
                        )}
                        {duplicateHeadersError == false ? null : (
                          <Alert
                            style={{
                              marginTop: "20px",
                              textAlign: "justify",
                              width: "50%",
                            }}
                            message="Error"
                            description="File contains duplicate columns"
                            type="error"
                            showIcon
                          />
                        )}
                        {uploadSuccess == false ? null : (
                          <Alert
                            style={{
                              marginTop: "20px",
                              textAlign: "justify",
                              width: "50%",
                            }}
                            message="Success"
                            description="File Uploaded Successfully"
                            type="success"
                            showIcon
                          />
                        )}
                        {branchFieldError === false ? null : (
                          <Alert
                            style={{
                              marginTop: "20px",
                              textAlign: "justify",
                              width: "50%",
                            }}
                            message="Error"
                            description="Make sure that the excel sheet is in the same format as per the sample template."
                            type="error"
                            showIcon
                          />
                        )}
                        <div
                          style={{
                            textAlign: "justify",
                          }}
                        >
                          <p>
                            <strong style={{ fontSize: "18px" }}>Instructions to be followed -</strong>
                          </p>
                          <p style={{ fontSize: "17px" }}>1. The extension of the file should be 'xlsx'.</p>
                          <p style={{ fontSize: "17px" }}>
                            2. The excel sheet should not be having any formula
                            in any of the cells and cells should only have
                            values in it.
                          </p>
                          <p style={{ fontSize: "17px" }}>
                            3. Download sample template using this link -{" "}
                            <a
                              style={{ color: "dodgerBlue" }}
                              onClick={() => handleTemplateDownload()}
                            >
                              Sample Template
                            </a>
                          </p>
                        </div>

                        {uploadAlert && (
                          <div style={{ marginTop: "10px" }}>{uploadAlert}</div>
                        )}
                      </div>
                    </center>
                  </Card>
                </Col>
                <Col
                  xxl={10}
                  xl={10}
                  lg={10}
                  sm={24}
                  xs={24}
                >
                  <Card
                    layout="horizontal"
                    style={{
                      // width: "40%",
                      marginTop: "20px",
                      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", // Add the box-shadow property
                      borderRadius: "5px", // Add rounded corners for a more aesthetic look
                      height: 600,
                    }}
                    bordered={true}
                    className="criclebox tablespace mb-24"
                    title={<>SUMMARY</>}
                    centered
                  >
                    <div className="table-responsive">
                      {loading ? (
                        <div className="spinner-container">
                          <HashLoader color="#f57171" />
                        </div>
                      ) : (
                        <StyledTable
                          columns={columnsInventorySummary}
                          dataSource={summaryDataSource}
                          pagination={false}
                          bordered
                        // rowClassName={(record, index) =>
                        //   index % 2 === 0
                        //     ? "table-row-light"
                        //     : "table-row-dark"
                        // }
                        />
                      )}
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <Card
            layout="horizontal"
            bordered={true}
            className="criclebox tablespace mb-24"
            title={
              <>
                {/* {props.reportType} ASSET INVENTORY */}
                ASSET INVENTORY
                {lastUpdatedDate && lastUpdatedTime && (
                  <span style={{ marginLeft: "10px", fontSize: "14px" }}>
                    <br />
                    <strong style={{ fontSize: "16px" }}>DATE - {lastUpdatedDate}</strong> &nbsp;
                    <strong style={{ fontSize: "16px" }}>TIME - {lastUpdatedTime} (24 HRS)</strong>
                  </span>
                )}
              </>
            }
            centered
            style={{ width: "100%", marginTop: "20px" }}
            extra={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                <StyledCSVLink

                  data={dataSource}
                  onClick={() => { }}
                >
                  Download Report
                </StyledCSVLink>
              </div>
            }
          >
            <div className="table-responsive">
              {loading ? (
                <div className="spinner-container">
                  <HashLoader color="#f57171" />
                </div>
              ) : (
                <StyledTable
                  columns={columnsInventory}
                  dataSource={dataSource}
                  pagination={paginationOptions}
                  bordered
                  // rowClassName={(record, index) =>
                  //   index % 2 === 0
                  //     ? "table-row-light table-row-clickable"
                  //     : "table-row-dark table-row-clickable"
                  // }
                  onRow={(record) => ({
                    onClick: () => onRowClick(record),
                  })}
                />
              )}
            </div>
          </Card>

          <Card
            layout="horizontal"
            bordered={true}
            className="criclebox tablespace mb-24"
            title={
              <>
                {/* {props.reportType} INVENTORY TRAIL */}
                INVENTORY TRAIL
                {/* {lastUpdatedDate && lastUpdatedTime && (
                  <span style={{ marginLeft: "10px", fontSize: "14px" }}>
                    <br />
                    <strong style={{fontSize:"18px"}}>DATE</strong> - {lastUpdatedDate} &nbsp;
                    <strong style={{fontSize:"18px"}}>TIME</strong> - {lastUpdatedTime} (24 HRS)
                  </span>
                )} */}
              </>
            }
            centered
            style={{ width: "100%", marginTop: "20px" }}
            extra={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                <StyledCSVLink

                  data={trailDataSource}
                  onClick={() => { }}
                >
                  Download Report
                </StyledCSVLink>
              </div>
            }
          >
            <div className="table-responsive">
              {loading ? (
                <div className="spinner-container">
                  <HashLoader color="#f57171" />
                </div>
              ) : (
                <StyledTable
                  columns={columnsInventoryTrail}
                  dataSource={trailDataSource}
                  pagination={paginationOptions}
                  bordered
                // rowClassName={(record, index) =>
                //   index % 2 === 0 ? "table-row-light" : "table-row-dark"
                // }
                />
              )}
            </div>
          </Card>

          <Modal
            visible={inventoryUpdateModalVisible}
            onCancel={handleModalClose}
            footer={null}
            width={1000}
            height={500}
          >
            {/* <div style={{ marginLeft: 15, overflowY: "auto", height: "100%" }}> */}
            <div>
              <h2>ASSIGN ASSET</h2>
              {/* <div style={{ overflowY: "auto", height: "60%" }}> */}
              {/* <Row style={{ width: "100%", height: "100%" }}> */}
              <Row style={{ width: "100%" }}>
                <Col
                  flex={10}
                  // style={{ marginLeft: 15, overflowY: "auto", height: "100%" }}
                  style={{ marginLeft: 15, overflowY: "auto" }}
                >
                  {renderInputFields()}
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <Button onClick={handleModalClose}>Close</Button>
                    <Button
                      type="primary"
                      style={{ marginLeft: "10px" }}
                      onClick={handleUpdateInventory}
                    >
                      Update
                    </Button>
                  </div>
                </Col>
              </Row>
              {/* </div> */}
            </div>
          </Modal>
          <Modal
            visible={assignAssetModalVisible}
            onCancel={handleModalClose}
            footer={null}
            width={1000}
          >
            {/* <div style={{ marginLeft: 15, overflowY: "auto", height: "100%" }}> */}
            <div>
              <h2>ASSIGN ASSET</h2>

              <Form
                layout="vertical" // Set the form layout to vertical
                style={{
                  marginTop: "20px",
                  width: 500,
                  marginLeft: "20px",
                  marginRight: "20px",
                  marginBottom: "20px",
                }}
              >
                <Form.Item label={<strong style={{ fontSize: "18px" }}>SELECT EMPLOYEE :</strong>}>
                  <Select
                    defaultValue=""
                    value={employeeToAssign}
                    style={{ width: 200 }}
                    onChange={handleEmployeeChange}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {Object.entries(employeeData).map(([key, value]) => (
                      <Option key={key} value={key}>
                        {value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={<strong style={{ fontSize: "18px" }}>EMPLOYEE DESIGNATION :</strong>}>
                  <Input
                    value={designation}
                    placeholder="Designation"
                    style={{ marginTop: "10px", color: "black" }}
                    disabled
                  />
                </Form.Item>
                <Form.Item label={<strong style={{ fontSize: "18px" }}>EMPLOYEE LOCATION :</strong>}>
                  <Input
                    value={location}
                    placeholder="Location"
                    style={{ marginTop: "10px", color: "black" }}
                    disabled
                  />
                </Form.Item>
              </Form>

              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button onClick={handleModalClose}>Close</Button>
                {employeeToAssign && (
                  <Button
                    type="primary"
                    style={{ marginLeft: "10px" }}
                    onClick={handleAssignToEmployee}
                  >
                    Assign
                  </Button>
                )}
              </div>
            </div>
          </Modal>
          <Modal
            visible={deleteAssetModalVisible}
            onCancel={handleModalClose}
            footer={null}
            width={1000}
          >
            {/* <div style={{ marginLeft: 15, overflowY: "auto", height: "100%" }}> */}
            <div>
              {/* <h2>ASSIGN ASSET</h2> */}
              <p>
                <strong style={{ fontSize: "18px" }}>
                  Are you sure you want to delete the entry of asset with below
                  details ?
                </strong>
              </p>

              <Form
                layout="vertical" // Set the form layout to vertical
                style={{
                  marginTop: "20px",
                  width: 500,
                  marginLeft: "20px",
                  marginRight: "20px",
                  marginBottom: "20px",
                }}
              >
                <Form.Item label={<strong style={{ fontSize: "18px" }}>ASSET ID :</strong>}>
                  <Input
                    value={assetID}
                    placeholder="assetID"
                    style={{ marginTop: "10px", color: "black" }}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item label={<strong style={{ fontSize: "18px" }}>SERIAL NUMBER :</strong>}>
                  <Input
                    value={serialNumber}
                    placeholder="serialNumber"
                    style={{ marginTop: "10px", color: "black" }}
                    disabled
                  />
                </Form.Item>
              </Form>

              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button onClick={handleModalClose}>Close</Button>
                <Button
                  type="primary"
                  style={{ marginLeft: "10px" }}
                  onClick={handleDeleteAssetEntry}
                >
                  Delete
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </center>
    </>
  );
};

export default MISComp;
