import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../apiConfig'

export const updateCollectionBA = createAsyncThunk(
  'updateCollectionBA',
  async ({ jwt_token, requestBody }) => {
    console.log('Request Body - ', requestBody)
    const options = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwt_token,
        'Content-Type': 'application/json' // Set content type to JSON
      },
      body: JSON.stringify(requestBody) // Wrap requestBody as needed
    }

    try {
      const response = await fetch(
        `${BASE_URL}reassign/collection/ba/`,
        // `${BASE_URL}reassign/collection/ba/?ba_code=${ba_code}&app_id=${app_id}&reassign_type=${reassign_type}&current_ba=${current_ba}`,
        options
      )
      console.log('Request Options - ', options)
      console.log('Response -- ', response)
      return response.json() // Assuming the response is JSON
    } catch (error) {
      console.log('Error', error)
      alert(error.message)
      throw error
    }
  }
)

const updateCollectionBASlice = createSlice({
  name: 'updateCollectionBA',
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: null
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateCollectionBA.pending, state => {
      state.isLoading = true
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(updateCollectionBA.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(updateCollectionBA.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.errorMessage = action.error.message
    })
  }
})

export default updateCollectionBASlice.reducer
