import { Col, Table, Button, Form, Input, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAdmin } from '../../../redux/slice/adminSlice'
import { useAuth } from '../../../context/AuthContext'

function TableRefreshManual () {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const [selectedTimes, setSelectedTimes] = useState([])
  const [tableData, setTableData] = useState([])

  const { authState } = useAuth()

  var jwt_token = authState.token
  console.log('Admin Auth Token - ', jwt_token)

  const onFinish = async values => {
    setSelectedTimes(prevTimes => [...prevTimes, values.time.refreshtime])
    setFormData({
      table: values.table.tablename,
      time: values.time.refreshtime
    })
    form.resetFields()
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  }

  useEffect(() => {
    // Create a new row in the tableData for the selected table and times
    if (formData.table && formData.time) {
      const existingRow = tableData.find(row => row.table === formData.table)
      if (existingRow) {
        existingRow.times.push(formData.time)
      } else {
        setTableData(prevTableData => [
          ...prevTableData,
          { table: formData.table, times: [formData.time] }
        ])
      }
    }
  }, [formData])

  const columns = [
    {
      title: 'Table Name',
      dataIndex: 'table',
      key: 'table'
    },
    {
      title: 'Selected Times',
      dataIndex: 'times',
      key: 'times',
      render: times => times.join(', ')
    }
  ]

  return (
    <div style={{ marginTop: '20px' }}>
      <Form
        {...layout}
        form={form}
        name='nest-messages'
        onFinish={onFinish}
        style={{ maxWidth: 600 }}
      >
        <Form.Item
          name={['table', 'tablename']}
          label='Select Table'
          rules={[{ required: true, message: 'Please select table' }]}
        >
          <Select placeholder='Select Table'>
            <Select.Option value='Credit Pipeline'>
              Credit Pipeline
            </Select.Option>
            <Select.Option value='Branch Pipeline'>
              Branch Pipeline
            </Select.Option>
            <Select.Option value='Legal and Technical'>
              Legal and Technical
            </Select.Option>
            <Select.Option value='Supervisor Visit'>
              Supervisor Visit
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name={['time', 'refreshtime']}
          label='Select Time'
          rules={[{ required: true, message: 'Please select time' }]}
        >
          <Select placeholder='Select Time'>
            <Select.Option value='12:00 AM'>12:00 AM</Select.Option>
            <Select.Option value='01:00 AM'>01:00 AM</Select.Option>
            <Select.Option value='02:00 AM'>02:00 AM</Select.Option>
            {/* ... rest of the options ... */}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type='primary' htmlType='submit'>
            Set Refresh Time
          </Button>
        </Form.Item>
      </Form>
      <div
        style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px' }}
      >
        <Table dataSource={tableData} columns={columns} />
      </div>
    </div>
  )
}

export default TableRefreshManual
