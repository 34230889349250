import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DIGITAL_JOURNEY_BASE_URL } from '../apiConfig'

const isBase64 = (str) => {
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false; // Not a valid base64 string
    }
};

const decodeBase64 = (str) => {
    if (isBase64(str)) {
        try {
            return decodeURIComponent(escape(atob(str))); // Ensures correct UTF-8 decoding
        } catch (e) {
            console.error("Error decoding Base64:", e);
            return str; // Return original string if decoding fails
        }
    }
    return str; // Return original if it's not base64
};

// Decode chat data fields
// const decodeChatData = (data) => {
//     // Check if data is an array and the first item is also an array (containing chat objects)
//     if (Array.isArray(data) && data.length > 0 && Array.isArray(data[0])) {
//         const chatDataArray = data[0]; // Extract the chat data array

//         // Decode Base64 fields in the chat data
//         const decodedChatData = chatDataArray.map((item) => {
//             if (item.chat_user_profile) {
//                 item.chat_user_profile.full_name = decodeBase64(item.chat_user_profile.full_name);
//                 item.chat_user_profile.reg_phone = decodeBase64(item.chat_user_profile.reg_phone);
//                 item.chat_user_profile.first_name = decodeBase64(item.chat_user_profile.first_name);
//                 item.chat_user_profile.last_name = decodeBase64(item.chat_user_profile.last_name);
//                 item.chat_user_profile.email_id = decodeBase64(item.chat_user_profile.email_id);
//                 item.chat_user_profile.kyc.pan = decodeBase64(item.chat_user_profile.kyc.pan);
//                 item.chat_user_profile.address.address_1 = decodeBase64(item.chat_user_profile.address.address_1);
//                 item.chat_user_profile.address.address_2 = decodeBase64(item.chat_user_profile.address.address_2);
//                 item.business_det.address.address_1 = decodeBase64(item.business_det.address.address_1);
//                 item.business_det.address.address_2 = decodeBase64(item.business_det.address.address_2);
//                 // Continue decoding other Base64 fields if needed
//             }
//             return item;
//         });

//         // Return the modified data in the same structure (array with two items)
//         return [decodedChatData, data[1]];
//     } else {
//         console.error("Data format is incorrect or data[0] is not an array.");
//         return data; // Return the original data if the format is incorrect
//     }
// };

const decodeChatData = (data) => {
    // Check if data is an array and the first item is also an array (containing chat objects)
    if (Array.isArray(data) && data.length > 0 && Array.isArray(data[0])) {
        const chatDataArray = data[0]; // Extract the chat data array

        // Decode Base64 fields in the chat data
        const decodedChatData = chatDataArray.map((item) => {
            if (item.chat_user_profile) {
                const profile = item.chat_user_profile;
                profile.full_name = decodeBase64(profile.full_name || '');
                profile.reg_phone = decodeBase64(profile.reg_phone || '');
                profile.first_name = decodeBase64(profile.first_name || '');
                profile.last_name = decodeBase64(profile.last_name || '');
                profile.email_id = decodeBase64(profile.email_id || '');

                // Safely decode pan field in kyc if it exists
                if (profile.kyc && profile.kyc.pan) {
                    profile.kyc.pan = decodeBase64(profile.kyc.pan);
                }

                // Safely decode address fields if they exist
                if (profile.address) {
                    profile.address.address_1 = decodeBase64(profile.address.address_1 || '');
                    profile.address.address_2 = decodeBase64(profile.address.address_2 || '');
                }
            }

            // Safely decode address fields in business_det if they exist
            if (item.business_det && item.business_det.address) {
                const businessAddress = item.business_det.address;
                businessAddress.address_1 = decodeBase64(businessAddress.address_1 || '');
                businessAddress.address_2 = decodeBase64(businessAddress.address_2 || '');
            }

            // Continue decoding other fields if needed
            return item;
        });

        // Return the modified data in the same structure (array with two items)
        return [decodedChatData, data[1]];
    } else {
        console.error("Data format is incorrect or data[0] is not an array.");
        return data; // Return the original data if the format is incorrect
    }
};


// export const fetchChatData = createAsyncThunk(
//     'fetchChatData',
//     async ({ jwtBearer, user_id, user_role, chat_type, page, pageSize }) => {
//         const response = await fetch(`${DIGITAL_JOURNEY_BASE_URL}portal/get/data/?user_id=${user_id}&user_role=${user_role}&chat_type=${chat_type}&page=${page}&pageSize=${pageSize}`, {
//             headers: {
//                 Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
//             }
//         });

//         const data = await response.json();
//         // Decode the data before returning
//         return decodeChatData(data);
//     }
// );

export const fetchChatData = createAsyncThunk(
    'fetchChatData',
    // async ({ jwtBearer, user_id, user_role, chat_type, page, pageSize }) => {
    async ({ jwtBearer, user_id, user_role, chat_type }) => {
        // Construct the request body
        const requestBody = {
            user_id,
            user_role,
            chat_type
            // page,
            // pageSize
        };

        console.log('Get Chat Request - ', requestBody)

        const response = await fetch(`${DIGITAL_JOURNEY_BASE_URL}portal/get/data/`, {
            method: 'POST', // Change to POST
            headers: {
                'Content-Type': 'application/json', // Set content type to JSON
                Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
            },
            body: JSON.stringify(requestBody) // Send the request body as JSON
        });

        if (!response.ok) {
            throw new Error('Failed to fetch chat data'); // Handle error response
        }

        const data = await response.json();
        // Decode the data before returning
        return decodeChatData(data);
    }
);

const chatDataSlice = createSlice({
    name: 'chatDataSlice',
    initialState: {
        isLoading: false,
        data: null,
        isError: false
    },
    extraReducers: (builder) => {
        builder.addCase(fetchChatData.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchChatData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload; // Decoded data is stored here
        });
        builder.addCase(fetchChatData.rejected, (state, action) => {
            console.log('Error', action.error);
            state.isError = true;
        });
    }
});

export default chatDataSlice.reducer;
