import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../app/context/AuthContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import logo_rounded from "../../assets/logo_rounded.png";
import {
  Layout,
  Drawer,
  Affix,
  theme,
  Button,
  Menu,
  Divider,
  Avatar,
  Modal,
  Popover,
  Row,
  Col,
  Tooltip,
  Watermark,
  Form,
  Input,
  Card,
  message,
  Alert,
} from "antd";
import Sidenav from "./Sidenav";
import React from "react";
import logo from "../../../assets/logo.png";

import Diwali_Final from "../../../assets/Diwali_Final.jpg";
import { updateUserPassword } from "../../app/redux/slice/updateUserPassword";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  FileProtectOutlined,
  HomeOutlined,
  DollarOutlined,
  MobileOutlined,
  AreaChartOutlined,
  LaptopOutlined,
} from "@ant-design/icons";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { NavLink } from "react-router-dom";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import PermissionsGate from "../PermissionsGate";
import { SCOPES } from "../PermissionsMap";
import jwt_decode from "jwt-decode";
import { setCollapsed } from "../../redux/slice/sidenavCollapseSlice";
const { Header, Sider, Content } = Layout;

function Main({ children }) {
  const dispatch = useDispatch();
  const { authState, onLogout } = useAuth();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [showResetButton, setShowResetButton] = useState(false);

  useEffect(() => {
    // Extract the page name from the pathname (remove the leading '/')
    const pageName = location.pathname.substring(1);
    setCurrentPage(pageName);
  }, [location]);
  var token_decoded = authState.token;
  console.log("Main Token - ", token_decoded);
  var jwt_payload = "";
  var jwt_payload_username = "";
  var jwt_payload_userid = "";
  var jwt_role = "";
  if (token_decoded) {
    jwt_payload = jwt_decode(token_decoded);
    console.log("JWT Payload Main", jwt_payload);
    jwt_payload_username = jwt_payload.username;
    jwt_payload_userid = jwt_payload.user_id;
    jwt_role = jwt_payload.role;
    console.log("JWT Group Main", jwt_payload_username);
  }

  // Redirect to login
  const state = useSelector((state) => state);
  console.log("Password Reset State - ", state);

  // const tokenExpired = state.creditReports.data?.detail || "";
  // const tokenExpiryTime = state.loginUser.data?.access_token;
  // const TOKEN_KEY = "my-jwt";
  // if (tokenExpired == "Signature has expired.") {
  //   console.log("In token expired condition");
  //   AsyncStorage.removeItem(TOKEN_KEY);
  //   onLogout;
  //   location.reload();
  // }

  const TOKEN_KEY = "my-jwt";
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var jwt_payload_expiry = [];
  if (jwtBearer) {
    console.log("Token decoded");
    jwt_payload = jwt_decode(jwtBearer);
    jwt_payload_expiry = jwt_payload.exp;
  }
  console.log("Password reset expiry - ", jwt_payload_expiry);
  var currentTime = Math.floor(Date.now() / 1000);

  console.log("Password reset current time - ", currentTime);

  // Compare current time with token expiry time
  if (currentTime > jwt_payload_expiry) {
    // Token has expired, perform logout
    // AsyncStorage.removeItem(TOKEN_KEY);
    onLogout();
    location.reload();
  }
  // Redirect to login

  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  const [sidenavType, setSidenavType] = useState("#E7EBEC");
  const [fixed, setFixed] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(defaultSelectedKey);
  const [passwordChangeModalVisible, setPasswordChangeModalVisible] =
    useState(false);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);

  const [collapsed, setCollapsed] = useState(false);
  const collapseState = useSelector((state) => state.sidenavCollapse);
  console.log("Sidenav Collapse State - ", collapseState);
  useEffect(() => {
    if (collapseState === "collapsed") {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [collapseState]);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigation = useNavigation();

  const handleMenuItemPress = (screenName) => {
    navigation.navigate(screenName);
  };
  const Stack = createNativeStackNavigator();

  const page = pathname.replace("/", "");

  const getDefaultSelectedKey = (pathname) => {
    switch (pathname) {
      case "/Reports":
        return "2";
      case "/MIS":
        return "3";
      case "/Collection":
        return "4";
      case "/CMASupport":
        return "5";
      case "/ITInventory":
        return "6";
      case "/Notifications":
        return "7";
      case "/CMA":
        return "8";
      case "/Adminusers":
        return "9";
      default:
        return "2"; // Set a default value in case the pathname doesn't match any of the cases
    }
  };

  // Get the defaultSelectedKey based on the current pathname
  const defaultSelectedKey = getDefaultSelectedKey(location.pathname);
  console.log("Current Page Sidenav - ", defaultSelectedKey);

  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    const updatedSelectedKeys = [pathname || "Reports"];
    setSelectedKeys(updatedSelectedKeys);
  }, [pathname]);

  const [popoverVisible, setPopoverVisible] = useState(false);

  const showPopover = () => {
    setPopoverVisible(true);
  };

  const hidePopover = () => {
    setPopoverVisible(false);
  };

  const [signOutModalVisible, setSignOutModalVisible] = useState(false);

  const showSignOutModal = () => {
    setSignOutModalVisible(true);
    hidePopover(); // Hide the popover when opening the sign-out confirmation modal
  };

  const showPasswordChangeModal = () => {
    setPasswordChangeModalVisible(true);
    hidePopover(); // Hide the popover when opening the sign-out confirmation modal
  };

  const hideSignOutModal = () => {
    setSignOutModalVisible(false);
    setPasswordChangeModalVisible(false);
  };

  const handleConfetti = () => {
    setConfettiVisible(true);
    setTimeout(() => {
      setConfettiVisible(false);
    }, 3000); // Adjust the duration as needed
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    if (reNewPassword != "") {
      if (reNewPassword !== e.target.value) {
        setPasswordMatchError("Passwords do not match");
        setShowResetButton(false);
      } else {
        setPasswordMatchError("");
        setShowResetButton(true);
      }
    }
  };

  const handleReNewPasswordChange = (e) => {
    setReNewPassword(e.target.value);
    const reEnterPassword = e.target.value;
    // Check if the entered password matches the new password
    if (reEnterPassword !== newPassword) {
      setPasswordMatchError("Passwords do not match");
      setShowResetButton(false);
    } else {
      setPasswordMatchError("");
      setShowResetButton(true);
    }
  };

  const handleResetPassword = async () => {
    if (currentTime > jwt_payload_expiry) {
      // Token has expired, perform logout
      alert("Session expired. Please login again");
      // AsyncStorage.removeItem(TOKEN_KEY);
      onLogout();
      location.reload();
    }
    try {
      const requestBody = {
        userid: jwt_payload_userid,
        new_password: newPassword,
        old_password: oldPassword,
        category: "user",
      };

      const response = await dispatch(
        updateUserPassword({ jwt_token: authState.token, requestBody })
      );
      console.log("Password reset response - ", response);
      // Reset the form and mode after submission
      setPasswordChangeModalVisible(false);
      // message.success("Password changed successfully");
      alert(response.payload?.message);
      if (response.payload?.code == 200) {
        // AsyncStorage.removeItem("my-jwt");
        setTimeout(() => {
          alert(
            "You will be redirected to the login screen shortly. Please use your new password to login"
          );
        }, 2000); // Delay rendering by 500ms
        setTimeout(() => {
          message.success("Password changed successfully");
          window.location.reload();
        }, 5000); // Delay rendering by 500ms
      }
    } catch (error) {
      console.error("Error in change password:", error);
      message.error("Unknown error occurred while changing password");
    }
    // console.log("Password reset response - ", response);
  };

  return (
    <div className="login-page">
      <Card
        style={{
          width: "60%",
          marginTop: "20px",
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", // Add the box-shadow property
          borderRadius: "15px", // Add rounded corners for a more aesthetic look
        }}
        bordered={true}
      >
        {/* <Form>
          <Form.Item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          > */}
        <div
          style={{
            textAlign: "center",
          }}
        >
          <img
            src={logo_rounded}
            style={{
              height: 48,
              marginTop: "40px",
              alignItems: "center",
            }}
          />
        </div>
        <h1 className="form-title" style={{ textAlign: "center" }}>
          PLEASE RESET YOUR PASSWORD
        </h1>
        {/* </Form.Item>
        </Form> */}
        {/* <Row gutter={16}>
          <Col span={12}> */}
        {/* <Row>
          <Col> */}
        <Alert
          style={{
            width: "60%",
            margin: "auto",
          }}
          message={<strong>WELCOME TO THE ZIEL PORTAL!</strong>}
          description="To enhance the security of the portal, a one-time password reset is required before you can login."
          type="info"
        />
        <div
          style={{
            width: "60%",
            margin: "auto", // horizontally center the card
            marginTop: "20px",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
            borderRadius: "5px",
          }}
        >
          <Card
            style={{
              // width: "100%",
              alignItems: "center",
              marginTop: "20px",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            }}
            layout="vertical"
            bordered={true}
          // className="criclebox tablespace mb-24"
          >
            <Form
              layout="vertical" // Set the form layout to vertical
              style={{
                marginTop: "20px",
                width: 500,
                marginLeft: "20px",
                marginRight: "20px",
                marginBottom: "20px",
              }}
            >
              <Form.Item
                label={<strong>ENTER THE OLD PASSWORD :</strong>}
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter the old password.",
                  },
                ]}
              >
                <>
                  <Input
                    style={{ color: "#404040" }}
                    onChange={handleOldPasswordChange}
                  />
                </>
              </Form.Item>
              <Form.Item
                label={<strong>ENTER THE NEW PASSWORD :</strong>}
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter the new password.",
                  },
                  {
                    pattern:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/,
                    message:
                      "Password must contain at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character.",
                  },
                ]}
              >
                <>
                  <Input.Password
                    type="password"
                    style={{ color: "#404040" }}
                    onChange={handleNewPasswordChange}
                  />
                </>
              </Form.Item>
              <Form.Item
                label={<strong>RE-ENTER THE NEW PASSWORD :</strong>}
                name="reNewPassword"
                rules={[
                  {
                    required: true,
                    message: "Please re-enter the new password.",
                  },
                ]}
              >
                <>
                  <Input.Password
                    style={{ color: "#404040" }}
                    onChange={handleReNewPasswordChange}
                  />
                </>
              </Form.Item>
              {passwordMatchError && (
                <div style={{ color: "red", marginLeft: "20px" }}>
                  {passwordMatchError}
                </div>
              )}
              {showResetButton && (
                <Button type="primary" onClick={handleResetPassword}>
                  Reset Password
                </Button>
              )}
            </Form>
          </Card>
        </div>
        <div
          style={{
            width: "60%",
            margin: "auto", // horizontally center the card
            marginTop: "20px",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
            borderRadius: "5px",
          }}
        >
          <Card
            layout="horizontal"
            bordered={true}
            className="criclebox tablespace mb-24"
            style={{
              // width: "60%",
              marginTop: "20px",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div style={{ marginLeft: "20px", marginRight: "20px" }}>
              <p>
                <strong>
                  In order to protect your account, make sure your password
                  meets the following requirements:
                </strong>
              </p>
              <p>1. Must be atleast 8 characters long.</p>
              <p>
                2. Must contain at least <strong>one numeric</strong> character.
              </p>
              <p>
                3. Must contain at least <strong>one special</strong> character
                - @$!%*?&.
              </p>
              <p>
                4. Must contain at least <strong>one uppercase</strong>{" "}
                alphabet.
              </p>
              <p>
                5. Should not contain your Name/DOB/Name of any familiar person.
              </p>
              <p>6. Should not be a part or same as your username.</p>
              <p>7. Should not be a dictionary word.</p>
              <p>8. Password will expire and needs to be changed every 3 months.</p>
              <p></p>
            </div>
          </Card>
        </div>
      </Card>
    </div>
  );
}

export default Main;
