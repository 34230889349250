import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DIGITAL_JOURNEY_BASE_URL } from '../apiConfig'

export const fetchChatImages = createAsyncThunk(
    'fetchChatImages',
    async ({ jwtBearer, uuid }) => {
        const response = await fetch(`${DIGITAL_JOURNEY_BASE_URL}portal/get/user/chat/images/?uuid=${uuid}`, {
            headers: {
                Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
            }
        })
        return response.json()
    }
)

const chatImagesSlice = createSlice({
    name: 'chatImagesSlice',
    initialState: {
        isLoading: false,
        data: null,
        isError: false
    },
    extraReducers: builder => {
        builder.addCase(fetchChatImages.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchChatImages.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        })
        builder.addCase(fetchChatImages.rejected, (state, action) => {
            console.log('Error', action.payload)
            state.isError = true
        })
    }
})

export default chatImagesSlice.reducer
