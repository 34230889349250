import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAutoRepeatData,
  fetchFilteredAutoRepeatData,
  fetchFullAppraisalData,
  fetchFilteredFullAppraisalData
} from '../../../redux/slice/fetchRepeatEligibilityData';
import { downloadRepeatEligibilityReport } from "../../../redux/slice/downloadRepeatEligibility";
import { HashLoader } from "react-spinners";
import { Card, Button, Input, Select, Space, Table, Alert, Empty } from "antd";
import { StyledTable, StyledButton } from "../components/styleComp";
import { useAuth } from "../../../context/AuthContext";
import jwt_decode from "jwt-decode";

const { Option } = Select;

const RepeatEligibility = (props) => {
  const { authState } = useAuth();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    pages: { autoRepeat: { page: 1, pageSize: 7 }, fullAppraisal: { page: 1, pageSize: 7 } },
    filters: { autoRepeat: {}, fullAppraisal: {} },
    searchInputs: { autoRepeat: "", fullAppraisal: "" },
    selectedColumns: { autoRepeat: "", fullAppraisal: "" },
    hasSearched: { autoRepeat: false, fullAppraisal: false },
  });


  const [triggerFilter, setTriggerFilter] = useState(null);

  const jwtBearer = authState.token;
  const tokenDecoded = jwtBearer ? jwt_decode(jwtBearer) : null;
  const jwtPayload = tokenDecoded ? {
    // group: tokenDecoded.group_id,
    group: props.userDetails.group_id,
    user: tokenDecoded.user_id,
    // role: tokenDecoded.role,
    role: props.userDetails.role,
    expiry: tokenDecoded.exp,
  } : {};

  useEffect(() => {
    if (jwtBearer) {
      const { page, pageSize } = state.pages.autoRepeat;
      dispatch(fetchAutoRepeatData({ jwtBearer, page, filters: state.filters.autoRepeat, pageSize }));
    }
  }, [jwtBearer, state.pages.autoRepeat, state.filters.autoRepeat, dispatch]);

  useEffect(() => {
    if (jwtBearer) {
      const { page, pageSize } = state.pages.fullAppraisal;
      dispatch(fetchFullAppraisalData({ jwtBearer, page, filters: state.filters.fullAppraisal, pageSize }));
    }
  }, [jwtBearer, state.pages.fullAppraisal, state.filters.fullAppraisal, dispatch]);

  const {
    autoRepeatData,
    fullAppraisalData,
    filteredAutoRepeatData,
    filteredFullAppraisalData,
    totalCountAutoRepeat,
    totalCountFullAppraisal,
    isLoadingAutoRepeat,
    isLoadingFullAppraisal,
    isLoadingFilteredAutoRepeat,
    isLoadingFilteredFullAppraisal,
    errorAutoRepeat,
    errorFullAppraisal,
  } = useSelector((state) => state.repeatEligibility);

  const stateEE = useSelector((state) => state.repeatEligibility);
  console.log("stateEligibility---", stateEE)

  const {
    isDownloadingAuto,
    isErrorAutoDownload,
    isDownloadingFull,
    isErrorFullDownload,
  } = useSelector((state) => state.downloadRepeatEligibility);

  const defineColumns = useCallback((data) => {
    if (data.length === 0) return [];
    const keys = Object.keys(data[0]);
    return keys.map((key) => ({
      title: <strong style={{ fontSize: "18px" }}>{key.replace(/_/g, " ").toUpperCase()}</strong>,
      dataIndex: key,
      key: key,
      render: (text) => <span style={{ fontSize: "16px" }}>{text}</span>,
    }));
  }, []);

  const handleKeyDown = (event, type) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleFilter(type);
    }
  };

  const handleFilter = (type) => {
    const filterActions = {
      autoRepeat: fetchFilteredAutoRepeatData,
      fullAppraisal: fetchFilteredFullAppraisalData,
    };

    const columnName = state.selectedColumns[type].toLowerCase();
    const trimmedInput = state.searchInputs[type].trim();

    dispatch(
      filterActions[type]({
        jwtBearer,
        filters: { [columnName]: trimmedInput },
      })
    );

    setState((prevState) => ({
      ...prevState,
      hasSearched: {
        ...prevState.hasSearched,
        [type]: true,
      },

      pages: {
        ...prevState.pages,
        [type]: { ...prevState.pages[type], page: 1 },
      },
    }));
  };

  const handleReset = (type) => {
    setState(prevState => ({
      ...prevState,
      searchInputs: {
        ...prevState.searchInputs,
        [type]: '',
      },
      selectedColumns: {
        ...prevState.selectedColumns,
        [type]: '',
      },
      hasSearched: {
        ...prevState.hasSearched,
        [type]: false,
      },
      filters: {
        ...prevState.filters,
        [type]: {},
      },
      hasSearched: {
        ...prevState.hasSearched,
        [type]: false,
      }
    }));

    if (type === 'autoRepeat') {
      dispatch(fetchAutoRepeatData({ jwtBearer, page: state.pages.autoRepeat.page, filters: {}, pageSize: state.pages.autoRepeat.pageSize }));
    } else if (type === 'fullAppraisal') {
      dispatch(fetchFullAppraisalData({ jwtBearer, page: state.pages.fullAppraisal.page, filters: {}, pageSize: state.pages.fullAppraisal.pageSize }));
    }


    setTriggerFilter(type);
  };

  useEffect(() => {
    if (triggerFilter) {
      handleFilter(triggerFilter);
      setTriggerFilter(null);
    }
  }, [triggerFilter]);

  // const paginationConfig = (type) => {
  //   const total = {
  //     autoRepeat: state.hasSearched.autoRepeat ? filteredAutoRepeatData.length : totalCountAutoRepeat,
  //     fullAppraisal: state.hasSearched.fullAppraisal ? filteredFullAppraisalData.length : totalCountFullAppraisal,
  //   }[type];

  //   return {
  //     current: state.pages[type].page,
  //     pageSize: state.pages[type].pageSize,
  //     total: total,
  //     onChange: (page, pageSize) => setState(prevState => ({
  //       ...prevState,
  //       pages: { ...prevState.pages, [type]: { page, pageSize } },
  //     })),
  //   };
  // };

  const paginationConfig = (type) => {
    const total = {
      autoRepeat: state.hasSearched.autoRepeat ? filteredAutoRepeatData.length : totalCountAutoRepeat,
      fullAppraisal: state.hasSearched.fullAppraisal ? filteredFullAppraisalData.length : totalCountFullAppraisal,
    }[type];

    return {
      current: state.pages[type].page,
      pageSize: state.pages[type].pageSize,
      total: total,
      showTotal: (total, [start, end]) => `${start}-${end} of ${total} items`,
      onChange: (page, pageSize) => setState(prevState => ({
        ...prevState,
        pages: { ...prevState.pages, [type]: { page, pageSize } },
      })),
    };
  };

  const renderSearchBar = (columns, type) => (
    <Space style={{ marginBottom: 16, marginLeft: 2 }}>
      <Select
        placeholder="Select Column"
        style={{ width: 200, height: 32, lineHeight: '32px' }}
        value={state.selectedColumns[type] || undefined}
        onChange={(value) =>
          setState((prevState) => ({
            ...prevState,
            selectedColumns: {
              ...prevState.selectedColumns,
              [type]: value,
            },
          }))
        }
      >
        {columns.map((col) => (
          <Option key={col.key} value={col.dataIndex}>
            {col.title}
          </Option>
        ))}
      </Select>
      <Input
        placeholder="Value"
        value={state.searchInputs[type]}
        onChange={(e) =>
          setState((prevState) => ({
            ...prevState,
            searchInputs: {
              ...prevState.searchInputs,
              [type]: e.target.value,
            },
          }))
        }
        onKeyDown={(e) => handleKeyDown(e, type)}
        style={{ width: 150, height: 32, lineHeight: '32px' }}
        disabled={!state.selectedColumns[type]}
      />
      <Button
        type="primary"
        onClick={() => handleFilter(type)}
        style={{ height: 32, lineHeight: '32px' }}
      >
        Search
      </Button>
      {(filteredAutoRepeatData || filteredFullAppraisalData) && (
        <Button onClick={() => handleReset(type)} style={{ width: 70, height: 32, lineHeight: '32px' }}>
          Reset
        </Button>
      )}
    </Space>
  );

  const handleAPIDownload = (type) => {
    dispatch(downloadRepeatEligibilityReport({ jwtBearer, type }));
  };

  const renderButtonByType = (type) => (
    <StyledButton type="primary" onClick={() => handleAPIDownload(type)} loading={type === "autoRepeat" ? isDownloadingAuto : isDownloadingFull}>
      Download Report
    </StyledButton>
  );

  const getCurrentMonthName = () => {
    const date = new Date();
    const options = { month: 'long' };
    return new Intl.DateTimeFormat('en-IN', options).format(date);
  };

  const currentMonth = getCurrentMonthName();
  console.log(currentMonth);

  return (
    <>
      <div>
        {["autoRepeat", "fullAppraisal"].map((type) => {
          const data = {
            autoRepeat: state.hasSearched.autoRepeat ? filteredAutoRepeatData : autoRepeatData,
            fullAppraisal: state.hasSearched.fullAppraisal ? filteredFullAppraisalData : fullAppraisalData,
          }[type];
          const columns = defineColumns(data || []);

          const isLoading = {
            autoRepeat: isLoadingAutoRepeat,
            fullAppraisal: isLoadingFullAppraisal,
          }[type];
          const error = {
            autoRepeat: errorAutoRepeat,
            fullAppraisal: errorFullAppraisal,
          }[type];

          const isLoadingFiltered = {
            autoRepeat: isLoadingFilteredAutoRepeat,
            fullAppraisal: isLoadingFilteredFullAppraisal,
          }[type];

          return (
            <Card
              key={type}
              title={
                <span style={{ fontWeight: 600, fontSize: '24px', fontFamily: 'poppins-font, sans-serif' }}>
                  {type === 'autoRepeat' ? 'Pre Approved' : 'Full Appraisal'}
                </span>
              }
              bordered
              extra={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >

                  {renderButtonByType(type)}
                </div>
              }
            >
              {renderSearchBar(columns, type)}
              <p style={{ fontSize: "18px", fontWeight: 500, marginTop: "8px" }}>
                The OSP shown is as of 3rd of {currentMonth}. Actual OSP will be calculated at the time of disbursement.
              </p>
              {isLoadingFiltered || isLoading ? (
                <div className="spinner-container">
                  <HashLoader color="#f57171" />
                </div>
              ) : error ? (
                <Alert message={error} type="error" />
              ) : (
                <StyledTable
                  columns={columns}
                  dataSource={data}
                  pagination={paginationConfig(type)}
                  locale={{ emptyText: <Empty description="No data available" /> }}
                />
              )}
            </Card>
          );
        })}
      </div>
    </>
  );
};

export default RepeatEligibility;
