import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../apiConfig';

// Helper function to format the date as `dd-MMM`
const formatDate = () => {
  const options = { day: '2-digit', month: 'short' };
  const date = new Date().toLocaleDateString('en-GB', options); // e.g., "01-Jan"
  return date.replace(/ /g, '-'); // Replace space with hyphen for filename
};

// Define the async thunk for downloading the report
export const downloadRepeatEligibilityReport = createAsyncThunk(
  'downloadRepeatEligibility/downloadReport',
  async ({ jwtBearer, type }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BASE_URL}pipeline/report/download/?type=${type}`, {
        headers: {
          Authorization: `Bearer ${jwtBearer}`, // Include the bearer token in the request header
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob(); // Extract the blob from the response
      const url = URL.createObjectURL(blob); // Create a temporary URL for the blob

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = `${type}_${formatDate()}.xlsx`; // Format filename with type and date
      link.click();

      // Clean up the temporary URL
      URL.revokeObjectURL(url);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Define the slice with proper handling for different report types
const downloadRepeatEligibilitySlice = createSlice({
  name: 'downloadRepeatEligibility',
  initialState: {
    isDownloadingAuto: false,
    isDownloadingFull: false,
    isErrorAutoDownload: false,
    isErrorFullDownload: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadRepeatEligibilityReport.pending, (state, action) => {
        const { type } = action.meta.arg;
        if (type === 'autoRepeat') {
          state.isDownloadingAuto = true;
          state.isErrorAutoDownload = false;
        } else if (type === 'fullAppraisal') {
          state.isDownloadingFull = true;
          state.isErrorFullDownload = false;
        }
      })
      .addCase(downloadRepeatEligibilityReport.fulfilled, (state, action) => {
        const { type } = action.meta.arg;
        if (type === 'autoRepeat') {
          state.isDownloadingAuto = false;
        } else if (type === 'fullAppraisal') {
          state.isDownloadingFull = false;
        }
      })
      .addCase(downloadRepeatEligibilityReport.rejected, (state, action) => {
        const { type } = action.meta.arg;
        if (type === 'autoRepeat') {
          state.isDownloadingAuto = false;
          state.isErrorAutoDownload = true;
        } else if (type === 'fullAppraisal') {
          state.isDownloadingFull = false;
          state.isErrorFullDownload = true;
        }
        console.error('Download failed:', action.payload);
      });
  },
});

export default downloadRepeatEligibilitySlice.reducer;
