import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../apiConfig'

// Async thunk for fetching user details using a POST request
export const fetchUserDetails = createAsyncThunk(
    'fetchUserDetails',
    async ({ jwtBearer, emp_code }) => {
        const response = await fetch(`${BASE_URL}admin/get/user/details/`, {
            method: 'POST',  // Changed to POST
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
            },
            body: JSON.stringify({ emp_code }) // Send emp_code in the body
        })

        // Check if the response is ok and return the parsed JSON, or throw an error
        if (!response.ok) {
            throw new Error('Failed to fetch user details')
        }
        const data = await response.json();

        return data // Return the response as JSON
    }
)

const userDetailsSlice = createSlice({
    name: 'userDetailsSlice',
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
        errorMessage: ''  // Optional: to store error messages
    },
    extraReducers: builder => {
        builder.addCase(fetchUserDetails.pending, (state, action) => {
            state.isLoading = true
            state.isError = false
            state.errorMessage = ''
        })
        builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        })
        builder.addCase(fetchUserDetails.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = action.error.message  // Capture the error message
        })
    }
})

export default userDetailsSlice.reducer
