import React, { useState, useEffect, useCallback } from "react";
import { Card, Divider } from "antd";
import CreditPipeline from "./CreditPipeline";
import BranchPipeline from "./BranchPipeline";
import LTReport from "./LTReport";
import SupervisorVisit from "./SupervisorVisit";
import OperationsData from "./OperationsData";
import RepeatEligibility from "./RepeatEligibility";
import Leads from "./Leads";
import { CloseOutlined, ReloadOutlined } from "@ant-design/icons";
import jwt_decode from "jwt-decode";
import { useAuth } from "../../../context/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { setCollapsed } from "../../../redux/slice/sidenavCollapseSlice";

const { Meta } = Card;
// function Tables() {
const Tables = (props) => {
  console.log('Reports props - ', props)
  const { authState } = useAuth();
  const dispatch = useDispatch();
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedTabKey, setSelectedTabKey] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [refreshKey, setRefreshKey] = useState(Date.now()); // For refreshing components

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1100);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let jwt_token = authState.token;
  let jwt_payload_group = [];
  if (jwt_token) {
    const jwt_payload = jwt_decode(jwt_token);
    // jwt_payload_group = jwt_payload.group_id;
    jwt_payload_group = props.userDetails?.group_id;
  }

  const updatedOn = useSelector((state) => state.updatedOn);
  const creditUpdatedOn = useSelector((state) => state.creditUpdatedOn);
  const supervisorUpdatedOn = useSelector((state) => state.supervisorUpdatedOn);
  const LTUpdatedOn = useSelector((state) => state.LTUpdatedOn);
  const LeadUpdatedOn = useSelector((state) => state.LeadUpdatedOn);

  const tabComponents = [
    {
      key: "1",
      label: "Summary",
      description: "Click here to view the summary of the credit pipeline report",
      updatedOnDescription: <strong>Last Updated On - {creditUpdatedOn}</strong>,
      component: <CreditPipeline key={refreshKey} userDetails={props.userDetails} />,
    },
    {
      key: "2",
      label: "Branch Pipeline",
      description: "Click here to view the branch pipeline report",
      updatedOnDescription: <strong>Last Updated On - {updatedOn}</strong>,
      component: <BranchPipeline key={refreshKey} userDetails={props.userDetails} />,
    },
    ...(jwt_payload_group == "2" || jwt_payload_group == "3" || jwt_payload_group == "4" || jwt_payload_group == "5"
      ? []
      : [
        {
          key: "3",
          label: "Legal & Technical",
          description: "Click here to view the legal and technical report",
          updatedOnDescription: <strong>Last Updated On - {LTUpdatedOn}</strong>,
          component: <LTReport key={refreshKey} userDetails={props.userDetails} />,
        },
        {
          key: "4",
          label: "Supervisor Visit",
          description: "Click here to view the supervisor visit report",
          updatedOnDescription: <strong>Last Updated On - {supervisorUpdatedOn}</strong>,
          component: <SupervisorVisit key={refreshKey} userDetails={props.userDetails} />,
        },
      ]),
    ...(jwt_payload_group == "2" || jwt_payload_group == "4"
      ? []
      : [
        {
          key: "5",
          label: "Lead MIS",
          description: "Click here to view the Lead MIS",
          updatedOnDescription: <strong>Last Updated On - {LeadUpdatedOn}</strong>,
          component: <Leads key={refreshKey} userDetails={props.userDetails} />,
        },
      ]),
    ...(jwt_payload_group == "5" || jwt_payload_group == "10" || jwt_payload_group == "0"
      ? [
        {
          key: "6",
          label: "Operations LOS MIS",
          description: "Click here to view the Operations LOS MIS",
          component: <OperationsData key={refreshKey} userDetails={props.userDetails} />,
        },
      ] : []),
    // ...(jwt_payload_group == "3" || jwt_payload_group == "10" || jwt_payload_group == "0" || jwt_payload_group == "1"      
    //   ? [
    ...(jwt_payload_group == "2" || jwt_payload_group == "4"
      ? []
      : [
        {
          key: "7",
          label: "Repeat Eligibility",
          description: "Click here to view the Repeat Eligibility Data",
          component: <RepeatEligibility key={refreshKey} userDetails={props.userDetails} />,
        },
      ]),
  ];

  const openComponent = (component, key) => {
    // setSelectedComponent(component);
    setTimeout(() => {
      setSelectedComponent(component);
    }, 400);

    setSelectedTabKey(key);
    setRefreshKey(Date.now()); // Force re-render
    dispatch(setCollapsed("collapsed"));
  };

  const closeComponent = () => {
    setSelectedComponent(null);
    setSelectedTabKey(null);
    dispatch(setCollapsed(null));
  };

  const refreshComponent = () => {
    setRefreshKey(Date.now()); // Update key to refresh component
  };

  return (
    <div
      style={{
        display: "flex",
        minHeight: "125vh",
        flexDirection: isMobile && !selectedComponent ? "column" : "row",
        marginBottom: "0",
        overflowY: isMobile ? "scroll" : "auto",
        transition: "flex 7s ease",
        transitionDelay: "0.2s",
      }}
    >
      {/* Cards Container */}
      <div
        style={{
          width: isMobile
            ? selectedComponent
              ? "0%"
              : "100%"
            : selectedComponent
              ? "30%"
              : "100%",
          transition: "width 0.3s ease",
          padding: "16px 30px",
          display: isMobile && selectedComponent ? "none" : "flex",
          flexDirection: isMobile ? "column" : "column",
          overflowY: "auto",
          overflowX: "hidden",
          position: "relative",


          scrollbarWidth: isMobile ? "none" : "auto",
          msOverflowStyle: isMobile ? "none" : "auto",
        }}
      >

        <div
          style={{
            display: selectedComponent && isMobile ? "none" : "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gap: "30px",
          }}
        >
          {tabComponents.map((tab) => (
            <Card
              key={tab.key}
              title={
                <h3
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{tab.label}</span>
                  {selectedTabKey === tab.key && (
                    <div
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        color: "#1890ff",
                      }}
                      onClick={refreshComponent}
                    >
                      Refresh
                      <ReloadOutlined style={{ marginLeft: "8px" }} />
                    </div>
                  )}
                </h3>
              }
              style={{
                borderRadius: "20px",
                cursor: "pointer",
                boxShadow:
                  selectedTabKey === tab.key
                    ? "0 8px 16px rgba(255, 0, 0, 0.6)"
                    : "0 4px 8px rgba(0, 0, 0, 0.2)",
                marginBottom: isMobile && !selectedComponent ? "16px" : "0",
                transform: hoveredCard === tab.key ? "scale(1.05)" : "scale(1)",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                minHeight: "240px",
              }}
              onClick={() => openComponent(tab.component, tab.key)}
              onMouseEnter={() => setHoveredCard(tab.key)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <p style={{ fontSize: "1rem", fontFamily: "cursive" }}>
                {tab.description}
              </p>
              {selectedTabKey === tab.key && tab.updatedOnDescription && (
                <>
                  <Divider />
                  <p>{tab.updatedOnDescription}</p>
                </>
              )}
            </Card>
          ))}
        </div>
      </div>

      {/* Detail View Container */}
      <div
        style={{
          width: selectedComponent ? "100%" : "0%",
          transition: "width 0.3s ease",
          padding: "16px",
          backgroundColor: "#fff",
          overflowY: "auto",
          display: selectedComponent || isMobile ? "block" : "none",
        }}
      >
        {selectedComponent && (
          <div
            style={{
              background: "#f8f8f8",
              borderRadius: "10px",
              padding: "20px",
              height: "100%",
            }}
          >
            <CloseOutlined
              onClick={closeComponent}
              style={{
                marginBottom: "10px",
                cursor: "pointer",
                color: "#1890ff",
              }}
            />
            {selectedComponent}
          </div>
        )}
      </div>
    </div>
  );
}

export default Tables;