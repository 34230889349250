import { Col, Table, Button, Form, Input, Select, Modal } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAdmin } from '../../../redux/slice/adminSlice'
import { useAuth } from '../../../context/AuthContext'
import moment from 'moment'

function TableRefresh () {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const [tableData, setTableData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalRowData, setModalRowData] = useState({})

  const { authState } = useAuth()

  var jwt_token = authState.token
  console.log('Admin Auth Token - ', jwt_token)

  const onFinish = async values => {
    const { tablename, starttime, timeinterval } = values.time
    const startTimeMoment = moment(starttime, 'hh:mm A')
    const endTimeMoment = moment('12:00 AM', 'hh:mm A').add(1, 'day') // Add 1 day to include 12:00 AM

    const newEntries = []

    while (startTimeMoment.isBefore(endTimeMoment)) {
      const refreshTime = startTimeMoment.format('hh:mm A')
      newEntries.push({
        tablename,
        refreshTime
      })
      startTimeMoment.add(parseInt(timeinterval), 'hours')
    }

    // Remove previous entries for the same tablename
    const updatedTableData = tableData.filter(
      data => data.tablename !== tablename
    )

    const updatedData = [...updatedTableData, ...newEntries]

    setTableData(updatedData)
    form.setFieldsValue({
      time: {
        starttime: undefined,
        timeinterval: undefined
      }
    })
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  }

  const handleDelete = record => {
    setModalRowData(record)
    setIsModalVisible(true)
  }

  const handleModalConfirm = () => {
    const updatedTableData = tableData.filter(
      data =>
        data.tablename !== modalRowData.tablename ||
        data.refreshTime !== modalRowData.refreshTime
    )
    setTableData(updatedTableData)
    setIsModalVisible(false)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  //   const handleRowSelectionChange = selectedRowKeys => {
  //     setSelectedRowKeys(selectedRowKeys)
  //   }

  const columns = [
    {
      title: 'Table Name',
      dataIndex: 'tablename',
      key: 'tablename'
    },
    {
      title: 'Refresh Time',
      dataIndex: 'refreshTime',
      ley: 'refreshTime'
    },
    // {
    //   title: 'Start Time',
    //   dataIndex: 'starttime',
    //   key: 'starttime'
    // },
    // {
    //   title: 'Time Interval',
    //   dataIndex: 'timeinterval',
    //   key: 'timeinterval'
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Button type='link' danger onClick={() => handleDelete(record)}>
          Delete
        </Button>
      )
    }
  ]

  //   const rowSelection = {
  //     selectedRowKeys,
  //     onChange: handleRowSelectionChange
  //   }

  const [selectedTable, setSelectedTable] = useState(null)

  const handleTableChange = value => {
    setSelectedTable(value)
  }

  const filteredTableData = tableData.filter(
    data => data.tablename === selectedTable
  )

  return (
    <div style={{ marginTop: '20px' }}>
      <Form
        {...layout}
        form={form}
        name='nest-messages'
        onFinish={onFinish}
        style={{ maxWidth: 600 }}
      >
        <Form.Item
          name={['time', 'tablename']}
          label='Select Table'
          rules={[{ required: true, message: 'Please select table' }]}
        >
          <Select
            placeholder='Select Table'
            onChange={handleTableChange}
            value={selectedTable}
          >
            <Select.Option value='Credit Pipeline'>
              Credit Pipeline
            </Select.Option>
            <Select.Option value='Branch Pipeline'>
              Branch Pipeline
            </Select.Option>
            <Select.Option value='Legal and Technical'>
              Legal and Technical
            </Select.Option>
            <Select.Option value='Supervisor Visit'>
              Supervisor Visit
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name={['time', 'starttime']}
          label='Start Time'
          rules={[{ required: true, message: 'Please select start time' }]}
        >
          <Select placeholder='Select Start Time'>
            <Select.Option value='12:00 AM'>12:00 AM</Select.Option>
            <Select.Option value='01:00 AM'>01:00 AM</Select.Option>
            <Select.Option value='02:00 AM'>02:00 AM</Select.Option>
            <Select.Option value='03:00 AM'>03:00 AM</Select.Option>
            <Select.Option value='04:00 AM'>04:00 AM</Select.Option>
            <Select.Option value='05:00 AM'>05:00 AM</Select.Option>
            <Select.Option value='06:00 AM'>06:00 AM</Select.Option>
            <Select.Option value='07:00 AM'>07:00 AM</Select.Option>
            <Select.Option value='08:00 AM'>08:00 AM</Select.Option>
            <Select.Option value='09:00 AM'>09:00 AM</Select.Option>
            <Select.Option value='10:00 AM'>10:00 AM</Select.Option>
            <Select.Option value='11:00 AM'>11:00 AM</Select.Option>
            <Select.Option value='12:00 PM'>12:00 PM</Select.Option>
            <Select.Option value='01:00 PM'>01:00 PM</Select.Option>
            <Select.Option value='02:00 PM'>02:00 PM</Select.Option>
            <Select.Option value='03:00 PM'>03:00 PM</Select.Option>
            <Select.Option value='04:00 PM'>04:00 PM</Select.Option>
            <Select.Option value='05:00 PM'>05:00 PM</Select.Option>
            <Select.Option value='06:00 PM'>06:00 PM</Select.Option>
            <Select.Option value='07:00 PM'>07:00 PM</Select.Option>
            <Select.Option value='08:00 PM'>08:00 PM</Select.Option>
            <Select.Option value='09:00 PM'>09:00 PM</Select.Option>
            <Select.Option value='10:00 PM'>10:00 PM</Select.Option>
            <Select.Option value='11:00 PM'>11:00 PM</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name={['time', 'timeinterval']}
          label='Time Interval'
          rules={[{ required: true, message: 'Please select a time interval' }]}
        >
          <Select placeholder='Select Time Interval'>
            <Select.Option value='1 HRS'>1 HRS</Select.Option>
            <Select.Option value='2 HRS'>2 HRS</Select.Option>
            <Select.Option value='3 HRS'>3 HRS</Select.Option>
            <Select.Option value='4 HRS'>4 HRS</Select.Option>
            <Select.Option value='5 HRS'>5 HRS</Select.Option>
            <Select.Option value='6 HRS'>6 HRS</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type='primary' htmlType='submit'>
            Set Table Refresh Duration
          </Button>
        </Form.Item>
      </Form>
      <div
        style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px' }}
      >
        <Table
          //   dataSource={tableData}
          dataSource={filteredTableData}
          columns={columns}
          //   rowSelection={rowSelection}
        />
        <Modal
          title='Confirm Delete'
          visible={isModalVisible}
          onOk={handleModalConfirm}
          onCancel={handleModalCancel}
        >
          <p>Are you sure you want to delete the selected row?</p>
        </Modal>
      </div>
    </div>
  )
}

export default TableRefresh
