import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../apiConfig'

export const loginUser = createAsyncThunk(
  'loginUser',
  async ({ userid, password }) => {
    try {
      // const response = await axios.post(`${BASE_URL}login/user/`, {
      const response = await axios.post(`${BASE_URL}user/login/`, {
        userid,
        password
      })
      return response.data
    } catch (error) {
      console.log('Error login', error.response.data)
      alert(error.response.data.message)
      throw error.response.data
    }
  }
)
// export const loginUser = createAsyncThunk(
//   'loginUser',
//   async ({ userid, password }) => {
//     const response = await axios.post('http://127.0.0.1:8000/login/user/', {
//       userid,
//       password
//     })
//     return response.data
//   }
// )

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: null
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loginUser.pending, state => {
      state.isLoading = true
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(loginUser.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.errorMessage = action.error.message
    })
  }
})

export default userSlice.reducer
