import React from 'react'
import { Table, Spin } from 'antd'

const LeadsPivot = ({ allFieldsPivot, allBA, allBAWithLead, ftmData }) => {
  // Filter data to include only rows where both values are not 'NULL'
  //   console.log('All BA prop', allBA.supervisor_manager['ZFT0007-Praveen Rajwat'])
  console.log('FTM Prop', ftmData)
  const filteredData = Object.entries(allFieldsPivot)
    .filter(([key, values]) => {
      const [type, category] = key.split("', '")
      return type !== "('NULL'" && category !== "'NULL'" && values !== null
    })
    .reduce((obj, [key, values]) => {
      obj[key] = values
      return obj
    }, {})

  if (Object.keys(filteredData).length === 0) {
    return null
  }

  const pivotData = {}

  const dataCategories = ['HOT', 'WARM', 'COLD']

  const supervisors = {}

  const grandTotal = {}

  Object.entries(filteredData).forEach(([key, values]) => {
    const plCategory = key.replace(/\W/g, '') // This will remove all non-word characters

    grandTotal[plCategory] = grandTotal[plCategory] || 0

    if (!supervisors[plCategory]) {
      supervisors[plCategory] = []
    }

    Object.keys(values).forEach(supervisor => {
      if (!pivotData[supervisor]) {
        pivotData[supervisor] = {}
      }

      if (!pivotData[supervisor][plCategory]) {
        pivotData[supervisor][plCategory] = {}
      }

      pivotData[supervisor][plCategory] = values[supervisor] || 0

      supervisors[plCategory].push(supervisor)
      grandTotal[plCategory] += values[supervisor] || 0
    })
  })

  const calculateTotalHot = rowData =>
    dataCategories.reduce(
      (total, category) => total + (rowData[`PL-SEC-${category}`] || 0),
      0
    )

  const calculateTotalHotUseC = rowData =>
    dataCategories.reduce(
      (total, category) => total + (rowData[`PL-USEC-${category}`] || 0),
      0
    )

  const tableCellStyle = {
    fontSize: '32px' // Adjust the font size as needed
  }

  const columns = [
    {
      title: <strong style={{ fontSize: '13px' }}>SUPERVISOR</strong>,
      dataIndex: 'Supervisor',
      key: 'Supervisor',
      className: 'table-row-light'
    },
    {
      title: <strong style={{ fontSize: '13px' }}>FTD</strong>,
      children: [
        {
          title: <strong style={{ fontSize: '13px' }}>TOTAL ACTIVE BA</strong>,
          dataIndex: 'totalBA',
          key: 'totalBA',
          className: 'increase-font-size'
        },
        {
          title: <strong style={{ fontSize: '13px' }}>BA WITH LEAD</strong>,
          dataIndex: 'baWithLead',
          key: 'baWithLead',
          className: 'increase-font-size'
        },
        {
          title: (
            <strong style={{ fontSize: '13px' }}>TOTAL LEAD GENERATED</strong>
          ),
          dataIndex: 'totalLead',
          key: 'totalLead',
          className: 'increase-font-size'
          // dataIndex: 'Total Lead',
          // key: 'Total Lead'
          // className: 'table-row-blue'
        }
      ]
    },
    {
      title: <strong style={{ fontSize: '13px' }}>PL-SEC</strong>,
      children: [
        ...dataCategories
          .filter(category => category === 'HOT')
          .map(category => ({
            title: <strong style={{ fontSize: '13px' }}>{category}</strong>,
            dataIndex: `PLSEC${category}`,
            key: `PLSEC${category}`,
            // dataIndex: `PL-SEC-${category}`,
            // key: `PL-SEC-${category}`,
            className: 'increase-font-size'
          })),
        {
          title: <strong style={{ fontSize: '13px' }}>TOTAL</strong>,
          dataIndex: 'TotalHOTSEC',
          key: 'TotalHOTSEC',
          // dataIndex: 'Total-HOT-SEC',
          // key: 'Total-HOT-SEC',
          className: 'increase-font-size'
          // render: (_, record) => calculateTotalHot(record)
        }
      ]
    },
    {
      title: <strong style={{ fontSize: '13px' }}>PL-USEC</strong>,
      children: [
        ...dataCategories
          .filter(category => category === 'HOT')
          .map(category => ({
            title: <strong style={{ fontSize: '13px' }}>{category}</strong>,
            dataIndex: `PLUSEC${category}`,
            key: `PLUSEC${category}`,
            // dataIndex: `PL-USEC-${category}`,
            // key: `PL-USEC-${category}`,
            className: 'increase-font-size'
          })),
        {
          title: <strong style={{ fontSize: '13px' }}>TOTAL</strong>,
          dataIndex: 'TotalHOTUSEC',
          key: 'TotalHOTUSEC',
          // dataIndex: 'Total-HOT-USEC',
          // key: 'Total-HOT-USEC',
          className: 'increase-font-size'
          // render: (_, record) => calculateTotalHotUseC(record)
        }
      ]
    }
    // {
    //   title: <strong>GRAND TOTAL</strong>,
    //   dataIndex: 'Grand Total',
    //   key: 'Grand Total',
    //   className: 'table-row-blue'
    // }
  ]

  const tableData = Object.keys(pivotData).map(supervisor => {
    const supervisorId = Object.keys(allBA.supervisor_manager).find(
      id => allBA.supervisor_manager[id] === supervisor
    )
    const supervisorWithLead = Object.keys(
      allBAWithLead.supervisor_manager
    ).find(id => allBAWithLead.supervisor_manager[id] === supervisor)
    const supervisorFTM = Object.keys(ftmData.supervisor_manager).find(
      id => ftmData.supervisor_manager[id] === supervisor
    )
    const rowData = {
      Supervisor: <strong>{supervisor}</strong>,
      totalBA: supervisorId ? allBA.ba_count[supervisorId] : 0,
      baWithLead: supervisorWithLead
        ? allBAWithLead.ba_count[supervisorWithLead]
        : 0,
      totalLead: supervisorWithLead
        ? allBAWithLead.lead_count[supervisorWithLead]
        : 0,
      PLSECHOT: supervisorFTM ? ftmData.hot_sec_count[supervisorFTM] : 0,
      PLUSECHOT: supervisorFTM ? ftmData.hot_usec_count[supervisorFTM] : 0,
      TotalHOTSEC: supervisorFTM ? ftmData.total_sec_count[supervisorFTM] : 0,
      TotalHOTUSEC: supervisorFTM ? ftmData.total_usec_count[supervisorFTM] : 0
      // ...dataCategories.reduce((acc, category) => {
      //   acc[`PL-SEC-${category}`] =
      //     pivotData[supervisor][`PLSEC${category.toLowerCase()}`] || 0
      //   acc[`PL-USEC-${category}`] =
      //     pivotData[supervisor][`PLUSEC${category.toLowerCase()}`] || 0
      //   return acc
      // }, {})
    }

    rowData['Total-HOT-SEC'] = calculateTotalHot(rowData)
    rowData['Total-HOT-USEC'] = calculateTotalHotUseC(rowData)

    // rowData['Grand Total'] = dataCategories.reduce(
    //   (total, category) =>
    //     total +
    //     (rowData[`PL-SEC-${category}`] || 0) +
    //     (rowData[`PL-USEC-${category}`] || 0),
    //   0
    // )

    // rowData['Total Lead'] =
    //   (rowData['PL-SEC-HOT'] || 0) +
    //   (rowData['PL-SEC-WARM'] || 0) +
    //   (rowData['PL-SEC-COLD'] || 0) +
    //   (rowData['PL-USEC-HOT'] || 0) +
    //   (rowData['PL-USEC-WARM'] || 0) +
    //   (rowData['PL-USEC-COLD'] || 0)

    return rowData
  })

  return (
    <Table
      dataSource={tableData} 
      columns={columns}
      size='small'
      bordered
      pagination={false}
      style={tableCellStyle}
    />
  )
}

export default LeadsPivot
