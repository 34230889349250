import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { CMA_SUPPORT_URL } from '../apiConfig'

export const fetchUserDepartment = createAsyncThunk(
  'fetchUserDepartment',
  async ({ jwtBearer, userid }) => {
    const response = await fetch(`${CMA_SUPPORT_URL}get/user/department/?userid=${userid}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
      }
    })
    return response.json()
  }
)

const CMAUserDepartmentSlice = createSlice({
  name: 'CMADepartmentSlice',
  initialState: {
    isLoading: false,
    data: null,
    isError: false
  },
  extraReducers: builder => {
    builder.addCase(fetchUserDepartment.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchUserDepartment.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(fetchUserDepartment.rejected, (state, action) => {
      console.log('Error', action.payload)
      state.isError = true
    })
  }
})

export default CMAUserDepartmentSlice.reducer
