import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { CMA_SUPPORT_URL } from '../apiConfig'

export const updateDescription = createAsyncThunk(
  'updateDescription',
  async ({ jwt_token, requestBody }) => {
    console.log('Request Body - ', requestBody)
    const options = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwt_token,
        'Content-Type': 'application/json' // Set content type to JSON
      },
      // body: JSON.stringify({ inventory_json: requestBody }) // Wrap requestBody as needed
      body: JSON.stringify(requestBody) // Wrap requestBody as needed
    }
    console.log('Request URL - ', `${CMA_SUPPORT_URL}update/ticket/description`)
    try {
      const response = await fetch(
        // 'https://customer.zielfintech.com/uat/api/v1/support/update/ticket/description/',
        `${CMA_SUPPORT_URL}update/ticket/description/`,
        options
      )
      console.log('Request Options - ', options)
      console.log('Response -- ', response)
      return response.json() // Assuming the response is JSON
    } catch (error) {
      console.log('Error', error)
      alert(error.message)
      throw error
    }
  }
)

const updateDescriptionSlice = createSlice({
  name: 'updateDescription',
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: null
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateDescription.pending, state => {
      state.isLoading = true
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(updateDescription.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(updateDescription.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.errorMessage = action.error.message
    })
  }
})

export default updateDescriptionSlice.reducer
