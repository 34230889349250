import styled from "styled-components";
import { Modal, Button, Slider, InputNumber, Table } from "antd";
import { CSVLink } from "react-csv";
// export const StyledModal = styled(Modal)`
// //   width: 80%;

//   @media screen and (max-width: 768px) {
//     width: 100%;
//   }
// `;

export const MyBtn = styled(Button)`
  margin-left: 16px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  padding: 0 54px;
  font-size: 16px;
  border-radius: 28px;
  background: linear-gradient(90deg, #4e54c8, #8f94fb);
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: background 0.3s, box-shadow 0.3s;
  color:white;
 
  &:hover {
    background: linear-gradient(90deg, #8f94fb, #4e54c8);
    color: white !important;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

export const StyledTable = styled(Table)`
transition: transform 0.3s ease-in-out;
 
/* Table wrapper styles */
.ant-table {
  border-radius: 8px;
  border: 1px solid #ddd;
  overflow: auto;
  scrollbar-color: #aaa white;
  scrollbar-width: thin;
  ${'' /* overflow: none; */}
}
 
/* Table header styles */
.ant-table-thead > tr > th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #444;
  border-bottom: 1px solid #ddd;
}
 
/* Table row styles */
.ant-table-tbody > tr {
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  &:nth-child(even) {
    background-color: #fafafa; /* Zebra striping */
  }
}
 
/* Hover effect on rows */
.ant-table-tbody > tr:hover {
  cursor: pointer;
  background-color: #e6f7ff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  ${'' /* transform: scale(1.02); */}
  background-color:white;
  font-weight:500;
}
 
/* Highlighting selected row */
.ant-table-tbody > tr.ant-table-row-selected {
  background-color: #bae7ff;
}
 
/* Table cell styles */
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #eee;
}
 
/* Scrollable table with shadow */
.ant-table-scroll {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}
 
/* Hover effect on cells */
.ant-table-tbody > tr > td:hover {
  background-color: #f0f0f0;
}
 
/* Pagination styles */
.ant-pagination-item-active {
  border-color: #1890ff;
}
`;

export const StyledSlider = styled(Slider)`
  .ant-slider-track {
    background: linear-gradient(90deg, #36D1DC 0%, #5B86E5 100%); // Gradient track color
    height: 10px; // Track height
    border-radius: 10px; // Rounded track
  }
 
  .ant-slider-rail {
    background-color: #e9e9e9; // Rail color
    height: 10px; // Rail height
    border-radius: 10px; // Rounded rail
  }
 
  .ant-slider-handle {
    border-color: #5B86E5; // Handle border color
    background-color: #fff; // Handle background color
    height: 24px; // Handle height
    width: 24px; // Handle width
    margin-top: -7px; // Adjust handle position
    border: 2px solid #5B86E5; // Border thickness
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); // Handle shadow
    &:focus {
      border-color: #36D1DC;
      box-shadow: 0 0 0 5px rgba(54, 209, 220, 0.2); // Focus shadow
    }
  }
 
  .ant-slider-mark-text {
    color: #5B86E5; // Mark text color
    font-size: 14px; // Mark font size
    font-weight: 600; // Mark font weight
  }
`;

// Styled InputNumber component
export const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    color: #5B86E5;
    font-size: 16px; // Increase font size
    font-weight: 600; // Increase font weight
  }
  .ant-input-number-handler-wrap {
    display: none; // Hide the handlers
  }
  border: 2px solid #5B86E5; // Border color
  border-radius: 5px; // Rounded corners
  width: 100%; // Full width
  text-align: center; // Center align text
`;

export const StyledModal = styled(Modal)`
.ant-modal-content {
  border-radius: 10px;
}
 
.ant-modal-header {
  border-radius: 10px 10px 0 0;
  background-color: #f0f2f5;
}
 
.ant-modal-footer {
  display: none; // Hide the footer if not used
}
`;

export const StyledCard = styled.div`
  // border-radius: 25px;
  border-radius: 10px;
//   width: 50%;
  margin-bottom: 35px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  font-family: poppins-font, sans-serif;
  font-size: 20px;
  // overflow-y: auto;
  scrollbar-color: #aaa white;
  scrollbar-width: thin;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  // max-height: 100vh;
 
  @media screen and (max-width: 768px) {
    max-height: 70vh;
  }
`;

export const StyledRow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledCol = styled.div`
  flex: 1;
  max-width: 900px;
  // margin-left: 18px;
//   margin-right: 18px;
  overflow-y: auto;
  scrollbar-color: #aaa white;
  scrollbar-width: thin;
 
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const StyledButton = styled(Button)`
margin-left: 80px;
display: flex;
align-items: center;
justify-content: center;
height: 45px;
padding: 0 24px;
font-size: 16px;
font-weight: bold;
border-radius: 8px;
background: linear-gradient(90deg, #4e54c8, #8f94fb);
border: none;
box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
transition: background 0.3s, box-shadow 0.3s;
 
&:hover {
  background: linear-gradient(90deg, #3e44b8, #7f84eb);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}
`;


export const StyledCSVLink = styled(CSVLink)`
  border-radius: 30px;
  // background: linear-gradient(90deg, #4e54c8, #8f94fb);
  background: linear-gradient(45deg, #ff512f, #dd2476);
  color: white;
  font-weight: 600;
  padding: 0.7rem 1rem;
  font-size: 18px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-top:10px;
 
  &:hover {
    background: linear-gradient(90deg, #dd2476, #ff512f);
    color: #ffffff;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
 
  @media screen and (max-width: 576px) {
    padding: 0.6rem 0.5rem;
    font-size: 14px;
  }
`;

export const DownloadBtn = styled(Button)`
  border-radius: 30px;
  background: linear-gradient(45deg, #ff512f, #dd2476);
  color: white;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-top:10px;
 
  &:hover {
    background: linear-gradient(90deg, #dd2476, #ff512f);
    color: #ffffff !important ;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;