import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRejectData,
  fetchSuccessData,
  fetchPendingData,
  fetchPendingForApprovalData,
  fetchRejectFilteredData,
  fetchSuccessFilteredData,
  fetchPendingFilteredData,
  fetchPendingForApprovalFilteredData,
  fetchPendingBaData
} from "../../../redux/slice/collectionReportSlice";
import { updateCollectionBA } from "../../../redux/slice/updateCollectionBA";
import { HashLoader } from "react-spinners";
import { fetchAllBA } from "../../../redux/slice/getAllBASlice";
import { InfoCircleOutlined } from "@ant-design/icons";
import { downloadCollectionReport } from "../../../redux/slice/downloadCollectionSlice";
import {
  Spin,
  Table,
  Card,
  Modal,
  Button,
  Input,
  Tabs,
  Upload,
  message,
  Tooltip,
  Radio,
  Typography,
  Divider,
  Form,
  Select,
  Row,
  Col,
  Empty,
  Alert,
  Space
} from "antd";
import { StyledTable, StyledButton } from "../styleComp";
import { useAuth } from "../../../context/AuthContext";
import jwt_decode from "jwt-decode";
const { Option } = Select;

const CollectionData = (props) => {
  // console.log('Collection props - ', props)
  const { authState } = useAuth();
  const dispatch = useDispatch();

  // Initialize state for pagination, filters, search inputs, and selected columns for each table
  const [state, setState] = useState({
    pages: {
      reject: { page: 1, pageSize: 10 },
      success: { page: 1, pageSize: 10 },
      pending: { page: 1, pageSize: 10 },
      pendingForApproval: { page: 1, pageSize: 10 },
    },
    filters: {
      reject: {},
      success: {},
      pending: {},
      pendingForApproval: {},
    },
    searchInputs: {
      reject: "",
      success: "",
      pending: "",
      pendingForApproval: "",
    },
    selectedColumns: {
      reject: "",
      success: "",
      pending: "",
      pendingForApproval: "",
    },
  });



  const [rejectFilter, setRejectFilter] = useState({});
  const [successFilter, setSuccessFilter] = useState({});
  const [pendingFilter, setPendingFilter] = useState({});
  const [pendingForApprovalFilter, setPendingForApprovalFilter] = useState({});
  const [rejectSearchInput, setRejectSearchInput] = useState({});
  const [successSearchInput, setSuccessSearchInput] = useState({});
  const [pendingSearchInput, setPendingSearchInput] = useState({});
  const [pendingForApprovalSearchInput, setPendingForApprovalSearchInput] =
    useState({});
  const [reassignModalVisible, setReassignModalVisible] = useState(false);
  const [modalColumns, setModalColumns] = useState([]);
  const [currentBA, setCurrentBA] = useState("");
  const [currentAppID, setCurrentAppID] = useState("");
  const [selectedBA, setSelectedBA] = useState("");
  const [appID, setAppID] = useState("");
  const [currentBACode, setCurrentBACode] = useState("");
  const [form] = Form.useForm();
  const [reassignType, setReassignType] = useState("");
  const [remarksValue, setRemarksValue] = useState("");
  const [remarksValueOther, setRemarksValueOther] = useState("");
  const [modalData, setModalData] = useState([]);
  const [modalDataSingle, setModalDataSingle] = useState([]);
  const [modalPagination, setModalPagination] = useState({
    pageSize: 10,
    current: 1,
    total: modalData.length,
  });

  const [triggerFilter, setTriggerFilter] = useState(null);

  const [baList, setBaList] = useState([]);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonLoadingPending, setButtonLoadingPending] = useState(false);
  const [buttonLoadingPendingForAck, setButtonLoadingPendingForAck] = useState(false);
  const [buttonLoadingPendingForAckNew, setButtonLoadingPendingForAckNew] = useState(false);
  const [buttonLoadingSuccess, setButtonLoadingSuccess] = useState(false);


  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var jwt_payload_group = [];
  var jwt_payload_user = "";
  var jwt_payload_role = "";
  var jwt_payload_expiry = [];
  if (jwtBearer) {
    jwt_payload = jwt_decode(jwtBearer);
    // jwt_payload_group = jwt_payload.group_id;
    jwt_payload_group = props.userDetails?.group_id;
    jwt_payload_user = jwt_payload.user_id;
    // jwt_payload_role = jwt_payload.role;
    jwt_payload_role = props.userDetails?.role;
    jwt_payload_expiry = jwt_payload.exp;
  }

  // useEffect(() => {
  //   if (jwtBearer) {
  //     // Fetch data for each table based on current pagination and filters
  //     Object.keys(state.pages).forEach((key) => {
  //       const { page, pageSize } = state.pages[key];
  //       const filter = state.filters[key];
  //       console.log('Filter form - ', filter)
  //       const fetchActions = {
  //         reject: fetchRejectData,
  //         success: fetchSuccessData,
  //         pending: fetchPendingData,
  //         pendingForApproval: fetchPendingForApprovalData,
  //       };

  //       dispatch(
  //         fetchActions[key]({
  //           jwtBearer,
  //           page,
  //           pageSize,
  //           filters: filter,
  //         })
  //       );
  //     });
  //   }
  // }, [jwtBearer, state.pages, state.filters, dispatch]);

  useEffect(() => {
    if (jwtBearer) {
      const { page, pageSize } = state.pages.reject;
      const filter = state.filters.reject;

      dispatch(fetchRejectData({
        jwtBearer,
        page,
        pageSize
      }));
    }
  }, [jwtBearer, state.pages.reject, state.filters.reject, dispatch]);

  useEffect(() => {
    if (jwtBearer) {
      const { page, pageSize } = state.pages.success;
      const filter = state.filters.success;

      dispatch(fetchSuccessData({
        jwtBearer,
        page,
        pageSize
      }));
    }
  }, [jwtBearer, state.pages.success, state.filters.success, dispatch]);

  useEffect(() => {
    if (jwtBearer) {
      const { page, pageSize } = state.pages.pending;
      const filter = state.filters.pending;

      dispatch(fetchPendingData({
        jwtBearer,
        page,
        pageSize
      }));
    }
  }, [jwtBearer, state.pages.pending, state.filters.pending, dispatch]);

  useEffect(() => {
    if (jwtBearer) {
      const { page, pageSize } = state.pages.pendingForApproval;
      const filter = state.filters.pendingForApproval;

      dispatch(fetchPendingForApprovalData({
        jwtBearer,
        page,
        pageSize
      }));
    }
  }, [jwtBearer, state.pages.pendingForApproval, state.filters.pendingForApproval, dispatch]);


  // Extract data and loading states from the Redux store
  const {
    rejectData,
    rejectCount,
    isLoadingReject,
    errorReject,
    successData,
    successCount,
    isLoadingSuccess,
    errorSuccess,
    pendingData,
    pendingCount,
    isLoadingPending,
    errorPending,
    pendingForApprovalData,
    pendingForApprovalCount,
    isLoadingPendingForApproval,
    errorPendingForApproval,
    rejectFilteredData,
    successFilteredData,
    pendingFilteredData,
    pendingForApprovalFilteredData,
    isLoadingRejectFiltered,
    isLoadingSuccessFiltered,
    isLoadingPendingFiltered,
    isLoadingPendingForApprovalFiltered, isLoadingPendingBA,
    errorPendingBA,
    pendingBAData
  } = useSelector((state) => state.collectionData);

  const stateC = useSelector((state) => state.collectionData);
  console.log("state", stateC)


  const defineColumns = useCallback((data) => {
    if (data.length === 0) return [];
    const keys = Object.keys(data[0]);
    return keys.map((key) => ({
      title: <strong style={{ fontSize: "18px" }}>{key.replace(/_/g, " ").toUpperCase()}</strong>,
      dataIndex: key,
      key: key,
      render: (text) => <span style={{ fontSize: "16px" }}>{text}</span>,
    }));
  }, []);

  const handleKeyDown = (event, type) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleFilter(type);
    }
  };

  const handleFilter = (type) => {
    const filterActions = {
      reject: fetchRejectFilteredData,
      success: fetchSuccessFilteredData,
      pending: fetchPendingFilteredData,
      pendingForApproval: fetchPendingForApprovalFilteredData,
    };

    dispatch(
      filterActions[type]({
        jwtBearer,
        page: state.pages[type].page,
        pageSize: state.pages[type].pageSize,
        filters: { [state.selectedColumns[type]]: state.searchInputs[type] },

      })
    );
  };

  // const handleReset = (type) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     searchInputs: {
  //       ...prevState.searchInputs,
  //       [type]: '',
  //     },
  //     selectedColumns: {
  //       ...prevState.selectedColumns,
  //       [type]: '',
  //     },
  //   }));
  //   handleFilter(type);
  // };


  const handleReset = (type) => {
    console.log('Before reset:', state);

    setState(prevState => {
      const newState = {
        ...prevState,
        searchInputs: {
          ...prevState.searchInputs,
          [type]: '',
        },
        selectedColumns: {
          ...prevState.selectedColumns,
          [type]: '',
        },
      };

      console.log('State after reset:', newState);
      return newState;
    });

    setTriggerFilter(type);
  };

  useEffect(() => {
    if (triggerFilter) {
      console.log('Filtering for:', triggerFilter);
      handleFilter(triggerFilter);
      setTriggerFilter(null); // Reset trigger
    }
  }, [triggerFilter]);



  // Configures pagination for each table
  const paginationConfig = (type) => {
    const total = {
      reject: rejectFilteredData ? rejectFilteredData.length : rejectCount,
      success: successFilteredData ? successFilteredData.length : successCount,
      pending: pendingFilteredData ? pendingFilteredData.length : pendingCount,
      pendingForApproval: pendingForApprovalFilteredData
        ? pendingForApprovalFilteredData.length
        : pendingForApprovalCount,
    }[type];

    return {
      current: state.pages[type].page,
      pageSize: state.pages[type].pageSize,
      total: total,
      showTotal: (total, [start, end]) => `${start}-${end} of ${total} items`,
      onChange: (page, pageSize) => setState((prevState) => ({
        ...prevState,
        pages: {
          ...prevState.pages,
          [type]: { page, pageSize },
        },
      })),
    };
  };

  const handleRemarksChange = (e) => {
    setRemarksValueOther(e.target.value);
  };

  const handleCancel = () => {
    setReassignModalVisible(false);
    form.resetFields();
    setSelectedBA("");
  };

  const handleReassignBA = async (record) => {
    try {
      // Set initial state for the reassign modal
      setAppID(record.application_id);
      setCurrentBACode(record.ba_code);
      setCurrentAppID(record.application_id);


      const resp = await dispatch(fetchPendingBaData({ jwtBearer, filters: { ba_code: record.ba_code } }));
      console.log('Data resp - ', resp?.payload?.pending?.data)

      const currentBAData = resp?.payload?.pending?.data
      // Dispatch fetchAllBA and await the response
      const baResponse = await dispatch(fetchAllBA({ jwtBearer, ba_code: record.ba_code, branch: record.branch }));

      if (baResponse.payload && baResponse.payload.message && baResponse.payload.message.BA) {
        setBaList(baResponse.payload.message.BA);
      } else {
        console.error("Unexpected response format from fetchAllBA", baResponse);
        message.error("Failed to load BA list.");
        return; // Exit early if the response format is not as expected
      }

      setCurrentBA(record.ba_code);
      setReassignModalVisible(true);

      console.log("dkjfldjlkadjf", pendingBAData)
      // Filter pendingBAData and pendingData based on the record
      const filteredPendingData = currentBAData.filter((item) => item.ba_code === record.ba_code);
      const filteredPendingDataSingle = currentBAData.filter((item) => item.application_id === record.application_id);

      setModalDataSingle(filteredPendingDataSingle);
      setModalData(filteredPendingData);

      // Set up columns for the modal table
      if (filteredPendingData.length > 0) {
        const modalKeys = Object.keys(filteredPendingData[0]);
        const modalColumns = modalKeys.map((key) => ({
          title: key.toUpperCase(),
          dataIndex: key,
          key,
        }));

        setModalColumns(modalColumns);
      } else {
        setModalColumns([]); // Handle case where no data is available
      }

      // Set up pagination for the modal table
      setModalPagination({
        pageSize: 10,
        current: 1,
        total: filteredPendingData.length,
      });

      // Logging for debugging purposes
      console.log("filteredPendingData", filteredPendingData);
      console.log("pending ba data", pendingBAData);
      console.log("modalColumns", modalColumns);

    } catch (error) {
      console.error("Error in handleReassignBA", error);
      message.error("An error occurred while reassigning BA.");
    }
  };

  // const handleReassign = () => {
  //   var requestBody = {};

  //   if (remarksValue != "others") {
  //     requestBody = {
  //       reassigned_by: jwt_payload_user,
  //       ba_code: selectedBA,
  //       current_ba: currentBACode,
  //       app_id: appID,
  //       reassign_type: reassignType,
  //       remarks: remarksValue,
  //     };
  //   } else {
  //     requestBody = {
  //       reassigned_by: jwt_payload_user,
  //       ba_code: selectedBA,
  //       current_ba: currentBACode,
  //       app_id: appID,
  //       reassign_type: reassignType,
  //       remarks: remarksValueOther,
  //     };
  //   }

  //   console.log("Selected BA", selectedBA);
  //   dispatch(updateCollectionBA({ jwt_token: authState.token, requestBody }));
  //   form.resetFields();

  //   handleCancel();

  //   setRemarksValue("");
  //   setReassignType("");
  // };

  const handleReassign = async () => {
    var requestBody = {};

    if (remarksValue != "others") {
      requestBody = {
        reassigned_by: jwt_payload_user,
        ba_code: selectedBA,
        current_ba: currentBACode,
        app_id: appID,
        reassign_type: reassignType,
        remarks: remarksValue,
      };
    } else {
      requestBody = {
        reassigned_by: jwt_payload_user,
        ba_code: selectedBA,
        current_ba: currentBACode,
        app_id: appID,
        reassign_type: reassignType,
        remarks: remarksValueOther,
      };
    }



    console.log("Selected BA", selectedBA);
    // dispatch(updateCollectionBA({ jwt_token: authState.token, ba_code: selectedBA, current_ba: currentBACode, app_id: appID, reassign_type: reassignType }));
    dispatch(updateCollectionBA({ jwt_token: authState.token, requestBody }));
    form.resetFields();

    handleCancel();
    // setReassignModalVisible(false)

    setRemarksValue("");
    setReassignType("");
    // Dispatch the updateCollectionBA action
    // await dispatch(updateCollectionBA({ jwt_token: authState.token, requestBody }));

    // Fetch the updated pending data
    await dispatch(fetchPendingData({ jwtBearer, page: state.pages.pending.page, pageSize: state.pages.pending.pageSize }));

    message.success('Reassignment successful');
  };


  const renderSearchBar = (columns, type) => (
    <Space style={{ marginBottom: 16, marginLeft: 2 }}>
      <Select
        placeholder="Select Column"
        style={{ width: 200 }}
        value={state.selectedColumns[type] || undefined}
        onChange={(value) =>
          setState((prevState) => ({
            ...prevState,
            selectedColumns: {
              ...prevState.selectedColumns,
              [type]: value,
            },
          }))
        }
      >
        {columns.map((col) => (
          <Option key={col.key} value={col.dataIndex}>
            {col.title}
          </Option>
        ))}
      </Select>
      <Input
        placeholder="Value"
        value={state.searchInputs[type]}
        onChange={(e) =>
          setState((prevState) => ({
            ...prevState,
            searchInputs: {
              ...prevState.searchInputs,
              [type]: e.target.value,
            },
          }))
        }
        onKeyDown={(e) => handleKeyDown(e, type)}
        style={{ width: 150 }}
        disabled={!state.selectedColumns[type]}
      />
      <Button
        type="primary"
        onClick={() => handleFilter(type)}
      >
        Search
      </Button>
      {(rejectFilteredData || successFilteredData || pendingFilteredData || pendingForApprovalFilteredData) && (
        <Button onClick={() => handleReset(type)}>
          Reset
        </Button>
      )}
    </Space>
  );

  const handleAPIDownload = (type) => {
    if (type == 'reject') {
      setButtonLoading(true)
    }
    else if (type == 'pending') {
      setButtonLoadingPending(true)
    }
    else if (type == 'pendingForApproval') {
      setButtonLoadingPendingForAck(true)
    }
    else if (type == 'pendingForAcknowledgement') {
      setButtonLoadingPendingForAckNew(true)
    }
    else if (type == 'success') {
      setButtonLoadingSuccess(true)
    }
    dispatch(downloadCollectionReport({ jwtBearer, type: type })).then(() => {
      setButtonLoading(false)
      setButtonLoadingPending(false)
      setButtonLoadingPendingForAck(false)
      setButtonLoadingPendingForAckNew(false)
      setButtonLoadingSuccess(false)
    })
  }

  const renderButtonByType = (type) => {
    switch (type) {
      case "reject":
        return (
          <StyledButton type="primary" onClick={() => handleAPIDownload(type)} loading={buttonLoading}>
            Download Report
          </StyledButton>
        );
      case "success":
        return (
          <StyledButton type="primary" onClick={() => handleAPIDownload(type)} loading={buttonLoadingSuccess}>
            Download Report
          </StyledButton>
        );
      case "pending":
        return (
          <StyledButton type="primary" onClick={() => handleAPIDownload(type)} loading={buttonLoadingPending}>
            Download Report
          </StyledButton>
        );
      case "pendingForApproval":
        return (
          <>
            <StyledButton type="primary" onClick={() => handleAPIDownload('pendingForAcknowledgement')} loading={buttonLoadingPendingForAckNew}>
              Download Receipt
            </StyledButton>
            <StyledButton type="primary" onClick={() => handleAPIDownload(type)} loading={buttonLoadingPendingForAck}>
              Download Report
            </StyledButton>
          </>
        );
      default:
        return (
          <StyledButton type="primary" onClick={() => handleAPIDownload(type)} loading={buttonLoading}>
            Download Default Report
          </StyledButton>
        );
    }
  };



  return (
    <>
      <div>
        {["reject", "success", "pending", "pendingForApproval"].map((type) => {
          const data = {
            reject: rejectFilteredData || rejectData,
            success: successFilteredData || successData,
            pending: pendingFilteredData || pendingData,
            pendingForApproval: pendingForApprovalFilteredData || pendingForApprovalData,
          }[type];
          const columns = defineColumns(data || []);

          if (type === "pending" && (jwt_payload_group === "5" || jwt_payload_group === "10" || jwt_payload_role === "BM")) {
            columns.push({
              title: <strong style={{ fontSize: "18px" }}>Reassign BA</strong>,
              dataIndex: "reassignBA",
              key: "reassignBA",
              width: "100px",
              render: (text, record) => (
                <Button type="primary" onClick={() => handleReassignBA(record)}>
                  Reassign
                </Button>
              ),
            });
          }

          const isLoading = {
            reject: isLoadingReject,
            success: isLoadingSuccess,
            pending: isLoadingPending,
            pendingForApproval: isLoadingPendingForApproval,
          }[type];
          const error = {
            reject: errorReject,
            success: errorSuccess,
            pending: errorPending,
            pendingForApproval: errorPendingForApproval,
          }[type];
          const isLoadingFiltered = {
            reject: isLoadingRejectFiltered,
            success: isLoadingSuccessFiltered,
            pending: isLoadingPendingFiltered,
            pendingForApproval: isLoadingPendingForApprovalFiltered,
          }[type];





          return (
            // <Card key={type} title={type.toUpperCase()} bordered
            <Card key={type}
              title={
                <span style={{ fontWeight: 600, fontSize: '24px', fontFamily: 'poppins-font, sans-serif' }}>
                  {type === 'success'
                    ? 'SUCCESS'
                    : type === 'reject'
                      ? 'REJECT'
                      : type === 'pending'
                        ? 'PENDING'
                        : 'PENDING FOR ACKNOWLEDGEMENT'}
                </span>
              }
              bordered
              extra={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {renderButtonByType(type)}
                </div>
              }
            >
              {renderSearchBar(columns, type)}
              {isLoadingFiltered || isLoading ? (
                <div className="spinner-container">
                  <HashLoader color="#f57171" />
                </div>
              ) : error ? (
                <Alert message={error} type="error" />
              ) : (
                <StyledTable
                  columns={columns}
                  dataSource={data}
                  pagination={paginationConfig(type)}
                  locale={{ emptyText: <Empty description="No data available" /> }}
                />
              )}
            </Card>
          );
        })}
      </div>

      <Modal
        visible={reassignModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="90%"
        style={{ maxWidth: 1300 }}
        // bodyStyle={{ padding: '16px' }}
        centered
      >
        <div style={{ overflowY: "auto" }}>
          <Row style={{ width: "100%" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <h2 style={{ textAlign: "center" }}>
                CASES ASSIGNED TO SELECTED BA
              </h2>
              <StyledTable
                columns={modalColumns}
                dataSource={modalData}
                pagination={modalPagination}
                bordered

              />
              <Form layout="vertical" style={{ padding: "3rem" }}>
                {/* Other form items go here if needed */}
                <Form.Item label={<strong style={{}}>Current BA -</strong>}>
                  <Input
                    disabled
                    value={currentBA}
                    style={{ color: "maroon" }}
                  />
                </Form.Item>

                <Form.Item label={<strong style={{}}>Reassign To -</strong>}>
                  <Select
                    placeholder="Select a new BA"
                    style={{ width: "100%" }}
                    value={selectedBA}
                    onChange={(value) => {
                      setSelectedBA(value);
                    }}
                  >
                    {Object.values(baList).map((ba) => (
                      <Select.Option key={ba}>{ba}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                {selectedBA && (
                  <>
                    <div>
                      <p>
                        <strong style={{}}>Reassign Option</strong>
                        <Tooltip
                          title={
                            <span>
                              Ressign this particular case -
                              <br /> <br />
                              Selecting 'Reassign this particular case' as
                              Reassign type will reassign only the currently
                              selected case to the selected BA.
                              <br /> <br />
                              Reassign all cases -
                              <br /> <br />
                              Selecting 'Reassign all cases' as Reassign type
                              will reassign all the cases assigned to the
                              current BA to the selected BA.
                            </span>
                          }
                        >
                          <InfoCircleOutlined
                            type="question-circle"
                            style={{
                              marginLeft: "5px",
                              cursor: "pointer",
                              color: "dodgerblue",
                              fontSize: "16px",
                            }}
                          />
                        </Tooltip>
                      </p>

                      {/* <Form.Item label={<strong style={{fontSize:"18px"}}>Assign Option</strong>}> */}
                      <Form.Item>
                        <Radio.Group
                          onChange={(e) => {
                            // console.log('Radio value:', e.target.value);
                            setReassignType(e.target.value);
                            // You can store the selected radio value in state if needed
                          }}
                        >
                          <Radio value="one">
                            Ressign this particular case
                          </Radio>
                          <Radio value="all">Reassign all cases</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </>
                )}
                {reassignType == "one" && (
                  <StyledTable
                    columns={modalColumns}
                    dataSource={modalDataSingle}
                    pagination={false}
                    bordered

                  // title={() => (
                  // <Typography.Title level={4} style={{ textAlign: 'center' }}>
                  //   CASES ASSIGNED TO SELECTED BA
                  // </Typography.Title>
                  // )}
                  />
                  // <Form.Item label={<strong style={{fontSize:"18px"}}>Selected Application ID -</strong>}>
                  //   <Input disabled value={currentAppID} style={{ color: 'maroon' }} />
                  // </Form.Item>
                )}

                {reassignType && (
                  <>
                    <div>
                      <p>
                        <strong style={{ fontSize: "18px" }}>
                          Why do you want to reassign this case
                        </strong>
                      </p>
                    </div>
                    <Form.Item>
                      <Radio.Group
                        onChange={(e) => {
                          setRemarksValue(e.target.value);
                        }}
                      >
                        <Radio value="Sourcing/Servicing BA has Resigned">
                          Sourcing/Servicing BA Resigned
                        </Radio>
                        <Radio value="Sourcing/Servicing BA has been transferred">
                          Sourcing/Servicing BA has been transferred
                        </Radio>
                        <Radio value="others">Others</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {remarksValue == "others" && (
                      <Form.Item
                        label={
                          <strong style={{ fontSize: "18px" }}>
                            Briefly describe why this case needs to be
                            reassigned.
                          </strong>
                        }
                        name="remarks"
                      >
                        <>
                          <Input.TextArea
                            style={{ height: "100px" }}
                            onChange={handleRemarksChange}
                          />
                        </>
                      </Form.Item>
                    )}
                  </>
                )}

                {remarksValue && (
                  <Form.Item>
                    <Button size="20" type="primary" onClick={handleReassign}>
                      Reassign Case
                    </Button>
                  </Form.Item>
                )}
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default CollectionData;