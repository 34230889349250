import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../apiConfig'

export const deactivatePortalUsers = createAsyncThunk(
  'deactivatePortalUsers',
  async ({ jwt_token, type, requestBody }) => {
    console.log('Request Body - ', requestBody)
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwt_token
      },
      body: JSON.stringify({ userid: requestBody })
    }

    try {
      const response = await fetch(
        `${BASE_URL}admin/deactivate/user/?type=${type}`,
        options
      )
      console.log('Request Options - ', options)
      console.log('REsponse -- ', response)
      return response.data
    } catch (error) {
      console.log('Error', error.response.data)
      alert(error.response.data.message)
      throw error.response.data
    }
  }
)

const deactivatePortalUsersSlice = createSlice({
  name: 'deactivatePortalUsers',
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: null
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(deactivatePortalUsers.pending, state => {
      state.isLoading = true
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(deactivatePortalUsers.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(deactivatePortalUsers.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.errorMessage = action.error.message
    })
  }
})

export default deactivatePortalUsersSlice.reducer
