import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../apiConfig'

export const uploadInventory = createAsyncThunk(
  'uploadInventory',
  async ({ jwt_token, type, requestBody }) => {
    console.log('Request Body - ', requestBody)
    const options = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwt_token,
        'Content-Type': 'application/json' // Set content type to JSON
      },
      body: JSON.stringify({ it_inventory_json: requestBody }) // Wrap requestBody as needed
    }

    try {
      const response = await fetch(
        `${BASE_URL}inventory/upload/?type=${type}`,
        options
      )
      console.log('Request Options - ', options)
      console.log('REsponse -- ', response)
      return response.json() // Assuming the response is JSON
    } catch (error) {
      console.log('Error', error)
      alert(error.message)
      throw error
    }
  }
)

const uploadInventorySlice = createSlice({
  name: 'uploadInventory',
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: null
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(uploadInventory.pending, state => {
      state.isLoading = true
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(uploadInventory.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(uploadInventory.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.errorMessage = action.error.message
    })
  }
})

export default uploadInventorySlice.reducer
