import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../apiConfig'

export const fetchFilterDetails = createAsyncThunk(
  'fetchFilterDetails',
  async ({ jwtBearer }) => {
    const response = await fetch(`${BASE_URL}filter/branch_product/details`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
      }
    })
    return response.json()
  }
)

const filterDetailsSlice = createSlice({
  name: 'filterDetails',
  initialState: {
    isLoading: false,
    data: null,
    isError: false
  },
  extraReducers: builder => {
    builder.addCase(fetchFilterDetails.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchFilterDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(fetchFilterDetails.rejected, (state, action) => {
      console.log('Error', action.payload)
      state.isError = true
    })
  }
})

export default filterDetailsSlice.reducer
