import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Typography, Table, Tabs, Button, Input, Tag, Card, Form, Divider, Progress, Row, Col, Radio, Modal, Select, Steps, Spin, FloatButton, Slider, InputNumber, Collapse, Drawer, Alert } from 'antd';
import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { StyledModal, StyledCard, StyledRow, StyledCol, StyledTable, RefreshButton, StyledButton } from "../style/styleComp";
import { useAuth } from "../../../context/AuthContext";
import { fetchChatData } from '../../../redux/slice/getChatData';
import { fetchBranch } from "../../../redux/slice/getBranchData";
import { fetchBankVerification } from '../../../redux/slice/fetchBankVerification';
import { fetchFieldMaster } from '../../../redux/slice/getFieldMasterData';
import dayjs from 'dayjs';
import { useSpring, animated } from 'react-spring';
import Notification from '../components/notification';
import { HashLoader } from 'react-spinners'
import UserTabAssigned from '../components/userTabAssigned';
import '../style/customStyles.css'
import { setCollapsed } from '../../../redux/slice/sidenavCollapseSlice';

const { Text } = Typography;

const { TabPane } = Tabs;

const { Panel } = Collapse;

const App = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [activeTabKey, setActiveTabKey] = useState('allTables');
  const [tabList, setTabList] = useState([{ key: 'allTables', title: 'CMA Leads' }]);
  const [activeRowData, setActiveRowData] = useState(null);
  const [selectedChatData, setSelectedChatData] = useState(null);
  const [selectedUserChat, setSelectedUserChat] = useState(null);
  const [branchData, setBranchData] = useState({});
  const [custMobile, setCustMobile] = useState('');
  const [secondaryContact, setSecondaryContact] = useState('')
  const [custName, setCustName] = useState('');
  const [custIndustry, setCustIndustry] = useState('');
  const [selectedRefNum, setSelectedRefNum] = useState("");
  const [purposeOfLoan, setPurposeOfLoan] = useState("");
  const [businessExperienceYears, setBusinessExperienceYears] = useState("");
  const [businessPresentExperienceYears, setBusinessPresentExperienceYears] = useState("");
  const [custLoanAmt, setCustLoanAmt] = useState("");
  const [custAffordableEMI, setCustAffordableEMI] = useState("");
  const [custMothersName, setCustMothersName] = useState("");
  const [custGender, setCustGender] = useState("");
  const [custDOB, setCustDOB] = useState("")
  const [custMaritalStatus, setCustMaritalStatus] = useState("");
  const [custSpouseName, setCustSpouseName] = useState("");
  const [custChildren, setCustChildren] = useState("");
  const [custGST, setCustGST] = useState("");
  const [custResidenceAddress, setCustResidenceAddress] = useState("");
  const [custWorkAddress, setCustWorkAddress] = useState("");
  const [custResidenceOwnership, setCustResidenceOwnership] = useState("");
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [selectedUserChatData, setSelectedUserChatData] = useState([]);
  const [customerAvailability, setCustomerAvailability] = useState("")
  const [rescheduleDate, setRescheduleDate] = useState("");
  const [loanAmountValue, setLoanAmountValue] = useState("")
  const [isLoanAmountValid, setIsLoanAmountValid] = useState(false);
  const [isAffordableEMIValid, setIsAffordableEMIValid] = useState(false);
  const [isTenureValid, setIsTenureValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [customerFeedbackValue, setCustomerFeedbackValue] = useState('');
  const [customerBureauFeedbackValue, setCustomerBureauFeedbackValue] = useState('');
  const [selectedDateTime, setSelectedDateTime] = useState(dayjs());
  const [showChatModal, setShowChatModal] = useState(false);
  const [editableMessageIndex, setEditableMessageIndex] = useState(-1);
  const [editableInputValue, setEditableInputValue] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [imgIsModalVisible, setImgIsModalVisible] = useState(false);
  const [leadDecisionValue, setLeadDecisionValue] = useState("")
  const [branchToAssign, setBranchToAssign] = useState(null)
  const [showBranchSelect, setShowBranchSelect] = useState(false)
  const [bMToAssign, setBMToAssign] = useState("")
  const [originalMessage, setOriginalMessage] = useState("");
  const [originalTimeStamp, setOriginalTimeStamp] = useState("")
  const [originalQuesID, setOriginalQuesID] = useState("")
  const [stringDateTime, setStringDateTime] = useState("")
  const [tableData, setTableData] = useState([]);
  const [affordableEMIValue, setAffordableEMIValue] = useState("")
  const [tenureValue, setTenureValue] = useState("")
  const [affordableEMIMin, setAffordableEMIMin] = useState(null)
  const [minLoanAmt, setMinLoanAmt] = useState(null)
  const [maxLoanAmt, setMaxLoanAmt] = useState(null)
  const [affordableEMIMax, setAffordableEMIMax] = useState(null)
  const [loanAmountMin, setLoanAmountMin] = useState(null)
  const [loanAmountMax, setLoanAmountMax] = useState(null)
  const [fieldMaster, setFieldMaster] = useState([])
  const [reasonToReject, setReasonToReject] = useState('')
  const [recommendedProduct, setRecommendedProduct] = useState('')
  const [editAllowed, setEditAllowed] = useState(false)
  const [branchSelection, setBranchSelection] = useState('')
  const [nearestBranchesArray, setNearestBranchesArray] = useState([])

  const [showRescheduleCard, setShowRescheduleCard] = useState(false);
  const [showAvailableCard, setShowAvailableCard] = useState(false);
  const [showChatCard, setShowChatCard] = useState(false);
  const [showDocumentCard, setShowDocumentCard] = useState(false);

  const [showSecondTab, setShowSecondTab] = useState(false);
  const [allTablesKey, setAllTablesKey] = useState('allTables');

  const [notificationQueue, setNotificationQueue] = useState([]);
  const [currentNotification, setCurrentNotification] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const intervalRef = useRef(); // useRef correctly defined
  const [notificationMessage, setNotificationMessage] = useState('');
  const [loadingNotificationMessage, setLoadingNotificationMessage] = useState('')
  const [loadingNotification, setLoadingNotification] = useState(false)

  const [showCustChat, setShowCustChat] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [EditCustLoanAmt, setEditCustLoanAmt] = useState("");
  const [showUnsavedModal, setShowUnsavedModal] = useState(false)
  const [crifResponse, setCrifResponse] = useState('')
  const [custCRIFResponse, setCustCRIFResponse] = useState(null)
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerContent, setDrawerContent] = useState('');
  const [salarySource, setSalarySource] = useState('')
  const [emailModalVisible, setEmailModalVisible] = useState(false)
  const [aadhaarModalVisible, setAadhaarModalVisible] = useState(false)
  const [bankingModalVisible, setbankingModalVisible] = useState(false)
  const [custAccountNumber, setCustAccountNumber] = useState('')
  const [custEmailID, setCustEmailID] = useState('')
  const [custIFSC, setCustIFSCNumber] = useState('')
  const [accountErrorMessage, setAccountErrorMessage] = useState('')
  const [custAccountNumberValid, setCustAccountNumberValid] = useState(false)
  const [bankVerificationSuccess, setBankVerificationSuccess] = useState(false)
  const [bankVerificationError, setBankVerificationError] = useState(false)
  const [bankVerificationAPIFailure, setBankVerificationAPIFailure] = useState(false)
  const [bankVerificationErrorMsg, setBankVerificationErrorMsg] = useState('')
  const [tabRerenderKey, setTabRerenderKey] = useState(0); // New state to force re-render

  // Convert reschedule_datetime string to Date object
  const parseRescheduleDatetime = (datetime) => moment(datetime, 'YYYY-MM-DD hh:mm A');

  useEffect(() => {
    console.log('Appointment triggered');

    intervalRef.current = setInterval(() => {
      const now = moment();

      tableData.forEach((item) => {
        const rescheduleTime = parseRescheduleDatetime(item.reschedule_datetime);
        let timeDiff = rescheduleTime.diff(now, 'minutes');
        let message = '';

        console.log('Appointment triggered time diff - ', timeDiff)

        if (timeDiff > 0) {
          if (timeDiff <= 15) {
            message = `Appointment for ${item.cust_name} is in less than ${timeDiff} minutes`;
          }
        } else {
          timeDiff = Math.abs(timeDiff);
          if (timeDiff < 60) {
            timeDiff = Math.floor(timeDiff / 60);
            message = `Appointment for ${item.cust_name} is due over ${timeDiff} minutes`;
          } else if (timeDiff > 60 && timeDiff < 1440) {
            timeDiff = Math.floor(timeDiff / 60);
            message = `Appointment for ${item.cust_name} is due over ${timeDiff} hours`;
          } else if (timeDiff > 1440) {
            timeDiff = Math.floor(timeDiff / 1440);
            message = `Appointment for ${item.cust_name} is due over ${timeDiff} days`;
          }
        }

        if (message) {
          setNotificationQueue((prevQueue) => [...prevQueue, message]);
        }
      });
      // }, 10000); // Check every 10 seconds
    }, 1000 * 60 * 5); // Check every minute

    return () => clearInterval(intervalRef.current); // Cleanup interval
  }, [tableData, parseRescheduleDatetime]);

  useEffect(() => {
    if (notificationQueue.length > 0 && !currentNotification) {
      setCurrentNotification(notificationQueue[0]);
      setNotificationQueue((prevQueue) => prevQueue.slice(1));
      setShowNotification(true);

      const timer = setTimeout(() => {
        setShowNotification(false);
        setCurrentNotification(null);
      }, 10000); // Show each notification for 10 seconds

      return () => clearTimeout(timer);
    }
  }, [notificationQueue, currentNotification]);

  const floatButtonProps = useSpring({
    to: { transform: 'translateY(0px)', opacity: 1 },
    from: { transform: 'translateY(100px)', opacity: 0 },
    config: { tension: 200, friction: 10 },
  });


  const onNotifClose = (e) => {
    setShowNotification(false)
    setCurrentNotification(null);
  }

  const { authState, onLogout } = useAuth();

  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var jwt_payload_group = [];
  var user_id = "";
  var user_role = ""
  var user_name = ""
  if (jwtBearer) {
    jwt_payload = jwt_decode(jwtBearer);
    // user_name = jwt_payload.username;
    user_name = props.userDetails?.username;
    user_id = jwt_payload.user_id;
    // user_role = jwt_payload.role;
    user_role = props.userDetails?.role;
    // jwt_payload_group = jwt_payload.group_id;
    jwt_payload_group = props.userDetails?.group_id;
  }

  // Function to fetch chat data
  const fetchData = () => {
    console.log('Fetching chat data...');
    setLoading(true);
    dispatch(fetchChatData({ jwtBearer, user_id: user_id, user_role: user_role, chat_type: 'Generated' }))
      .then(() => {
        console.log('Chat data fetched successfully.');
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chat data:", error);
        setLoading(false);
      });
  };

  // Initial fetch on component mount
  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 1000 * 60 * 5); // 5 minutes interval

    return () => {
      clearInterval(intervalId);
      console.log('Interval cleared.');
    };
  }, [dispatch]); // Fetch data initially and set up interval

  // Handle refresh button click
  const handleRefresh = () => {
    fetchData();
  };

  const { data: chatData } = useSelector((state) => state.fetchChatData);

  if (chatData && chatData.length > 0) {
    console.log('Chat data - ', chatData[0])
  }

  // Function to format date
  function formatDate(isoString) {
    console.log('Time to convert - ', isoString);
    if (!isoString) {
      return ''; // Return empty string if dateString is null or undefined
    }
    const date = new Date(isoString);

    // Check for invalid date
    if (isNaN(date.getTime())) {
      return ''; // Return empty string if date is invalid
    }

    // Extract individual date and time components
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 24-hour time to 12-hour time
    hours = String(hours).padStart(2, '0');

    // Construct the formatted date string
    return `${month}-${day}-${year}, ${hours}:${minutes} ${ampm}`;
  }

  // Function to calculate percentage completed
  function calculatePercentageCompleted(profile) {
    const fields = [
      'full_name',
      'father_name',
      'reg_phone',
      'mother_name',
      'date_of_birth',
      'marital_status',
      'aadhaar_link_front',
      'aadhaar_link_back',
      'pan_link',
      'cheque_link',
      'photo_link'
    ];

    let filledCount = 0;

    // Check if each field is filled
    fields.forEach((field) => {
      if (profile[field] && profile[field].trim() !== '') {
        filledCount++;
      }
    });

    return Math.floor((filledCount / fields.length) * 100); // Calculate percentage
  }

  useEffect(() => {
    console.log('State refreshed');
    if (chatData && chatData.length > 0) {
      const formattedData = chatData[0].map((item) => {
        // Collect necessary fields for percentage calculation
        const profile = {
          ...item.chat_user_profile,
          ...item.images // Include image links in profile data
        };

        return {
          uuid: item.uuid,
          Reference_number: item.req_loan_det.ref_no,
          cust_name: item.chat_user_profile.full_name,
          username: item.chat_user_profile.reg_phone,
          requestedLoanAmounts: item.telecaller_loan_amount,
          affordableEMI: item.affordableEMI,
          branch: item.branch,
          reschedule_datetime: item.reschedule_datetime,
          availability: item.availability,
          percentageCompleted: calculatePercentageCompleted(profile) // Calculate completion percentage
        };
      });
      setTableData(formattedData);
    }
  }, [chatData]);

  // console.log('Table data - ', tableData)

  const handleUserTabData = (data) => {
    setCurrentNotification(`Lead has been processed successfully for ${data.Customer_name}`)
    setShowNotification(true)
    // // Hide notification after 3 seconds
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
    console.log("Received data from UserTab:", data);
    const tabKeyToRemove = `details-${data.Reference_number}`;

    setTabList((prevTabs) => {
      return prevTabs.filter(tab => tab.key !== tabKeyToRemove);
    });
    setShowSecondTab(false);
    setActiveTabKey('allTables');
    // Process the data as needed
  };

  const handleUserTabDataReschedule = (data) => {
    console.log("Received data from UserTab:", data);
    const tabKeyToRemove = `details-${data.Reference_number}`;

    setTabList((prevTabs) => {
      return prevTabs.filter(tab => tab.key !== tabKeyToRemove);
    });
    setShowSecondTab(false);
    setActiveTabKey('allTables');
    // Process the data as needed
  };

  const handleUserTabDataAvailability = (data) => {
    console.log("Received data from UserTab:", data);
    const tabKeyToRemove = `details-${data.Reference_number}`;

    setTabList((prevTabs) => {
      return prevTabs.filter(tab => tab.key !== tabKeyToRemove);
    });
    setShowSecondTab(false);
    setActiveTabKey('allTables');
    // Process the data as needed
  };

  const handleRowClick = async (record) => {

    console.log('Selected record - ', record)
    dispatch(setCollapsed("collapsed"));
    setCustomerAvailability(null)
    setCustomerFeedbackValue('')
    setLoanAmountValue('')
    setBranchToAssign('')
    setLeadDecisionValue('')
    setShowSecondTab(true);

    // const matchingChat = chatData[0].find(chat => chat.req_loan_det.ref_no === record.Reference_number);
    const matchingChat = chatData[0].find(chat => chat.uuid === record.uuid);
    const userChat = chatData[1].find(chat => chat.uuid === record.uuid);
    console.log('User chat - ', userChat)

    if (matchingChat) {
      // Set the selected chat data and demographic details
      setSelectedChatData(matchingChat);
    } else {
      // Handle case where no matching chat data is found
      setSelectedChatData(null);
    }

    if (userChat) {
      console.log('In user chat found')
      // Set the selected chat data and demographic details
      setSelectedUserChat(userChat);
    } else {
      // Handle case where no matching chat data is found
      setSelectedUserChat(null);
    }

    const branchResponse = await dispatch(fetchBranch({ jwtBearer }));
    const fieldMasterResponse = await dispatch(fetchFieldMaster({ jwtBearer }))
    const fieldMasterDataResponse = fieldMasterResponse.payload
    setFieldMaster(fieldMasterDataResponse)
    console.log('field Master - ', fieldMasterDataResponse)
    const branchDataResponse = branchResponse.payload.message;

    const btrimData = branchDataResponse.btrim || {};

    // Assuming bm_emp_code and bm_name are provided in the same format as btrimData
    const bmEmpCodeData = branchDataResponse.bm_emp_code || {};
    const bmNameData = branchDataResponse.bm_name || {};

    // Create a mapping of btrim values to bm_emp_code and bm_name
    const branchMapping = {};
    Object.entries(btrimData).forEach(([key, value]) => {
      branchMapping[value] = {
        bmEmpCode: bmEmpCodeData[key] || '',
        bmName: bmNameData[key] || ''
      };
    });

    // Set the branch data in state
    setBranchData(branchMapping);

    // const selectedUserData = chatData.filter((item) => item.username === selectedUsername);
    const selectedUserData = chatData && chatData.length > 0 ? chatData[0].find(chat => chat.req_loan_det.ref_no === record.Reference_number) : {};
    const selectedUserChatData = chatData && chatData.length > 0 ? chatData[1].find(chat => chat.uuid === record.uuid) : {};

    console.log("Selected user data:", selectedUserData);
    setSelectedUserData(selectedUserData); // Update selectedUserData state
    setSelectedUserChatData(selectedUserChatData)

    // Multiple tabs logic
    const userTabContent = (
      <UserTabAssigned
        selectedUserData={selectedUserData}
        selectedUserChatData={selectedUserChatData}
        // activeRowData={record}
        fieldMaster={fieldMasterDataResponse}
        branchData={branchMapping}
        nearestBranchData={branchData}
        onSendData={handleUserTabData}
        onSendDataReschedule={handleUserTabDataReschedule}
        onSendDataAvailability={handleUserTabDataAvailability}
      />
    );

    const selectedTabKey = `details-${record.Reference_number}`;
    console.log('Selected tab - ', selectedTabKey)

    setTabList((prevTabs) => {
      const isTabExist = prevTabs.some(tab => tab.key === selectedTabKey);
      if (!isTabExist) {
        return [
          ...prevTabs,
          { key: selectedTabKey, title: record.cust_name, content: userTabContent }
        ];
      } else {
        // Update content if the tab already exists
        return prevTabs.map(tab =>
          tab.key === selectedTabKey ? { ...tab, content: userTabContent } : tab
        );
      }
    });
    setActiveTabKey(selectedTabKey)
    // Multiple tabs logic

    setActiveRowData(record);
    // setActiveTabKey('details');
  };

  const handleTabChange = (key) => {

    setActiveTabKey(key);
  };

  const handleTabEdit = (targetKey, action) => {
    if (action === 'remove') {
      setTabList((prev) => prev.filter(tab => tab.key !== targetKey));
      if (activeTabKey === targetKey) {
        setActiveTabKey('allTables');
      }
    }
  };

  // Helper functions for rendering tags based on time difference
  const renderTimeDifferenceTag = (diffInMinutes) => {
    if (diffInMinutes < 15) {
      return createTag("green", "< 15 minutes");
    } else if (diffInMinutes < 30) {
      return createTag("green", "< 30 minutes");
    } else if (diffInMinutes < 60) {
      return createTag("orange", "30 mins to 1 hr");
    } else if (diffInMinutes < 1440) { // Less than 1 day
      return createTag("red", `${Math.floor(diffInMinutes / 60)} hr${Math.floor(diffInMinutes / 60) > 1 ? 's' : ''}`);
    } else if (diffInMinutes < 10080) { // Less than 1 week
      return createTag("blue", `${Math.floor(diffInMinutes / 1440)} day${Math.floor(diffInMinutes / 1440) > 1 ? 's' : ''}`);
    } else if (diffInMinutes < 43200) { // Less than 1 month
      return createTag("purple", `${Math.floor(diffInMinutes / 10080)} week${Math.floor(diffInMinutes / 10080) > 1 ? 's' : ''}`);
    } else {
      return createTag("black", `${Math.floor(diffInMinutes / 43200)} month${Math.floor(diffInMinutes / 43200) > 1 ? 's' : ''}`);
    }
  };

  // Create a Tag component
  const createTag = (color, text) => (
    <span className="poppins-font" style={{ fontSize: '18px' }}>
      <Tag color={color} style={{ fontSize: "16px", lineHeight: "32px", padding: "0 16px" }}>{text}</Tag>
    </span>
  );

  const renderNoActivityTag = () => (
    <span className="poppins-font" style={{ fontSize: '18px' }}>
      <Tag color="gray" style={{ fontSize: "16px", lineHeight: "32px", padding: "0 16px" }}>{"No activity"}</Tag>
    </span>
  );

  const [filteredInfo, setFilteredInfo] = useState({});

  // Helper function to get unique values for filtering
  const getUniqueValues = (dataIndex) => {
    return [...new Set(data.map(item => item[dataIndex]))].map(value => ({
      text: value,
      value,
    }));
  };

  // Function to reset filters
  const clearFilters = () => {
    setFilteredInfo({});
  };

  // Define your column filter properties
  const getColumnFilterProps = (dataIndex) => ({
    filters: getUniqueValues(dataIndex),
    filteredValue: filteredInfo[dataIndex] || null,
    onFilter: (value, record) => record[dataIndex].toString().includes(value),
  });

  const columns = [
    {
      title: <strong style={{ fontSize: '18px' }}>REFERENCE NUMBER</strong>,
      dataIndex: "Reference_number",
      key: "Reference_number",
      // ...getColumnFilterProps('Reference_number'),
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: '18px' }}>
          {text}</span>
      ),
    },
    {
      title: <strong style={{ fontSize: '18px' }}>CUSTOMER NAME</strong>,
      dataIndex: "cust_name",
      key: "cust_name",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: '18px' }}>
          {text}</span>
      ),
    },
    {
      title: <strong style={{ fontSize: '18px' }}>CONTACT NUMBER</strong>,
      dataIndex: "username",
      key: "username",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: '18px' }}>
          {text}</span>
      ),
    },
    {
      title: <strong style={{ fontSize: '18px' }}>ACTUAL LOAN AMOUNT</strong>,
      dataIndex: "requestedLoanAmounts",
      key: "requestedLoanAmounts",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: '18px' }}>
          {text}</span>
      ),
    },
    {
      title: <strong style={{ fontSize: '18px' }}>AFFORDABLE EMI</strong>,
      dataIndex: "affordableEMI",
      key: "affordableEMI",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: '18px' }}>
          {text}</span>
      ),
    },
    {
      title: <strong style={{ fontSize: '18px' }}>BRANCH</strong>,
      dataIndex: "branch",
      key: "branch",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: '18px' }}>
          {text}</span>
      ),
    },
    // {
    //   title: <strong style={{ fontSize: '18px' }}>LAST ACTION</strong>,
    //   dataIndex: "lastAction",
    //   key: "lastAction",
    //   render: (text) => (
    //     // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
    //     <span className="poppins-font" style={{ fontSize: '18px' }}>
    //       {text}</span>
    //   ),
    // },
    // {
    //   title: <strong style={{ fontSize: '18px' }}>TIME SINCE LAST ACTION</strong>,
    //   dataIndex: "timeSinceLastAction",
    //   key: "timeSinceLastAction",
    //   render: (text, record) => {
    //     const lastAction = record.lastAction;
    //     if (lastAction) {
    //       // Parse the ISO 8601 timestamp directly
    //       const lastTimestamp = moment(lastAction);
    //       const now = moment();

    //       // Calculate the time difference
    //       const diffInMinutes = now.diff(lastTimestamp, 'minutes');

    //       console.log('Parsed last action timestamp:', lastTimestamp.format('MM-DD-YYYY, hh:mm A'));
    //       // console.log('Time difference in minutes:', diffInMinutes);

    //       return renderTimeDifferenceTag(diffInMinutes);
    //     } else {
    //       return renderNoActivityTag();
    //     }
    //   },
    // },
    // {
    //   title: <strong style={{ fontSize: '18px' }}>TIME SINCE LAST ACTION</strong>,
    //   dataIndex: "timeSinceLastAction",
    //   key: "timeSinceLastAction",
    //   render: (text, record) => {
    //     const lastAction = record.lastAction;
    //     if (lastAction) {
    //       // Parse the timestamp using the specified format
    //       const lastTimestamp = moment(lastAction, 'MM-DD-YYYY, hh:mm A');
    //       const now = moment();
    //       const diffInMinutes = now.diff(lastTimestamp, 'minutes');

    //       console.log('Parsed last action timestamp:', lastTimestamp.format('MM-DD-YYYY, hh:mm A'));
    //       // console.log('Time difference in minutes:', diffInMinutes);

    //       return renderTimeDifferenceTag(diffInMinutes);
    //     } else {
    //       return renderNoActivityTag();
    //     }
    //   },
    // },
    // {
    //   title: <strong style={{ fontSize: '18px' }}>RESCHEDULE DATE-TIME</strong>,
    //   dataIndex: "reschedule_datetime",
    //   key: "reschedule_datetime",
    //   render: (text) => (
    //     <span className="poppins-font" style={{ fontSize: '18px' }}>
    //       {text}</span>
    //   ),
    // },
    // {
    //   title: <strong style={{ fontSize: '18px' }}>AVAILABILITY</strong>,
    //   dataIndex: "availability",
    //   key: "availability",
    //   render: (text) => (
    //     <span className="poppins-font" style={{ fontSize: '18px' }}>
    //       {text}</span>
    //   ),
    // },
    {
      title: <strong style={{ fontSize: '18px' }}>PERCENTAGE</strong>,
      dataIndex: "percentageCompleted",
      key: "percentageCompleted",
      render: (percentage) => (
        <Progress
          type="circle"
          percent={percentage}
          width={60} // Increase width
          strokeWidth={10} // Increase stroke width
          format={(percent) => (
            <span style={{ fontWeight: 'bold' }}>{percent}%</span> // Make text bold
          )}
          strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
        />
      ),
    },
  ];

  const handleEmailIDChange = (e) => {
    const value = e.target.value;

    // Ensure only numbers are entered
    if (/^\d*$/.test(value)) {
      setCustAccountNumber(value); // Store value as a string
      setCustAccountNumberValid(true);
      setAccountErrorMessage('');
    } else {
      setCustAccountNumber('');
      setCustAccountNumberValid(false);
      setAccountErrorMessage('Please enter a valid number.');
    }
  };

  return (
    <>
      <Tabs
        activeKey={activeTabKey}
        onChange={handleTabChange}
        type="editable-card"
        // type="card"
        onEdit={handleTabEdit}
      >
        {tabList.map(tab => (
          <TabPane
            tab={<p style={{ fontSize: '16px' }}><strong>{tab.title}</strong></p>}
            key={tab.key}
            closable={tab.key !== 'allTables'}
          >
            {tab.key === 'allTables' ? (
              loading ? (
                <div className='spinner-container'>
                  <HashLoader color="#36d7b7" />
                </div>
              ) : (
                <>
                  <RefreshButton type="primary" onClick={handleRefresh} loading={loading}>
                    Refresh
                  </RefreshButton>
                  <StyledTable
                    columns={columns}
                    dataSource={tableData}
                  // onRow={(record) => ({
                  //   onClick: () => handleRowClick(record),
                  // })}
                  />
                </>
              )
            ) : (
              tab.content
            )}
          </TabPane>
        ))}
      </Tabs>
      <animated.div style={{ ...floatButtonProps, position: 'fixed', bottom: '20px', right: '20px' }}>
        {/* <Notification message={notificationMessage} show={showNotification} onClose={onNotifClose} /> */}
        <Notification message={currentNotification} show={showNotification} onClose={onNotifClose} />
      </animated.div>
      <animated.div style={{ ...floatButtonProps, position: 'fixed', bottom: '20px', right: '20px' }}>
        <Notification message={loadingNotificationMessage} show={loadingNotification} />
      </animated.div>

      <Modal
        visible={emailModalVisible}
        title={<p style={{ fontSize: '20px', fontWeight: '700', color: 'grey' }}>Verify Email ID</p>}
        footer={null}

        onCancel={() => setEmailModalVisible(false)}
      >
        <Divider style={{ borderColor: 'lightgrey' }} />
        <Form style={{ marginLeft: '10px', marginRight: '20px' }} form={form} layout="vertical">
          <Form.Item label={<strong style={{ fontSize: '20px', fontWeight: '700', color: 'grey' }}>Email ID</strong>} help={accountErrorMessage}>
            <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={custEmailID} onChange={handleEmailIDChange} />
          </Form.Item>
          {custEmailID && (
            <Button
              type="primary"
              onClick={handleInitiateBankVerification}
              htmlType="submit"
              style={{ marginLeft: "80px", justifyContent: 'flex-end' }}
            >
              Submit Email
            </Button>
          )}
        </Form>
        {bankVerificationSuccess && (
          <Alert
            style={{ marginTop: '20px' }}
            message="Success"
            description="Your bank account has been verified successfully."
            type="success"
            showIcon
          />
        )}
        {bankVerificationError && (
          <Alert
            style={{ marginTop: '20px' }}
            message="Bank verification failed. Please find the reason below -"
            description={bankVerificationErrorMsg}
            type="warning"
            showIcon
          />
        )}
      </Modal>
    </>
  );
};

export default App;
