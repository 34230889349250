import { Col, Table, Button, Form, Input, Select, Modal } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAdmin } from '../../../redux/slice/adminSlice'
import { useAuth } from '../../../context/AuthContext'
import { createGroup } from '../../../redux/slice/createGroupSlice'
import moment from 'moment'

function TableRefresh () {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const [tableData, setTableData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalRowData, setModalRowData] = useState({})

  const { authState } = useAuth()
  const dispatch = useDispatch()

  var jwt_token = authState.token
  console.log('Admin Auth Token - ', jwt_token)

  const onFinish = async values => {
    const { groupid, groupname } = values.group

    const requestBody = {
      group_id: groupid,
      group_name: groupname
    }

    console.log('Request body - ', requestBody)
    const jsonreq = JSON.stringify(requestBody)
    console.log('JSON Request - ', jsonreq)

    try {
      const response = await dispatch(
        createGroup({
          jwt_token: authState.token, // Pass the jwt_token as an argument
          requestBody: requestBody // Pass the requestBody as an argument
        })
      )

      console.log(response)
      // form.setFieldsValue({ user: {} })
      // setFormData({})
      form.resetFields()
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  }

  const handleDelete = record => {
    setModalRowData(record)
    setIsModalVisible(true)
  }

  const handleModalConfirm = () => {
    const updatedTableData = tableData.filter(
      data =>
        data.tablename !== modalRowData.tablename ||
        data.refreshTime !== modalRowData.refreshTime
    )
    setTableData(updatedTableData)
    setIsModalVisible(false)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  //   const handleRowSelectionChange = selectedRowKeys => {
  //     setSelectedRowKeys(selectedRowKeys)
  //   }

  const columns = [
    {
      title: 'Table Name',
      dataIndex: 'tablename',
      key: 'tablename'
    },
    {
      title: 'Refresh Time',
      dataIndex: 'refreshTime',
      ley: 'refreshTime'
    },
    // {
    //   title: 'Start Time',
    //   dataIndex: 'starttime',
    //   key: 'starttime'
    // },
    // {
    //   title: 'Time Interval',
    //   dataIndex: 'timeinterval',
    //   key: 'timeinterval'
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Button type='link' danger onClick={() => handleDelete(record)}>
          Delete
        </Button>
      )
    }
  ]

  //   const rowSelection = {
  //     selectedRowKeys,
  //     onChange: handleRowSelectionChange
  //   }

  const [selectedTable, setSelectedTable] = useState(null)

  const handleTableChange = value => {
    setSelectedTable(value)
  }

  const filteredTableData = tableData.filter(
    data => data.tablename === selectedTable
  )

  return (
    <div style={{ marginTop: '20px' }}>
      <Form
        {...layout}
        form={form}
        name='nest-messages'
        onFinish={onFinish}
        style={{ maxWidth: 600 }}
      >
        <Form.Item
          name={['group', 'groupid']}
          label='Group ID'
          rules={[{ required: true }]}
        >
          <Input placeholder='Enter Group ID' />
        </Form.Item>
        <Form.Item
          name={['group', 'groupname']}
          label='Group Name'
          rules={[{ required: true }]}
        >
          <Input placeholder='Enter Group Name' />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type='primary' htmlType='submit'>
            Set Group
          </Button>
        </Form.Item>
      </Form>
      <div
        style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px' }}
      >
        <Table
          //   dataSource={tableData}
          dataSource={filteredTableData}
          columns={columns}
          //   rowSelection={rowSelection}
        />
        <Modal
          title='Confirm Delete'
          visible={isModalVisible}
          onOk={handleModalConfirm}
          onCancel={handleModalCancel}
        >
          <p>Are you sure you want to delete the selected row?</p>
        </Modal>
      </div>
    </div>
  )
}

export default TableRefresh
