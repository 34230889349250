// import React from 'react';
// import { animated, useSpring } from '@react-spring/web';

// const Notification = ({ message, show }) => {
//     const animation = useSpring({
//         opacity: show ? 1 : 0,
//         transform: show ? 'translateY(0)' : 'translateY(-50%)',
//         config: { tension: 220, friction: 120 },
//     });

//     return (
//         <animated.div style={animation} className="notification">
//             {message}
//         </animated.div>
//     );
// };

// export default Notification;


import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import { NotificationOutlined, CloseOutlined } from '@ant-design/icons';

const Notification = ({ message, show, onClose }) => {
    const animation = useSpring({
        opacity: show ? 1 : 0,
        transform: show ? 'translateY(0)' : 'translateY(-50%)',
        config: { tension: 220, friction: 120 },
    });

    const notificationStyle = {
        background: 'linear-gradient(135deg, #6e8efb, #a777e3)',
        color: 'white',
        padding: '20px 25px',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
        fontSize: '16px',
        transition: 'background-color 0.3s ease',
        width: '500px', // Increase the width here
        height: '200px'
    };

    const iconStyle = {
        marginRight: '10px',
        fontSize: '24px',
    };

    const closeIconStyle = {
        marginLeft: 'auto', // Push the close icon to the right
        cursor: 'pointer',
        fontSize: '20px',
    };

    return (
        <animated.div style={{ ...animation, ...notificationStyle }}>
            <NotificationOutlined style={iconStyle} />
            <span>{message}</span>
            <CloseOutlined style={closeIconStyle} onClick={onClose} />
        </animated.div>
    );
};

export default Notification;

