import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Empty, Modal, Select, message } from "antd";
import * as XLSX from "xlsx";
import { useAuth } from "../../../context/AuthContext";
import jwt_decode from "jwt-decode";
import { fetchOperationsData } from "../../../redux/slice/fetchOperationsData";
import { updateOperationsIsAvailable } from '../../../redux/slice/updateOperations';
import { saveAs } from "file-saver";
import { HashLoader } from "react-spinners";
import { DownloadBtn, StyledTable } from "../components/styleComp";

const { Option } = Select;

const OperationsData = (props) => {
  const { authState } = useAuth();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [preColumns, setPreColumns] = useState([]);
  const [postColumns, setPostColumns] = useState([]);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [latestPreDate, setLatestPreDate] = useState(null);
  const [latestPostDate, setLatestPostDate] = useState(null);
  const [latestAvailableDate, setLatestAvailableDate] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [newValue, setNewValue] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const jwtBearer = authState.token;
  let jwt_payload_group = [];
  if (jwtBearer) {
    try {
      const jwt_payload = jwt_decode(jwtBearer);
      // jwt_payload_group = jwt_payload.group_id;
      jwt_payload_group = props.userDetails.group_id;
    } catch (error) {
      console.error("Error decoding JWT token:", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchOperationsData({ jwtBearer })).unwrap();
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Error fetching data: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    if (jwtBearer) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [dispatch, jwtBearer]);

  const { postData, preData, availableData, updateOperationsIsAvailableStatus } = useSelector((state) => ({
    ...state.operationsData,
    updateOperationsIsAvailableStatus: state.updateOperationsIsAvailable,
  }));

  useEffect(() => {
    if (preData.length > 0) {
      const keys = Object.keys(preData[0]);
      const columns = keys.map((key) => ({
        title: (
          <strong style={{ fontSize: "18px" }}>
            {key.replace(/_/g, " ").toUpperCase()}
          </strong>
        ),
        dataIndex: key,
        key: key,
        render: (text) => (
          <span className="poppins-font" style={{ fontSize: "16px" }}>
            {text}
          </span>
        ),
        filters: [
          ...new Set(preData.map((record) => record[key]))
        ].map((value) => ({
          text: value,
          value: value,
        })),
        onFilter: (value, record) => record[key] === value,
        filterSearch: true,
      }));
      setPreColumns(columns);

      const latestDate = Math.max(
        ...preData.map((item) => new Date(item.ADDITION_DATE).getTime())
      );
      setLatestPreDate(new Date(latestDate).toLocaleString());
    }
  }, [preData]);

  useEffect(() => {
    if (postData.length > 0) {
      const keys = Object.keys(postData[0]);
      const columns = keys.map((key) => ({
        title: (
          <strong style={{ fontSize: "18px" }}>
            {key.replace(/_/g, " ").toUpperCase()}
          </strong>
        ),
        dataIndex: key,
        key: key,
        render: (text) => (
          <span className="poppins-font" style={{ fontSize: "16px" }}>
            {text}
          </span>
        ),
        filters: [
          ...new Set(postData.map((record) => record[key]))
        ].map((value) => ({
          text: value,
          value: value,
        })),
        onFilter: (value, record) => record[key] === value,
        filterSearch: true,
      }));
      setPostColumns(columns);

      const latestDate = Math.max(
        ...postData.map((item) => new Date(item.ADDITION_DATE).getTime())
      );
      setLatestPostDate(new Date(latestDate).toLocaleString());
    }
  }, [postData]);

  useEffect(() => {
    if (availableData.length > 0) {
      const keys = Object.keys(availableData[0]);
      const columns = keys.map((key) => ({
        title: (
          <strong style={{ fontSize: "18px" }}>
            {key.replace(/_/g, " ").toUpperCase()}
          </strong>
        ),
        dataIndex: key,
        key: key,
        render: (text, record) => {
          if (key === "IS_AVAILABLE") {
            return (
              <Select
                value={text} // Set the current value here
                onChange={(value) => handleIsAvailableChange(value, record)}
                style={{ width: 120 }}
              >
                <Option value="Y">Yes</Option>
                <Option value="N">No</Option>
              </Select>
            );
          }
          return <span className="poppins-font" style={{ fontSize: "16px" }}>{text}</span>;
        },
        filters: [
          ...new Set(availableData.map((record) => record[key]))
        ].map((value) => ({
          text: value,
          value: value,
        })),
        onFilter: (value, record) => record[key] === value,
        filterSearch: true,
      }));
      setAvailableColumns(columns);

      const latestDate = Math.max(
        ...availableData.map((item) => new Date(item.UPDATED_ON).getTime())
      );
      setLatestAvailableDate(new Date(latestDate).toLocaleString());
    }
  }, [availableData]);

  const handleIsAvailableChange = (value, record) => {
    setSelectedRecord(record);
    setNewValue(value);
    setIsModalVisible(true);
  };

  const confirmUpdate = async () => {
    setConfirmLoading(true);
    const requestBody = {
      user_id: selectedRecord.USER_ID,
      los_stage: selectedRecord.LOS_STAGE,
      loan_category: selectedRecord.LOAN_CATEGORY,
      loan_type: selectedRecord.LOAN_TYPE,
      is_available: newValue,
    };

    try {
      await dispatch(updateOperationsIsAvailable({
        jwtBearer: authState.token,
        requestBody,
      })).unwrap();
      message.success("Update successful!");
      await dispatch(fetchOperationsData({ jwtBearer }));
      setIsModalVisible(false);
      setSelectedRecord(null);
      setNewValue(null);
    } catch (error) {
      console.error("Error updating data:", error);
      message.error("Update failed: " + error.message);
    } finally {
      setConfirmLoading(false);
    }
  };

  const downloadExcel = (dataSource, columns, filename) => {
    const csvHeaders = columns.map((col) => ({
      key: col.key,
      label: col.title.props.children,
    }));
    const formattedData = dataSource.map((row) => {
      const newRow = {};
      csvHeaders.forEach((header) => {
        newRow[header.label] = row[header.key];
      });
      return newRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, filename);
  };

  const paginationConfig = {
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    defaultPageSize: 10, // Initial page size
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
  };

  return (
    <>
      <Card
        style={{ marginTop: "2rem" }}
        title={
          <div>
            <strong style={{ fontSize: "22px" }}>PRE DISBURSAL REVIEW MIS</strong>
            {latestPreDate && (
              <div style={{ fontSize: "18px", fontWeight: 500, marginTop: "8px" }}>
                Last added on: {latestPreDate}
              </div>
            )}
          </div>
        }
        bordered
        extra={
          preData.length > 0 && (
            <DownloadBtn
              onClick={() =>
                downloadExcel(preData, preColumns, "Pre_disbursal_data.xlsx")
              }
              style={{ fontSize: "16px" }}
            >
              Download data
            </DownloadBtn>
          )
        }
      >
        {loading ? (
          <div className="spinner-container">
            <HashLoader color="#f57171" />
          </div>
        ) : preData.length > 0 ? (
          <StyledTable
            columns={preColumns}
            dataSource={preData}
            pagination={paginationConfig}
            rowKey="application_number"
          />
        ) : (
          <Empty description="No data available" />
        )}
      </Card>
      <Card
        style={{ marginTop: "2rem" }}
        title={
          <div>
            <strong style={{ fontSize: "22px" }}>POST DISBURSAL REVIEW MIS</strong>
            {latestPostDate && (
              <div style={{ fontSize: "18px", fontWeight: 500, marginTop: "8px" }}>
                Last added on: {latestPostDate}
              </div>
            )}
          </div>
        }
        bordered
        extra={
          postData.length > 0 && (
            <DownloadBtn
              onClick={() =>
                downloadExcel(postData, postColumns, "Post_disbursal_data.xlsx")
              }
              style={{ fontSize: "16px" }}
            >
              Download data
            </DownloadBtn>
          )
        }
      >
        {loading ? (
          <div className="spinner-container">
            <HashLoader color="#f57171" />
          </div>
        ) : postData.length > 0 ? (
          <StyledTable
            columns={postColumns}
            dataSource={postData}
            pagination={paginationConfig}
            rowKey="application_number"
          />
        ) : (
          <Empty description="No data available" />
        )}
      </Card>
      <Card
        style={{ marginTop: "2rem" }}
        title={
          <div>
            <strong style={{ fontSize: "22px" }}>OPERATIONS AVAILABLE USER DATA</strong>
            {latestAvailableDate && (
              <div style={{ fontSize: "14px", marginTop: "8px" }}>
                Last Updated on: {latestAvailableDate}
              </div>
            )}
          </div>
        }
        bordered
        extra={
          availableData.length > 0 && (
            <DownloadBtn
              onClick={() =>
                downloadExcel(availableData, availableColumns, "Available_user_data.xlsx")
              }
              style={{ fontSize: "16px" }}
            >
              Download data
            </DownloadBtn>
          )
        }
      >
        {loading ? (
          <div className="spinner-container">
            <HashLoader color="#f57171" />
          </div>
        ) : availableData.length > 0 ? (
          <StyledTable
            columns={availableColumns}
            dataSource={availableData}
            pagination={paginationConfig}
            rowKey="application_number"
          />
        ) : (
          <Empty description="No data available" />
        )}
      </Card>
      <Modal
        title="Confirm Update"
        visible={isModalVisible}
        onOk={confirmUpdate}
        onCancel={() => {
          setIsModalVisible(false);
          setSelectedRecord(null);
          setNewValue(null);
        }}
        okText="Yes"
        cancelText="No"
        confirmLoading={confirmLoading}
      >
        <p>Are you sure you want to update the availability status for the selected record?</p>
      </Modal>
    </>
  );
};

export default OperationsData;

