import React, { useState } from 'react';
import { Card, Row, Col, Select } from 'antd';
import outlookVideo from "../../assets/videos/Outlook_Video.mp4";
import teamsVideo from "../../assets/videos/Teams_Video.mp4";
import calenderVideo from "../../assets/videos/Calender_Meeting_Invite_Video.mp4";
const { Option } = Select;

const Home = () => {
  const [language, setLanguage] = useState('hindi');
  const [activeVideo, setActiveVideo] = useState(null);

  const handleVideoPlay = (videoId) => {
    setActiveVideo(videoId);
    pauseOtherVideos(videoId);
  };

  const pauseOtherVideos = (currentVideoId) => {
    const videoIds = ['outlookVideo', 'teamsVideo', 'calenderVideo'];
    videoIds.forEach((id) => {
      if (id !== currentVideoId) {
        const videoElement = document.getElementById(id);
        if (videoElement) {
          videoElement.pause();
        }
      }
    });
  };

  return (
    <>
      <div style={{ margin: '0 16px' }}>
        <Card
          style={{
            borderRadius: "25px",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            marginBottom: 16
          }}
        >
          <div style={{ textAlign: 'center' }}>

            <p style={{ color: "#333", fontWeight: "bold", textDecoration: "underline" }} className="homeHeading">
              Microsoft Outlook Tutorial
            </p>
            <p style={{ color: "#333", fontWeight: "bold", textDecoration: "underline" }} className="homeHeading">
              माइक्रोसॉफ्ट आउटलुक शिक्षण
            </p>

            <Row gutter={84} justify="center" align="middle">
              <Col xs={24} sm={22} md={20} lg={18} xl={14}>
                <video
                  id="outlookVideo"
                  style={{ width: '100%', borderRadius: "15px", marginBottom: "45px" }}
                  controls
                  onPlay={() => handleVideoPlay('outlookVideo')}
                >
                  <source src={outlookVideo} type="video/mp4" />
                </video>
              </Col>
              <Col xs={24} sm={22} md={20} lg={18} xl={10}>
                <Card
                  style={{
                    borderRadius: "25px",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                    width: '100%',
                    minHeight: "350px"
                  }}
                >
                  <div style={{ fontSize: "1.5rem", textAlign: "left" }}>
                    <Select
                      style={{ width: 200, float: 'right', marginBottom: '1rem' }}
                      defaultValue="hindi"
                      onChange={(value) => setLanguage(value)}
                    >
                      <Option value="hindi">हिन्दी</Option>
                      <Option value="english">English</Option>
                    </Select>
                    {language === 'english' && (
                      <>
                        <p style={{ fontSize: '1.2rem' }}><strong>Points covered in this tutorial -</strong></p>
                        <p style={{ fontSize: '1.2rem' }}>1. How to log in to Outlook using Microsoft Edge exclusively without using Chrome.</p>
                        <p style={{ fontSize: '1.2rem' }}>2. Steps to send an email to another employee.</p>
                        <p style={{ fontSize: '1.2rem' }}>3. How to check your received emails in the Inbox.</p>
                        <p style={{ fontSize: '1.2rem' }}>4. Instructions for attaching files or folders to an Outlook email.</p>
                        <p style={{ fontSize: '1.2rem' }}>5. How to access all Office apps from the Outlook page, including Excel, Word, and Teams.</p>
                      </>
                    )}
                    {language === 'hindi' && (
                      <>
                        <p style={{ fontSize: '1.2rem' }}><strong>इस ट्यूटोरियल में शामिल विषय -</strong></p>
                        <p style={{ fontSize: '1.2rem' }}>1. क्रोम का उपयोग किए बिना विशेष रूप से माइक्रोसॉफ्ट एज का उपयोग करके आउटलुक में कैसे लॉग इन करें।</p>
                        <p style={{ fontSize: '1.2rem' }}>2. किसी अन्य कर्मचारी को ईमेल भेजने के चरण।</p>
                        <p style={{ fontSize: '1.2rem' }}>3. इनबॉक्स में अपने प्राप्त ईमेल की जांच कैसे करें।</p>
                        <p style={{ fontSize: '1.2rem' }}>4. आउटलुक ईमेल में फ़ाइलें या फ़ोल्डर्स को कैसे जोड़ें।</p>
                        <p style={{ fontSize: '1.2rem' }}>5. एक्सेल, वर्ड और टीम्स सहित आउटलुक पेज से सभी ऑफिस ऐप्स तक कैसे पहुंचें।</p>
                      </>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Card>


        <Card
          style={{
            borderRadius: "25px",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            marginBottom: 16
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <p style={{ color: "#333", fontWeight: "bold", textDecoration: "underline" }} className="homeHeading">
              Microsoft Teams Tutorial
            </p>
            <p style={{ color: "#333", fontWeight: "bold", textDecoration: "underline" }} className="homeHeading">
              माइक्रोसॉफ्ट टीम शिक्षण
            </p>
            <Row gutter={84} justify="center" align="middle">
              <Col xs={24} sm={22} md={20} lg={18} xl={14}>
                <video
                  id="teamsVideo"
                  style={{ width: '100%', borderRadius: "15px", marginBottom: "45px" }}
                  controls
                  onPlay={() => handleVideoPlay('teamsVideo')}
                >
                  <source src={teamsVideo} type="video/mp4" />
                </video>
              </Col>
              <Col xs={24} sm={22} md={20} lg={18} xl={10}>
                <Card
                  style={{
                    borderRadius: "25px",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                    width: '100%',
                    minHeight: "350px"
                  }}
                >
                  <div style={{ fontSize: "1.5rem", textAlign: "left" }}>
                    <Select
                      style={{ width: 200, float: 'right', marginBottom: '1rem' }}
                      defaultValue="hindi"
                      onChange={(value) => setLanguage(value)}
                    >
                      <Option value="hindi">हिन्दी</Option>
                      <Option value="english">English</Option>
                    </Select>
                    {language === 'english' && (
                      <>
                        <p style={{ fontSize: '1.2rem' }}><strong>Points covered in this tutorial -</strong></p>
                        <p style={{ fontSize: "1.2rem" }}>1. How to log in to Teams on your browser and mobile apps.</p>
                        <p style={{ fontSize: "1.2rem" }}>2. Steps to chat with another employee in Teams.</p>
                        <p style={{ fontSize: "1.2rem" }}>3. How to add or create a personal group in Teams.</p>
                        <p style={{ fontSize: "1.2rem" }}>4. How to check your Teams channels and communicate within channels.</p>
                        <p style={{ fontSize: "1.2rem" }}>5. How to access and manage your OneDrive data.</p>
                        <p style={{ fontSize: "1.2rem" }}>6. How to initiate and share your screen during Teams calls with another employee.</p>
                      </>
                    )}
                    {language === 'hindi' && (
                      <>
                        <p style={{ fontSize: '1.2rem' }}><strong>इस ट्यूटोरियल में शामिल विषय -</strong></p>
                        <p style={{ fontSize: "1.2rem" }}>1. अपने ब्राउज़र और मोबाइल ऐप्स पर टीम्स में कैसे लॉग इन करें।</p>
                        <p style={{ fontSize: "1.2rem" }}>2. टीम्स में किसी अन्य कर्मचारी के साथ चैट करने के चरण।</p>
                        <p style={{ fontSize: "1.2rem" }}>3. टीम्स में व्यक्तिगत ग्रुप कैसे जोड़ें या बनाएं।</p>
                        <p style={{ fontSize: "1.2rem" }}>4. टीम्स में अपने चैनल्स की जाँच कैसे करें और चैनल्स में संवाद कैसे करें।</p>
                        <p style={{ fontSize: "1.2rem" }}>5. अपने OneDrive डेटा तक कैसे पहुँचें और प्रबंधित करें।</p>
                        <p style={{ fontSize: "1.2rem" }}>6. किसी अन्य कर्मचारी के साथ टीम कॉल के दौरान अपनी स्क्रीन कैसे आरंभ करें और साझा करें।</p>
                      </>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Card>

        <Card
          style={{
            borderRadius: "25px",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            marginBottom: 16
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <p style={{ color: "#333", fontWeight: "bold", textDecoration: "underline" }} className="homeHeading">
              Calender Meeting Invite Tutorial
            </p>
            <p style={{ color: "#333", fontWeight: "bold", textDecoration: "underline" }} className="homeHeading">
              कैलेंडर मीटिंग आमंत्रण शिक्षण
            </p>
            <Row gutter={84} justify="center" align="middle">
              <Col xs={24} sm={22} md={20} lg={18} xl={14}>
                <video
                  id="calenderVideo"
                  style={{ width: '100%', borderRadius: "15px", marginBottom: "45px" }}
                  controls
                  onPlay={() => handleVideoPlay('calenderVideo')}
                >
                  <source src={calenderVideo} type="video/mp4" />
                </video>
              </Col>
              <Col xs={24} sm={22} md={20} lg={18} xl={10}>
                <Card
                  style={{
                    borderRadius: "25px",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                    width: '100%',
                    minHeight: "350px"
                  }}
                >
                  <div style={{ fontSize: "1.5rem", textAlign: "left" }}>
                    <Select
                      style={{ width: 200, float: 'right', marginBottom: '1rem' }}
                      defaultValue="hindi"
                      onChange={(value) => setLanguage(value)}
                    >
                      <Option value="hindi">हिन्दी</Option>
                      <Option value="english">English</Option>
                    </Select>
                    {language === 'english' && (
                      <>
                        <p style={{ fontSize: '1.2rem' }}><strong>Points covered in this tutorial -</strong></p>
                        <p style={{ fontSize: "1.2rem" }}>1. How to create a Teams meeting invite.</p>
                        <p style={{ fontSize: "1.2rem" }}>2. Steps to join a Teams meeting.</p>
                        <p style={{ fontSize: "1.2rem" }}>3. How to check your calendar for meeting scheduling.</p>
                      </>
                    )}
                    {language === 'hindi' && (
                      <>
                        <p style={{ fontSize: '1.2rem' }}><strong>इस ट्यूटोरियल में शामिल विषय -</strong></p>
                        <p style={{ fontSize: "1.2rem" }}>1. टीम मीटिंग आमंत्रण कैसे बनाएं।</p>
                        <p style={{ fontSize: "1.2rem" }}>2. टीम मीटिंग में शामिल होने के चरण।</p>
                        <p style={{ fontSize: "1.2rem" }}>3. मीटिंग शेड्यूलिंग के लिए अपना कैलेंडर कैसे जांचें।</p>
                      </>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Home;
