import React, { useState } from "react";
import MISComp from "../mis_components/MISComp";
// import { Suspense, lazy } from 'react'
// const MISComp = lazy(() => import('../mis_components/MISComp'))
import "../../../../assets/styles/MIS.css";

// function MIS() {
const MIS = (props) => {
  const [view, setView] = useState("disb_mis"); // Initialize with default view

  const handleButtonClick = (selectedView) => {
    setView(selectedView);
  };

  return (
    <>
      <div className="slider-container">
        <div
          className={`slider ${view}`}
          style={{ width: "60%", marginTop: "20px" }}
        >
          <button
            className={`slider-button ${view === "disb_mis" ? "active-button" : ""
              }`}
            onClick={() => handleButtonClick("disb_mis")}
          >
            <span style={{ whiteSpace: "nowrap" }}>DISBURSEMENT MIS</span>
          </button>
          <button
            className={`slider-button ${view === "daily" ? "active-button" : ""
              }`}
            onClick={() => handleButtonClick("daily")}
          >
            <span style={{ whiteSpace: "nowrap" }}>UTR TRACKER</span>
          </button>
          <button
            className={`slider-button ${view === "dailyStatus" ? "active-button" : ""
              }`}
            onClick={() => handleButtonClick("dailyStatus")}
          >
            <span style={{ whiteSpace: "nowrap" }}>DAILY STATUS</span>
          </button>
          <button
            className={`slider-button ${view === "weekly" ? "active-button" : ""
              }`}
            onClick={() => handleButtonClick("weekly")}
          >
            <span style={{ whiteSpace: "nowrap" }}>ACH STATUS</span>
          </button>

          <button
            className={`slider-button ${view === "monthly" ? "active-button" : ""
              }`}
            onClick={() => handleButtonClick("monthly")}
          >
            <span style={{ whiteSpace: "nowrap" }}>
              EXPECTED COLLECTION DUE
            </span>
          </button>
        </div>
      </div>
      {/* <Suspense fallback={<div>Component1 are loading please wait...</div>}> */}
      {view === "weekly" && <MISComp reportType="ACH" extraProp={props.userDetails} />}
      {view === "monthly" && (
        // <Suspense fallback={<div>Component1 are loading please wait...</div>}>
        <MISComp reportType="EXPECTED COLLECTION" extraProp={props.userDetails} />
        // </Suspense>
      )}
      {view === "daily" && <MISComp reportType="UTR" extraProp={props.userDetails} />}
      {view === "disb_mis" && <MISComp reportType="DISB MIS" extraProp={props.userDetails} />}
      {view === "dailyStatus" && <MISComp reportType="DAILY STATUS" extraProp={props.userDetails} />}
      {/* </Suspense> */}
    </>
  );
}

export default MIS;
