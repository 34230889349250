import React, { useState, useEffect } from "react";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import { DownOutlined } from "@ant-design/icons";
import styled from "styled-components";

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
  overflow-x: hidden;
`;

const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  width: 100%;
  max-width: 600px;
`;

const StyledDropdownButton = styled(Button)`
  height: 48px;
  width: 20px;
  background-color: #f0f0f0;
  color: rgba(0, 0, 0, 0.45);
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-align: center;
  margin: auto;

  &:hover {
    background-color: #e0e0e0;
  }

  .anticon {
    margin: 0;
  }

    @media(max-width:1400px){
  height:40px
  }
 
`;

const StyledButton = styled(Button)`
  flex: 1;
  height: 48px;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: white;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(135deg, #5d7cea, #9461e2);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background: #d9d9d9;
    color: rgba(0, 0, 0, 0.45);
  }

    @media(max-width:1400px){
  height:40px
  }
 
`;

const StyledInputContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 48px;
  font-size: 18px;
  color: black;
  border: 1px solid #d9d9d9;
  background-color: #f5f5f5;
  border-radius: 8px;
  text-align: center;
  padding: 0 2px;
  box-shadow: ${(props) =>
        props.error ? "0 0 0 2px rgba(255, 77, 79, 0.2)" : "none"};

  &:focus {
    border-color: ${(props) => (props.error ? "#ff4d4f" : "#40a9ff")};
    outline: none;
  }

  &:disabled {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.45);
  }

    @media(max-width:1400px){
  height:40px
  }
`;

const HelpMessage = styled.div`
  color: ${(props) => (props.error ? "red" : "rgba(0, 0, 0, 0.45)")};
  font-size: 14px;
  margin-top: 4px;
  text-align: center;
  width: 100%;
`;


const SetBtn = styled(Button)`
  flex: 1;
  height: 48px;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: white;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(135deg, #5d7cea, #9461e2);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background: #d9d9d9;
    color: rgba(0, 0, 0, 0.45);
  }

   @media (max-width: 1435px) {
    flex: 0 0 100%; 
    margin-top: 8px; 
  }

  @media(max-width:1400px){
  height:40px
  }
`

const LoanAmountSelector = ({ minLoanAmt, maxLoanAmt, onLoanAmountChange }) => {
    const [loanAmountValue, setLoanAmountValue] = useState("");
    const [incrementValue, setIncrementValue] = useState(10000); // Default increment value
    const [isLoanAmountValid, setIsLoanAmountValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        // Initialize loan amount value based on initialLoanAmount prop
        setLoanAmountValue("");
    }, []);

    const handleIncrease = () => {
        const newValue = Math.min(
            parseInt(loanAmountValue, 10) + incrementValue,
            maxLoanAmt
        );
        setLoanAmountValue(newValue.toString());
        setIsLoanAmountValid(true);
        setErrorMessage("");
        onLoanAmountChange(newValue);
    };

    const handleDecrease = () => {
        const newValue = Math.max(
            parseInt(loanAmountValue, 10) - incrementValue,
            minLoanAmt
        );
        setLoanAmountValue(newValue.toString());
        setIsLoanAmountValid(true);
        setErrorMessage("");
        onLoanAmountChange(newValue);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        const numericValue = parseInt(value, 10);

        if (!isNaN(numericValue)) {
            // Valid numeric input
            setLoanAmountValue(value);

            // Check if the value is within the valid range
            if (numericValue >= minLoanAmt && numericValue <= maxLoanAmt) {
                setIsLoanAmountValid(true);
                setErrorMessage("");
                onLoanAmountChange(numericValue);
            } else {
                setIsLoanAmountValid(false);
                onLoanAmountChange(numericValue);
                setErrorMessage(
                    `Loan amount must be between ₹${minLoanAmt.toLocaleString()} and ₹${maxLoanAmt.toLocaleString()}.`
                );
            }
        } else {
            // Invalid input
            setLoanAmountValue("");
            setIsLoanAmountValid(false);
            setErrorMessage("Please enter a valid number.");
        }
    };

    const handleBlur = () => {
        // Reset to bounds if input is out of range
        if (loanAmountValue < minLoanAmt) {
            setLoanAmountValue(minLoanAmt.toString());
            onLoanAmountChange(minLoanAmt);
        } else if (loanAmountValue > maxLoanAmt) {
            setLoanAmountValue(maxLoanAmt.toString());
            onLoanAmountChange(maxLoanAmt);
        }
    };

    const handleSetMinLoan = () => {
        setLoanAmountValue(minLoanAmt.toString());
        setIsLoanAmountValid(true);
        setErrorMessage("");
        onLoanAmountChange(minLoanAmt);
    };

    const handleMenuClick = ({ key }) => {
        setIncrementValue(parseInt(key, 10));
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1000">1000</Menu.Item>
            <Menu.Item key="5000">5000</Menu.Item>
            <Menu.Item key="10000">10000</Menu.Item>
        </Menu>
    );

    return (
        <Container>
            <RowContainer>
                {/* Dropdown Arrow (Left) */}
                <StyledDropdownButton>
                    <Dropdown overlay={menu} trigger={["click"]}>
                        <DownOutlined />
                    </Dropdown>
                </StyledDropdownButton>

                {/* Decrease Button */}
                <StyledButton
                    onClick={handleDecrease}
                    disabled={loanAmountValue <= minLoanAmt}
                >
                    <span style={{ fontSize: "18px" }}>- {incrementValue}</span>
                </StyledButton>

                {/* Input */}
                <StyledInputContainer>
                    <Tooltip
                        title={errorMessage}
                        visible={!isLoanAmountValid}
                        placement="topRight"
                    >
                        <StyledInput
                            type="text"
                            value={loanAmountValue}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={!isLoanAmountValid}
                        />
                    </Tooltip>
                    {/* <HelpMessage error={!isLoanAmountValid}>{!isLoanAmountValid && errorMessage}</HelpMessage> */}
                </StyledInputContainer>

                {/* Increase Button */}
                <StyledButton
                    onClick={handleIncrease}
                    disabled={loanAmountValue >= maxLoanAmt || !loanAmountValue}
                >
                    <span style={{ fontSize: "18px" }}>+ {incrementValue}</span>
                </StyledButton>

                {/* Dropdown Arrow (Right) */}
                <StyledDropdownButton>
                    <Dropdown overlay={menu} trigger={["click"]}>
                        <DownOutlined />
                    </Dropdown>
                </StyledDropdownButton>

                {/* Set Min Loan Button */}
                <SetBtn onClick={handleSetMinLoan}>
                    Set ₹{minLoanAmt.toLocaleString()}
                </SetBtn>
            </RowContainer>
        </Container>
    );
};

export default LoanAmountSelector;
