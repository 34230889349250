import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../apiConfig'

export const verifyOTP = createAsyncThunk(
    'verifyOTP',
    async ({ userid, otp }) => {
        try {
            console.log('Verify request - ', userid, otp)
            // const response = await axios.post(`${BASE_URL}login/verify/otp/`, {
            const response = await axios.post(`${BASE_URL}user/verify/otp/`, {
                userid,
                otp
                // })
            }, { withCredentials: true });
            return response.data
        } catch (error) {
            console.log('Error verify slice', error.response.data)
            alert(error.response.data.message)
            // alert('Hello')
            throw error.response.data
        }
    }
)

// export const verifyOTP = createAsyncThunk(
//     'verifyOTP',
//     async ({ userid, otp }, { rejectWithValue }) => {
//         try {
//             console.log('Verify request - ', userid, otp);

//             const response = await axios.post(`${BASE_URL}user/verify/otp/`, {
//                 userid,
//                 otp
//             }, { withCredentials: true });
//             console.log('result auth thunk - ', response)

//             if (response.data) {
//                 return response.data;
//             } else {
//                 throw new Error('No data in response');
//             }

//         } catch (error) {
//             console.log('Error in verifyOTP thunk:', error);
//             // Return a more meaningful error message for debugging
//             if (error.response && error.response.data) {
//                 return rejectWithValue(error.response.data.message || 'Error occurred');
//             }
//             return rejectWithValue(error.message || 'Unknown error occurred');
//         }
//     }
// );

const verifyOTPSlice = createSlice({
    name: 'verifyOTP',
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
        errorMessage: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(verifyOTP.pending, state => {
            state.isLoading = true
            state.isError = false
            state.errorMessage = null
        })
        builder.addCase(verifyOTP.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = false
            state.errorMessage = null
        })
        builder.addCase(verifyOTP.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = action.error.message
        })
    }
})

export default verifyOTPSlice.reducer
