import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DIGITAL_JOURNEY_BASE_URL } from '../apiConfig'

export const fetchBankVerification = createAsyncThunk(
    'fetchBankVerification',
    async ({ jwtBearer, custMobile, custAccountNumber, custIFSC, custName }) => {
        const response = await fetch(`${DIGITAL_JOURNEY_BASE_URL}portal/verify_bank/?username=${custMobile}&bank_account_number=${custAccountNumber}&ifsc_code=${custIFSC}&account_holder_name=${custName}`, {
            headers: {
                Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
            }
        })
        return response.json()
    }
)

const fetchBankVerificationSlice = createSlice({
    name: 'fetchBankVerification',
    initialState: {
        isLoading: false,
        data: null,
        isError: false
    },
    extraReducers: builder => {
        builder.addCase(fetchBankVerificationData.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchBankVerificationData.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        })
        builder.addCase(fetchBankVerificationData.rejected, (state, action) => {
            console.log('Error', action.payload)
            state.isError = true
        })
    }
})

export default fetchBankVerificationSlice.reducer
