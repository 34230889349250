import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../apiConfig'

export const fetchAdmin = createAsyncThunk(
  'fetchAdmin',
  async ({ jwtBearer }) => {
    const response = await fetch(`${BASE_URL}admin/get/users/`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
      }
    })
    return response.json()
  }
)

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    isLoading: false,
    data: null,
    isError: false
  },
  extraReducers: builder => {
    builder.addCase(fetchAdmin.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchAdmin.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(fetchAdmin.rejected, (state, action) => {
      console.log('Error', action.payload)
      state.isError = true
    })
  }
})

export default adminSlice.reducer
