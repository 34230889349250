import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../apiConfig'

export const createGroup = createAsyncThunk(
  'createGroup',
  async ({ jwt_token, requestBody }) => {
    // Accept jwt_token and requestBody as arguments
    const jsonreq = JSON.stringify(requestBody)
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwt_token
      },
      body: jsonreq
    }

    try {
      const response = await fetch(`${BASE_URL}admin/add/group/`, options)
      return response.data
    } catch (error) {
      console.log('Error', error.response.data)
      alert(error.response.data.message)
      throw error.response.data
    }
  }
)

const createGroupSlice = createSlice({
  name: 'createGroup',
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: null
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createGroup.pending, state => {
      state.isLoading = true
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(createGroup.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.isError = false
      state.errorMessage = null
    })
    builder.addCase(createGroup.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.errorMessage = action.error.message
    })
  }
})

export default createGroupSlice.reducer
