import { cloneElement, useEffect, useState } from 'react'
import { PERMISSIONS } from './PermissionsMap'
// import { useGetRole } from './useGetRole'
import { useAuth } from '../context/AuthContext'
import jwt_decode from 'jwt-decode'

const hasPermission = ({ permissions, scopes }) => {
  const scopesMap = {}
  scopes.forEach(scope => {
    scopesMap[scope] = true
  })

  console.log('Permissions -', permissions)

  return permissions.some(permission => scopesMap[permission])
}

export default function PermissionsGate({ children, scopes = [], userDetails }) {
  console.log('permission gate prop - ', userDetails)
  // const { role } = useGetRole()

  const { authState, onLogout } = useAuth()
  // const { jwt_payload_group } = userGroup()

  var token_decoded = authState.token
  var jwtBearer = authState.token

  var jwt_payload = ''
  var jwt_payload_type = ''
  if (token_decoded) {
    jwt_payload = jwt_decode(token_decoded)
    jwt_payload_type = jwt_payload.group_id
    // jwt_payload_type = userDetails?.group_id
    console.log('TYpe JWT - ', userDetails)
  }

  // const { role } = jwt_payload_group
  const role = jwt_payload_type
  console.log('Roles', role)
  // const testing = { consumer: 'CONSUMER' }
  // const permissions = PERMISSIONS[testing]
  // const permissions = PERMISSIONS[role]
  // const permissions = PERMISSIONS['' + role]
  // const permissions = PERMISSIONS[role.toString()]
  // const permissions = PERMISSIONS[`'${role}'`]
  const permissions = PERMISSIONS[String(role)]

  console.log('Permission array -', permissions)

  const permissionGranted = hasPermission({ permissions, scopes })

  if (!permissionGranted) return <></>

  return <>{children}</>
}
