import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { useAuth } from '../../../context/AuthContext'
import { fetchUserTickets } from '../../../redux/slice/getCMAUserTickets'
import { fetchDepartment } from '../../../redux/slice/getCMADepartment'
import { fetchTrail } from '../../../redux/slice/getCMATrail'
import moment from 'moment';  // Import moment library
import deptModal from '../components/deptModal'
import { ArrowRightOutlined } from '@ant-design/icons';
import {
  Space,
  Spin,
  Table,
  Card,
  Modal,
  Button,
  Input,
  message,
  Radio,
  Form,
  Typography,
  Divider,
  Row,
  Col,
  Tag
} from 'antd'

import AsyncStorage from '@react-native-async-storage/async-storage'
import { CSVLink } from 'react-csv'

const CMAUserTickets = () => {
  console.log('In open ticket')
  const dispatch = useDispatch()
  const [closedDataSource, setClosedDataSource] = useState([])
  const [loading, setLoading] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deptModalVisible, setDeptModalVisible] = useState(false);
  const [ticketNumber, setTicketNumber] = useState(null)
  const [deptResolution, setDeptResolution] = useState(false)
  const [descriptionSubmitted, setDescriptionSubmitted] = useState(false);
  const [trailData, setTrailData] = useState([])
  const [deptFromAPI, setDeptFromAPI] = useState('')


  useEffect(() => {
    dispatch(fetchUserTickets({ jwtBearer: 'JWT', userid: user_id, status: 'WIP' })).then(() => {
      setLoading(false) // Set loading state to false when the data is fetched
    })
  }, [])

  const state = useSelector(state => state)

  console.log('User Ticket State - ', state)

  const allFieldsReject = state.userTicketsSlice.data?.message || []

  console.log('Inventory Pandas Reject - ', allFieldsReject)

  const keysReject = allFieldsReject['ticket_number'] ? Object.keys(allFieldsReject['ticket_number']) : []
  

  // Create an array of objects in the desired format

  const objectsArrayReject = keysReject.map(key => {
    const obj = {}
    for (const subKey in allFieldsReject) {
      obj[subKey] = allFieldsReject[subKey][key]
    }
    return obj
  })

  const { authState, onLogout } = useAuth()

  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
    setDeptModalVisible(false)
  };


    // State to store the selected row data
    const [selectedRow, setSelectedRow] = useState(null);

    // let trailData = []
    const onRowClick = async (record) => {
      // console.log('Row Data Support - ', record.category_ID)
      console.log('Row Data Support - ', record)
      setTicketNumber(record.ticket_number)

      const deptResponse = await dispatch(
        fetchDepartment({ jwtBearer, ticket_number: record.ticket_number })
      )
      const departmentData = deptResponse.payload.message || {};

      setDeptFromAPI(deptResponse.payload.message)

      console.log('Current Department - ', departmentData)

      const trailResponse = await dispatch(
        fetchTrail({ jwtBearer, ticket_number: record.ticket_number })
      )
      // trailData = trailResponse.payload.message || [];
      setTrailData(trailResponse.payload.message)

      console.log('Current Trail - ', trailData)

      // Set the selected row data
      setSelectedRow(record);
  
      // Populate the form with the selected row data
      // form.setFieldsValue(record);
      if(record.description) {
        form.setFieldsValue({
          ...record,
          customerQuery: record.description, // Set the initial value for customerQuery
        });
        setDescriptionSubmitted(true);
      }
      else {
        form.setFieldsValue(record);
      }

      if (departmentData == 'Support') {
        setIsModalVisible(true)
      }
      else {
        setDeptModalVisible(true)
      }
    };

  var token_decoded = authState.token
  var jwtBearer = authState.token
  var jwt_payload = ''
  var jwt_payload_group = []
  var user_id = ''
  if (jwtBearer) {
    
    jwt_payload = jwt_decode(jwtBearer)
    console.log('Token decoded', jwt_payload.username)
    user_id = jwt_payload.user_id
    jwt_payload_group = jwt_payload.group_id
    console.log('JWT Group Reports - ', jwt_payload_group)
  }

  console.log('JWT Bearer Branch MIS - ', jwtBearer)

  useEffect(() => {
    dispatch(fetchUserTickets({ jwtBearer, userid: user_id, status: 'WIP' })).then(() => {
      setLoading(false) // Set loading state to false when the data is fetched
    })
  }, [])

  // const state = useSelector(state => state)
  // console.log('CMA User Ticket State - ', state)

  const tokenExpired = state.userTicketsSlice.data?.detail || ''
  const TOKEN_KEY = 'my-jwt'
  if (tokenExpired == 'Signature has expired.') {
    console.log('In token expired condition')
    AsyncStorage.removeItem(TOKEN_KEY)
    onLogout
    location.reload()
  }

  useEffect(() => {
    setClosedDataSource(objectsArrayReject)
  }, [allFieldsReject])


  const allKeysReject = objectsArrayReject.reduce((keys, record) => {
    return keys.concat(Object.keys(record))
  }, [])

  const uniqueKeysReject = [...new Set(allKeysReject)]

  const totalColumns = uniqueKeysReject.length;
  const equalColumnWidth = 100; // Set your desired width here

  const columnsAsset = uniqueKeysReject.map(key => ({
    title: <strong>{key.toUpperCase()}</strong>,
    dataIndex: key,
    key: key,
    width: totalColumns > 0 ? `${100 / totalColumns}%` : equalColumnWidth,
    align: 'left',
    filters: [...new Set(objectsArrayReject.map(record => record[key]))].map(
      value => ({
        text: value,
        value: value
      })
    ),
    onFilter: (value, record) => record[key] === value,
    filterSearch: true
  }))


  const paginationOptions = {
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    defaultPageSize: 10, // Initial page size
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
  }

  const excludedColumns = ['loan_id', 'main_ID', 'ticket_status', 'description', 'support_executive_id', 'user_id', 'resolution', 'created_at', 'updated_at', 'category_ID']
  const deptExcludedColumns = ['title', 'ticket_number', 'username', 'application_id', 'customer_name', 'loan_id', 'main_ID', 'ticket_status', 'support_executive_id', 'user_id', 'resolution', 'created_at', 'updated_at', 'category_ID']

  const usernameColumnIndex = columnsAsset.findIndex(column => column.dataIndex === 'username');

// If "username" column exists, update its title to "Contact Number"
if (usernameColumnIndex !== -1) {
  columnsAsset[usernameColumnIndex].title = <strong>CONTACT NUMBER</strong>;
}

const userIdColumnIndex = columnsAsset.findIndex(column => column.dataIndex === 'customer_name');

// If "user_id" column exists, update its title to "Customer name"
if (userIdColumnIndex !== -1) {
  columnsAsset[userIdColumnIndex].title = <strong>CUSTOMER NAME</strong>;
}

const DepartmentBoxes = ({ trailData }) => {
  return (
    <Space>
      {trailData?.length > 0 && trailData.map((trailItem, index) => (
        <React.Fragment key={index}>
        <Tag color="blue">{trailItem.transferred_from}</Tag>
        {index < trailData.length - 1 && <ArrowRightOutlined />}
        {index === trailData.length - 1 && (
          <>
            <ArrowRightOutlined />
            <Tag color="blue">{trailItem.transferred_to}</Tag>
          </>
        )}
      </React.Fragment>
      ))}
    </Space>
  );
};

  const columnsClosedWithPriority = [
    ...columnsAsset.filter(column => !excludedColumns.includes(column.dataIndex)),
    {
      title: <strong>CREATED AT</strong>,
      dataIndex: 'created_at',
      key: 'created_at',
      width: '10%',
      render: (text, record) => {
        const createdAtMoment = moment.utc(text); // Parse the UTC timestamp
        const createdAtIST = createdAtMoment.utcOffset('+05:30'); // Convert to IST (UTC+5:30)
        
        const date = createdAtIST.format('YYYY-MM-DD');
        const time = createdAtIST.format('HH:mm');
  
        return (
          <div>
            <p>DATE : <strong>{date}</strong></p>
            <p>TIME : <strong>{time}</strong></p>
          </div>
        );
      },
    },
    
  ];

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  

  return (
    <>
      <center>
        <div>
        <Card
            layout='horizontal'
            bordered={true}
            className='criclebox tablespace mb-24'
            title={
              <>
                CUSTOMER MOBILE APP TICKETS - WIP
              </>
            }
            centered
            style={{ width: '100%', marginTop: '20px' }}
            extra={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '12px'
                }}
              >
              </div>
            }
          >
            <div className='table-responsive'>
              {loading ? (
                <div className='spinner-container'>
                  <Spin size='large' />
                </div>
              ) : (
                <>
                <Table
                  columns={columnsClosedWithPriority}
                  dataSource={closedDataSource}
                  pagination={paginationOptions}
                  bordered
                  rowClassName={(record, index) =>
                    index % 2 === 0
                      ? 'table-row-light table-row-clickable'
                      : 'table-row-dark table-row-clickable'
                  }
                  onRow={(record) => ({
                    onClick: () => onRowClick(record),
                  })}
                />
                <Modal
                  title={
                    <>
                    <Typography.Title level={3} style={{ textAlign: 'center' }}>
                      TICKET DETAILS
                    </Typography.Title>
                    <Divider/>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <DepartmentBoxes trailData={trailData} />
                    </div>
                    </>
                  }
                  visible={isModalVisible}
                  onCancel={handleCancel}
                  footer={null}
                  width={1300}
                >
                <Form form={form} layout="vertical">
                <div
                  style={{height: 750 }}>
                <Row style={{width: '100%', height: '100%' }}>
        {/* First Column */}
        <Col flex={1} style={{marginLeft: 18}}>
          <Card
          style={{
            borderRadius: '25px',
            width: '300px',
            marginBottom: 35,
            boxShadow:
                '0 8px 16px rgba(0, 0, 0, 0.2)'
          }}>
                    {columnsAsset.map((column) => (
                    // Check if the column's dataIndex is not equal to 'admin_id'
                      !excludedColumns.includes(column.dataIndex) && (
                      <Form.Item
                        key={column.key}
                        label={column.title}
                        name={column.dataIndex}
                      >
                        <Input disabled={true} style={{color: 'maroon'}}/>
                      </Form.Item>
                    )
                    ))}
                    </Card>
                    </Col>
                    <Col flex={10} style={{ overflowY: 'auto', height: '100%'}}>

  {trailData?.length > 0 && (
    <p><strong>ACTIVITY</strong><br/><br/></p>
  )}
  {/* {trailData.map((trailItem, index) => ( */}
  {trailData?.length > 0 && trailData.map((trailItem, index) => (
    <div key={index}>     
      <Tag color='blue'>{trailItem.transferred_from}</Tag>
      <ArrowRightOutlined />
      <Tag color='blue' style={{marginLeft: 8}}>{trailItem.transferred_to}</Tag>
      <Tag color='green' style={{marginLeft: 8}}>{trailItem.transferred_at}</Tag>
      <p><strong>Comment:</strong> {trailItem.comment}</p>
      <Divider />
    </div>
   ))}
                    </Col>
                    </Row>
                    </div>
                  </Form>
                  </Modal>
                  <Modal
                  title={
                    <>
                    <Typography.Title level={3} style={{ textAlign: 'center' }}>
                      TICKET DETAILS DEPARTMENT
                    </Typography.Title>
                    <Divider/>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <DepartmentBoxes trailData={trailData} />
      </div>
                    </>
                  }
                  visible={deptModalVisible}
                  onCancel={handleCancel}
                  footer={null}
                  width={1300}
                >
                <Form form={form} layout="vertical">
                <div
                  style={{height: 750 }}>
                <Row style={{width: '100%', height: '100%' }}>
        {/* First Column */}
        <Col flex={1} style={{marginLeft: 18, overflowY: 'auto', height: '100%'}}>
          <Card
          style={{
            borderRadius: '25px',
            width: '300px',
            marginBottom: 35,
            marginLeft: 15,
            boxShadow:
                '0 8px 16px rgba(0, 0, 0, 0.2)'
          }}>
                    {columnsAsset.map((column) => (
                      !excludedColumns.includes(column.dataIndex) && (
                      <Form.Item
                        key={column.key}
                        label={column.title}
                        name={column.dataIndex}
                      >
                        <Input disabled={true} style={{color: 'maroon'}}/>
                      </Form.Item>
                    )
                    ))}
                    </Card>
                    </Col>
                    <Col flex={10} style={{marginRight: 18, overflowY: 'auto', height: '100%'}}>
    {trailData?.length > 0 && (
      <p><strong>ACTIVITY</strong><br/><br/></p>      
    )}                          
    {trailData?.length > 0 && trailData.map((trailItem, index) => (
    <div key={index}> 
      <Tag color='blue'>{trailItem.transferred_from}</Tag>
      <ArrowRightOutlined />
      <Tag color='blue' style={{marginLeft: 8}}>{trailItem.transferred_to}</Tag>
      <Tag color='green' style={{marginLeft: 8}}>{trailItem.transferred_at}</Tag>
      <p><strong>Comment:</strong> {trailItem.comment}</p>
      <Divider />
    </div>
   ))}
                    </Col>
                    </Row>
                    </div>
                  </Form>
                  </Modal>
                </>
              )}
            </div>
          </Card>
        </div>
      </center>
    </>
  )
}

export default CMAUserTickets
